import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Details from "../components/Restaurant/Details";
import { useTranslation } from "react-i18next";

function RestaurantDetail(props) {
    const { t } = useTranslation();
    const { restaurantId } = useParams();

    return (
        <div className="RestaurantDetail">
            <Header isTopBarEnabled={true} />

            <Details restaurantId={restaurantId} />

            <Footer />
        </div>
    );
}

export default RestaurantDetail;
