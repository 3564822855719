import React from "react";

function ItemService(props) {
    const { name, photo } = props;

    return (
        <div className="flex items-center">
            <img className="w-12 h-12" src={photo} alt="" />

            <p className="ml-3">{name}</p>
        </div>
    );
}

export default ItemService;
