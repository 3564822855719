import React, { useState } from "react";
import { sortNumeric } from "../../logic/utils";
import ItemMenu from "./ItemMenu";

function ItemCategory(props) {
    const { categoryRow, lastItem } = props;

    const [tabVisibility, setTabVisibility] = useState(false);

    let onTabClickHandler = () => setTabVisibility(!tabVisibility);

    return (
        <div>
            <div onClick={onTabClickHandler} className="cursor-pointer px-mobile">
                <div className="flex items-center justify-between py-3">
                    <p className="text-xl font-semibold">{categoryRow.name}</p>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        className={`w-6 transition-all duration-300 ${tabVisibility ? `rotate-180` : `rotate-0`}`}
                    >
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                    </svg>
                </div>

                {!lastItem && <hr className={`${tabVisibility ? `` : `border-t border-mycia-dimGray`}`} />}
            </div>

            <div className={`transition-all duration-150 overflow-hidden h-auto max-h-0 ${tabVisibility ? `max-h-screen` : ``}`}>
                {
                    categoryRow.items.sort(sortNumeric).map((item, key) => (
                        <ItemMenu key={key} item={item} index={key} />
                    ))
                }
            </div>
        </div>
    );
}

export default ItemCategory;
