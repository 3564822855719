import { React, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import ItemBusiness from "./ItemBusiness";
import { getRestaurants } from "../../apis/businesses";
import { restaurantNoImage } from "../../apis/assets";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { myCIASelect } from "../../style";

function List() {
    const { t } = useTranslation();
    const [restaurantList, setRestaurantList] = useState([]);
    const [filteredRestaurantList, setFilteredRestaurantList] = useState([]);
    const [businessName, setBusinessName] = useState("");
    const [restaurantOptions, setRestaurantOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();

    const loadRestaurant = useCallback(async () => {
        const data = await getRestaurants();

        setRestaurantList(data.ristoranti);
        setFilteredRestaurantList(data.ristoranti);
        setBusinessName(data.ristoratore);
        setRestaurantOptions(data.ristoranti.map(it => ({ label: it.ran_ragione_sociale, value: it.ris_id })));
    }, []);

    useEffect(() => {
        loadRestaurant();
    }, [loadRestaurant]);

    const onChangeOption = (target) => {
        if (target && target.value) {
            const temp = restaurantList.filter(it => it.ris_id == target.value);
            setFilteredRestaurantList(temp);
        } else {
            loadRestaurant();
        }
        setSelectedOption(target);
    }

    return (
        <div className="p-mobile">
            <Link to="/restaurant/create">
                <div className="button-mycia-white flex items-center justify-center">
                    <p className="font-semibold">AGGIUNGI RISTORANTE</p>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="icon-mycia ml-4">
                        <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
                    </svg>
                </div>
            </Link>

            <p className="mt-11 text-2xl font-medium">Ciao {businessName}!</p>

            <Link to="/account/modify">
                <div className="flex w-full cursor-pointer items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon-mycia fill-mycia-red">
                            <path d="M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z" />
                        </svg>

                        <p className="ml-3 font-semibold text-mycia-red underline">Modifica il tuo account gestore</p>
                </div>
            </Link>

            <Select
                className="mt-4 text-sm"
                styles={myCIASelect}
                isClearable={true}
                options={restaurantOptions}
                value={selectedOption}
                onChange={onChangeOption}
                placeholder="Nessuno"
                noOptionsMessage={({ inputValue }) => (!inputValue ? "Nessuno" : "No results found")}
            />
            
            <div className="flex flex-col gap-y-12">
                {
                    filteredRestaurantList.map((item, key) => {
                        const resImage = item.ran_immagine || restaurantNoImage;
                        return <ItemBusiness key={key} restaurantId={item.ris_id} name={item.ran_ragione_sociale} photo={resImage} />
                    })
                }
            </div>
        </div>
    );
}

export default List;
