import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../apis/users';
import { useTranslation } from "react-i18next";

function RecoverForm(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState();

    const onChangeHandler = ({target}) => setEmail(target.value);
    
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        
        const res = await forgotPassword(email);
        if (res.outcome) {
            alert("Email di recupero inviata");
            navigate(-1);
        }
    }

    const onClickNavigateBack = () => {
        navigate(-1);
    }

    return (
        <div className="min-h-[80vh] px-mobile">
            <div className="my-6 flex cursor-pointer items-center" onClick={onClickNavigateBack}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="h-5 w-5">
                    <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                </svg>

                <p className="ml-2 text-lg font-light underline cursor-pointer">Login</p>
            </div>

            <div className="mt-12">
                <form method="POST" action="" onSubmit={onSubmitHandler}>
                    <fieldset>
                        <label className="w-full text-2xl inline-block font-semibold">Recupera password</label>
                        <label className="mt-4 inline-block font-light">Inserisci il tuo indirizzo email. Riceverai un messaggio per cambiare la password.</label>

                        <input type="email" name="Email" id="email" className="input-mycia-secondary mt-8" value={email} onChange={onChangeHandler} />
                    </fieldset>

                    <button type="submit" className="button-mycia w-full mt-16">RECUPERA PASSWORD</button>
                </form>
            </div>
        </div>
    )
}

export default RecoverForm;
