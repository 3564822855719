import { doAuthPost, doFilePost, doGet } from "./api-client";
import { API_DOMAIN, API_DOMAIN_MYCIA, API_BASE_DOMAIN, API_BASE_DOMAIN_V2, API_BASE_DOMAIN_V3 } from "./constants";

async function getRestaurantDetail(resId) {
    const payload = new URLSearchParams({
        todo: "load-ristorante",
        script: "scripts/step-2.php",
        ris_id: resId,
    });

    const response = await doAuthPost(`${API_DOMAIN}`, payload);

    response.isContactlessMenuEnabled = response?.pacchetto_order_and_pay === true || response?.pacchetto_booking_pro === true;
    response.isBookingEnabled = response?.pacchetto_contactless_attivo === true || response?.pacchetto_order_and_pay === true;
    response.isMenuServicesEnabled = response?.pacchetto_contactless_attivo === true || response?.pacchetto_order_and_pay === true || response?.pacchetto_booking_pro;
    response.isQrCodeGeneratorEnabled = response?.pacchetto_booking_pro === true;

    return response;
}

async function getRestaurantAllDetail(restaurantId) {
    const payload = new URLSearchParams({
        todo: 'load-ristorante',
        script: 'scripts/step-3.php',
        ris_id: restaurantId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function addRestaurant(data) {
    const payload = new URLSearchParams({
        todo: 'ins-ristorante',
        script: 'scripts/step-3.php',
        ...data
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getRestaurantDishes(resId) {
    const payload = new URLSearchParams({
        todo: 'elenco-piatti',
        script: 'scripts/step-2.php',
        ris_id: resId,
        piattoPer: 'A', // 0, 1, 2
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getRestaurantServices(resId) {
    const payload = new URLSearchParams({
        todo: 'elenco-servizi',
        script: 'scripts/step-2.php',
        ris_id: resId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getRestaurantWines(resId) {
    const payload = new URLSearchParams({
        todo: 'elenco-vini',
        script: 'scripts/step-2.php',
        ris_id: resId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getCoursesAndTypesList() {
    const payload = new URLSearchParams({
        todo: 'tipi-linee',
        script: 'scripts/step-3.php',
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getServicesList(restaurantId) {
    const payload = new URLSearchParams({
        todo: 'elenco-servizi',
        script: 'scripts/step-3.php',
        ris_id: restaurantId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function toggleService(restaurantId, serviceId, selected) {
    const payload = new URLSearchParams({
        todo: 'toggle-servizio',
        script: 'scripts/step-3.php',
        ris_id: restaurantId,
        ser_id: serviceId,
        selected: selected ? 1 : 0
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function modifyRestaurant(restaurantId, data) {
    const payload = new URLSearchParams({
        todo: 'edit-ristorante',
        script: 'scripts/step-3.php',
        ris_id: restaurantId,
        ...data
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function checkPaymentServices(restaurantId) {
    const payload = new URLSearchParams({
        todo: 'check-online-payment-services',
        script: 'scripts/step-2.php',
        ris_id: restaurantId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function checkDeliveryServices(restaurantId) {
    const payload = new URLSearchParams({
        todo: "check-delivery-services-status",
        script: "scripts/step-14.php",
        ris_id: restaurantId,
    });

    const response = await doAuthPost(`${API_DOMAIN}`, payload);

    response.isOrderAndPayEnabled = response?.pacchetto_order_and_pay === true;
    response.isBookingEnabled = response?.pacchetto_table_booking === true;

    return response;
}

async function updateServices(restaurantId, todo, status) {
    const payload = new URLSearchParams({
        todo: todo,
        script: 'scripts/step-14.php',
        ris_id: restaurantId,
        status: status
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getReviews(resId) {
    return (await doGet(`${API_DOMAIN_MYCIA}/restaurant/${resId}/ratings`)).data;
}

async function getServicesTabs(restaurantId) {
    const payload = new URLSearchParams({
        todo: 'get-tabs',
        script: 'scripts/step-14.php',
        ris_id: restaurantId,
    })

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setServicesTabs(restaurantId, tabs) {
    const payload = new URLSearchParams({
        todo: 'set-tabs',
        script: 'scripts/step-14.php',
        ris_id: restaurantId,
        courses: tabs.courses,
        beverages: tabs.beverages
    })

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getTranslationsPages(restaurantId) {
    const payload = new URLSearchParams({
        todo: 'get-traduzione-piatti',
        script: 'scripts/step-14.php',
        ris_id: restaurantId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setTranslationPages(restaurantId, toggle = 0) {
    const payload = new URLSearchParams({
        todo: 'set-traduzione-piatti',
        script: 'scripts/step-14.php',
        ris_id: restaurantId,
        traduzione_piatti: toggle
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setAllowSharing(restaurantId, toggle = 0) {
    const payload = new URLSearchParams({
        todo: 'update-status-sharable-link',
        script: 'scripts/step-14.php',
        ris_id: restaurantId,
        status: toggle
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getTableServices(restaurantId) {
    const payload = new URLSearchParams({
        todo: 'get-tables',
        script: 'scripts/step-14-tableservice.php',
        ris_id: restaurantId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteTable(restaurantId, tableId) {
    const payload = new URLSearchParams({
        todo: 'delete-tables',
        script: 'scripts/step-14-tableservice.php',
        ris_id: restaurantId,
        table_id: tableId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setTables(restaurantId, data) {
    const payload = new URLSearchParams({
        todo: 'set-tables',
        script: 'scripts/step-14-tableservice.php',
        ris_id: restaurantId,
        ...data,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getDeliveryHours(restaurantId, data) {
    const payload = new URLSearchParams({
        todo: 'get-hours',
        script: 'scripts/step-14-delivery.php',
        ris_id: restaurantId,
        delivery_reservation: "delivery"
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteDeliveryHour(restaurantId, hour_id) {
    const payload = new URLSearchParams({
        todo: 'delete-hours',
        script: 'scripts/step-14-delivery.php',
        ris_id: restaurantId,
        hour_id: hour_id,
        delivery_reservation: "delivery"
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setDeliveryHour(restaurantId, open, close, day, id = null) {
    const payload = new URLSearchParams({
        todo: 'set-hours',
        open: open,
        close: close,
        day: day,
        script: 'scripts/step-14-delivery.php',
        ris_id: restaurantId,
        delivery_reservation: "delivery"
    });

    if (id) payload.append("id", id);

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getTakeAwayHours(restaurantId, data) {
    const payload = new URLSearchParams({
        todo: 'get-hours',
        script: 'scripts/step-14-takeaway.php',
        ris_id: restaurantId,
        delivery_reservation: "delivery"
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteTakeAwayHour(restaurantId, hour_id) {
    const payload = new URLSearchParams({
        todo: 'delete-hours',
        script: 'scripts/step-14-takeaway.php',
        ris_id: restaurantId,
        hour_id: hour_id,
        delivery_reservation: "delivery"
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setTakeAwayHour(restaurantId, open, close, day, id = null) {
    const payload = new URLSearchParams({
        todo: 'set-hours',
        open: open,
        close: close,
        day: day,
        script: 'scripts/step-14-takeaway.php',
        ris_id: restaurantId,
        delivery_reservation: "delivery"
    });

    if (id) payload.append("id", id);

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getDeliveryRate(restaurantId) {
    const payload = new URLSearchParams({
        todo: 'get-tariffe-delivery',
        script: 'scripts/step-14-delivery.php',
        ris_id: restaurantId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteDeliveryRate(restaurantId, id) {
    const payload = new URLSearchParams({
        todo: 'delete-tariffe-delivery',
        script: 'scripts/step-14-delivery.php',
        ris_id: restaurantId,
        id: id
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function saveDeliveryRate(restaurantId, distance_from, distance_to, cost_increase, medium_time, min_amount) {
    const payload = new URLSearchParams({
        todo: 'add-tariffe-delivery',
        script: 'scripts/step-14-delivery.php',
        ris_id: restaurantId,
        distance_from,
        distance_to,
        cost_increase,
        medium_time,
        min_amount
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getDeliveryInfo(restaurantId) {
    const payload = new URLSearchParams({
        todo: 'get-booking-info',
        script: 'scripts/step-14-booking.php',
        ris_id: restaurantId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getTakeAwayInfo(restaurantId) {
    const payload = new URLSearchParams({
        todo: 'get-booking-info',
        script: 'scripts/step-14-booking.php',
        delivery_reservation: "takeaway",
        ris_id: restaurantId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setDeliveryPhone(restaurantId, phone) {
    const payload = new URLSearchParams({
        todo: 'set-phone',
        script: 'scripts/step-14-booking.php',
        ris_id: restaurantId,
        phone: phone
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setDeliveryEmail(restaurantId, email) {
    const payload = new URLSearchParams({
        todo: 'set-email',
        script: 'scripts/step-14-booking.php',
        ris_id: restaurantId,
        email: email
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function toggleDeliveryEmail(restaurantId, status) {
    const payload = new URLSearchParams({
        todo: 'toggle-email',
        script: 'scripts/step-14-booking.php',
        ris_id: restaurantId,
        status
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function toggleDeliveryPhone(restaurantId, status) {
    const payload = new URLSearchParams({
        todo: 'toggle-sms',
        script: 'scripts/step-14-booking.php',
        ris_id: restaurantId,
        status
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getTakeAwayTime(restaurantId) {
    const payload = new URLSearchParams({
        todo: 'get-time',
        script: 'scripts/step-14-takeaway.php',
        ris_id: restaurantId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setTakeAwayTime(restaurantId, time) {
    const payload = new URLSearchParams({
        todo: 'set-time',
        script: 'scripts/step-14-takeaway.php',
        ris_id: restaurantId,
        time
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function uploadPhoto(file) {
    const payload = new FormData();
    payload.append("userfile", file);

    return (await doFilePost(`${API_BASE_DOMAIN}/net/v2/profilo/agp-1/scripts/upload.php`, payload));
}

async function uploadLogoPhoto(resId, file) {
    const payload = new FormData();
    payload.append(resId, file);
    payload.append("nome_file", resId);

    return (await doFilePost(`${API_BASE_DOMAIN}/net/profilo/step-2/scripts/include/upload.php`, payload));
}

async function uploadRestaurantPhoto(resId, file) {
    const payload = new FormData();
    payload.append(resId, file);
    payload.append("nome_file", resId);

    return (await doFilePost(`${API_BASE_DOMAIN}/net/profilo/step-2/scripts/include/upload-ris-imgs.php`, payload));
}

async function getPhotoDetail(photoId) {
    const payload = new URLSearchParams({
        todo: 'get-name-images-ris',
        script: 'scripts/step-2.php',
        rfo_id: photoId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deletePhoto(imgBig, imgSmall) {
    const payload = new URLSearchParams({
        todo: 'rimuovi_foto_fis',
        img_big: imgBig,
        img_small: imgSmall,
        staging: 0
    });

    return (await doAuthPost(`${API_BASE_DOMAIN}/net/profilo/step-2/scripts/include/operazioni.php`, payload));
}

async function deletePhotoFromRestaurant(resId, photoId) {
    const payload = new URLSearchParams({
        todo: 'remove-img-ris',
        script: 'scripts/step-2.php',
        ris_id: resId,
        rfo_id: photoId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateLogoRestaurant(restaurantId, imgUrl) {
    const payload = new URLSearchParams({
        todo: 'update-logo',
        script: 'scripts/step-2.php',
        ris_id: restaurantId,
        img: imgUrl
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function checkPhotoRestaurant(img) {
    const payload = new URLSearchParams({
        todo: 'update-logo',
        script: 'scripts/step-2.php',
        img
    });

    return (await doAuthPost(`${API_BASE_DOMAIN}/net/profilo/step-2/scripts/include/check_immagine.php`, payload));
}

async function updatePhotoRestaurant(restaurantId, imgBig, imgSmall) {
    const payload = new URLSearchParams({
        todo: 'add-img-ris',
        script: 'scripts/step-2.php',
        ris_id: restaurantId,
        img: imgBig,
        imgSmall: imgSmall
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getRestaurantByToken(token, lang, consumetime) {
    return (await doGet(`${API_BASE_DOMAIN_V2}/restaurants/?token=${token}&lang=${lang}&consumetime=${consumetime}`));    
}

async function getCoursesById(id, lang, consumetime) {
    return (await doGet(`${API_BASE_DOMAIN_V2}/courses/?id=${id}&lang=${lang}&consumetime=${consumetime}`));    
}

async function getBevarages(id, lang) {
    return (await doGet(`${API_BASE_DOMAIN_V2}/beverages/index.php?type=drinks&ris_id=${id}&lang=${lang}`));    
}

async function getFoodSetList(lang) {
    return (await doGet(`${API_BASE_DOMAIN_V2}/foodstyle/allergens/?lang=${lang}`));
}

async function getLanguages() {
    return (await doGet(`${API_BASE_DOMAIN_V2}/languages`));
}

async function getDishById(id) {
    return (await doGet(`${API_BASE_DOMAIN_V3}/foods/dishes/${id}`));
}

export {
    addRestaurant,
    getRestaurantDetail,
    getRestaurantAllDetail,
    getRestaurantDishes,
    getRestaurantServices,
    getRestaurantWines,
    getCoursesAndTypesList,
    getServicesList,
    toggleService,
    modifyRestaurant,
    checkPaymentServices,
    checkDeliveryServices,
    updateServices,
    getReviews,
    getServicesTabs,
    setServicesTabs,
    getTranslationsPages,
    setTranslationPages,
    setAllowSharing,
    getTableServices,
    setTables,
    deleteTable,
    getDeliveryHours,
    deleteDeliveryHour,
    setDeliveryHour,
    getTakeAwayHours,
    deleteTakeAwayHour,
    setTakeAwayHour,
    getDeliveryRate,
    deleteDeliveryRate,
    saveDeliveryRate,
    getDeliveryInfo,
    getTakeAwayInfo,
    setDeliveryPhone,
    setDeliveryEmail,
    toggleDeliveryEmail,
    toggleDeliveryPhone,
    getTakeAwayTime,
    setTakeAwayTime,
    uploadPhoto,
    uploadLogoPhoto,
    uploadRestaurantPhoto,
    updatePhotoRestaurant,
    getPhotoDetail,
    deletePhoto,
    deletePhotoFromRestaurant,
    checkPhotoRestaurant,
    updateLogoRestaurant,
    getRestaurantByToken,
    getCoursesById,
    getBevarages,
    getFoodSetList,
    getLanguages,
    getDishById,
}
