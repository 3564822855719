import React from 'react';

function MenuHeaderFirst(props) {
    const { name, description } = props;
    return (
        <div className="MenuHeaderFirst">
            <p className="mt-6 text-5xl text-center text-mycia-red font-firstStyle font-bold">{name}</p>
            <p className="mt-8 text-lg text-center font-firstStyle font-semibold">{description}</p>
        </div>
    )
}

export default MenuHeaderFirst;