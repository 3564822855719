import React from "react";

function ItemWeekDay(props) {
    const { day, time } = props;

    return (
        <div className="mt-1 flex justify-between text-lg font-light">
            <p>{day}</p> <p>{time}</p>
        </div>
    );
}

export default ItemWeekDay;
