import { useState, useEffect } from "react";

const staffIPs = ["213.225.251.78", "213.225.240.68", "213.225.247.6"];

function useIP() {
    const [ipAddress, setIPAddress] = useState("");
    const [isStaff, setIsStaff] = useState(true);

    useEffect(() => {
        async function getIP() {
            fetch("https://api.ipify.org?format=json")
                .then((response) => response.json())
                .then((data) => {
                    setIsStaff(staffIPs.includes(data.ip));
                    setIPAddress(data.ip);
                });
        }

        getIP();
    }, []);

    return { ipAddress, isStaff };
}

export default useIP;
