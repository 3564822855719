import React, { useState } from "react";
import Modal from "react-modal";
import { renameCategoryOfWine } from "../../../../apis/modify-restaurant";
import { modalStyle } from "../../../../style";
import Close from "../../../Icon/Close";
import { useTranslation } from "react-i18next";

function WineRenameCategoryModal(props) {
  const { t } = useTranslation();
  const { children, restaurantId, categoryName, onRefresh } = props;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(categoryName);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    onRefresh(name);
  }

  const onClickConfirm = async () => {
    const res = await renameCategoryOfWine(
      restaurantId,
      categoryName,
      name,
    );
    if (res.outcome) closeModal();
  };

  return (
    <div>
      <div onClick={openModal}>{children}</div>

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyle}
          contentLabel="Cambia nome categoria"
        >
          <div className="flex items-center justify-between">
            <p className="text-lg font-semibold">Cambia nome categoria</p>

            <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
          </div>

          <input
            name="category"
            type="text"
            value={name}
            className="mt-14 mb-20 input-mycia-secondary"
            onChange={({target}) => setName(target.value)}
          />

          <div className="button-mycia flex-1" onClick={onClickConfirm}>
            CONFERMA
          </div>
        </Modal>
      )}
    </div>
  );
}

export default WineRenameCategoryModal;
