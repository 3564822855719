import React from "react";

function Youtube(props) {
    const { className, onClick } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`ionicon ${className}`}
            viewBox="0 0 512 512"
            onClick={onClick}
        >
            <path d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z"></path>
        </svg>
    );
}

export default Youtube;
