import React from "react";

function Takeaway(props) {
    const { className, onClick } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 58.44 53.06"
            className={className}
            onClick={onClick}
        >
            <defs>
                <linearGradient
                    id="A"
                    x1="22.46"
                    x2="22.46"
                    y1="49.367"
                    y2="25.527"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#cf5448"></stop>
                    <stop offset="1" stopColor="#d16c4b"></stop>
                </linearGradient>
                <linearGradient
                    id="B"
                    x1="-7.5"
                    x2="-6.29"
                    y1="-226.7"
                    y2="-220.97"
                    gradientTransform="matrix(22.62 0 0 -4.78 182.01 -1052.08)"
                    xlinkHref="#A"
                ></linearGradient>
                <linearGradient
                    id="C"
                    x1="10.131"
                    x2="49.351"
                    y1="6.4"
                    y2="6.4"
                    xlinkHref="#A"
                ></linearGradient>
            </defs>
            <path
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="1.07"
                d="M29.17 51.21H1.89c-1.1 0-1.35-.25-1.35-1.34V24.83a2.23 2.23 0 01.4-1.28l6.98-10.47a1.75 1.75 0 00.27-.89l.01-4.84C8.12 3.68 11.03.63 14.7.55h29.73a6.68 6.68 0 016.79 6.57v.23l.02 4.8c0 .38.11.76.29 1.09l6.03 10.4c.21.37.32.78.33 1.2l.01 25.09c0 1.05-.27 1.31-1.33 1.31l-27.4-.03zm-26.7-1.94h40.08V25.43H2.46v23.84zm46.07-35.33H10.1c-.3-.02-.59.13-.74.4l-5.78 8.7-.27.43.56.02 38.64.01a.77.77 0 00.76-.44l5-8.58.28-.54zm.73-1.94l.02-.52V7.55a4.801 4.801 0 00-4.48-5.1 6.62 6.62 0 00-.63 0H14.67c-2.3.07-4.21 1.81-4.5 4.1-.13 1.77-.04 3.56-.05 5.34 0 .03.03.05.06.1h39.09zm-4.74 36.17l4.41-3.77c.24-.18.37-.48.35-.78V25.43h-4.76v22.74zm11.41 0V25.43H51.2v16.48c-.11.72-.08 1.45.09 2.16.27.6 1.01.97 1.55 1.43l3.1 2.65zm-.7-24.68l-5.01-8.6-5 8.6h10.01zm-1.1 25.82l.05-.11-3.95-3.4-4.08 3.5h7.99z"
            ></path>
            <path fill="url(#A)" d="M2.46 25.43v23.84h40.1V25.43H2.46z"></path>
            <path
                fill="url(#B)"
                d="M48.54 13.94l-.28.53-5 8.58a.76.76 0 01-.76.44l-38.64-.01c-.16 0-.32-.01-.56-.02l.27-.43 5.78-8.7c.15-.27.44-.42.74-.4h38.44z"
            ></path>
            <path
                fill="url(#C)"
                d="M49.27 11.99H10.18s-.06-.07-.06-.1l.05-5.34c.29-2.29 2.2-4.03 4.5-4.1h29.51a4.8 4.8 0 015.11 4.47V11.48l-.02.51zm-20.05-1.9h9.38c.78 0 1.12-.34 1.12-1.11V7.15a2.816 2.816 0 00-2.77-2.77 3973.23 3973.23 0 00-15.44 0c-1.54 0-2.79 1.25-2.8 2.79V8.9c0 .88.32 1.2 1.2 1.2h9.31z"
            ></path>
            <path
                fill="#a83634"
                d="M44.53 48.16V25.42h4.76V43.6c.02.3-.11.59-.35.78l-4.41 3.77zm11.42-.01l-3.09-2.65c-.54-.46-1.29-.84-1.55-1.43-.17-.71-.2-1.44-.09-2.16V25.43h4.74v22.72zm-.7-24.69H45.23l5-8.6 5.02 8.6zm-1.1 25.82h-7.99l4.08-3.5 3.95 3.4-.05.1z"
            ></path>
            <path d="M29.22 10.1h-9.33c-.88 0-1.2-.32-1.2-1.2V7.17a2.813 2.813 0 012.8-2.79h15.44a2.81 2.81 0 012.77 2.77v1.83c0 .78-.34 1.11-1.12 1.11h-9.36zm8.59-1.94v-.6c0-.98-.29-1.27-1.27-1.27H21.76c-.82 0-1.13.32-1.14 1.14v.72h17.19z"></path>
        </svg>
    );
}

export default Takeaway;
