import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateOrderDrink, updateOrderDrinkCategory } from "../../../apis/modify-restaurant";
import ArrowDownOutline from "../../Icon/ArrowDownOutline";
import ArrowUpOutline from "../../Icon/ArrowUpOutline";
import ItemWineMenuModify from "./ItemWineMenuModify";
import { useTranslation } from "react-i18next";
import WineRenameCategoryModal from "./Modal/WineRenameCategoryModal";
import Pen from "../../Icon/Pen";
import WineAddModal from "./Modal/WineAddModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function ItemWineCategoryModify(props) {
    const { t } = useTranslation();
    const { restaurantId } = useParams();
    const { isArchieve, name, wines, isCampari, isPago, isSanbitter, weight, onRefresh, isStart, isEnd, isWineMovable } = props;

    const [winesState, setWinesState] = useState(wines);
    const [categoryName, setCategoryName] = useState(name);
    const [tabVisibility, setTabVisibility] = useState(false);

    let onTabClickHandler = () => setTabVisibility(!tabVisibility);

    const onDelete = (wineId) => {
        let temp = winesState;
        temp = temp.filter((it) => it.rvi_id !== wineId);
        setWinesState(temp);
    };

    useEffect(() => {
        setWinesState(wines);
    }, [wines]);

    const onChangeWineNameAndPrice = (wineId, name, price) => {
        let temp = winesState;
        temp = temp.map((it) => {
            if (it.rvi_id === wineId) {
                it.rvi_nome = name;
                it.rvi_prezzo = price;
            }
            return it;
        });
        setWinesState(temp);
    };

    const onSwipeCategories = async (dir) => {
        if (dir === "down" && isEnd) return;
        if (dir === "up" && isStart) return;
        const res = await updateOrderDrinkCategory(restaurantId, name, dir, weight);
        if (res.outcome) onRefresh();
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);

        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result.forEach((item, index) => {
            item.rvi_weight = result.length - index;
        });

        return result;
    };

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const endWeight = winesState.length - 1 - result.destination.index;
        const startWeight = winesState.length - 1 - result.source.index;

        const tempWines = reorder(winesState, result.source.index, result.destination.index);
        setWinesState([...tempWines]);

        await updateOrderDrink(restaurantId, winesState[result.source.index].rvi_id, isArchieve ? 1 : 0, name, startWeight, endWeight);
    };

    function WineMenuModify({ item, index, arr }) {
        const itemMenuModify = (
            <ItemWineMenuModify
                isArchieve={isArchieve}
                key={`item_wine_modify_${item.rvi_id}`}
                categoryName={categoryName}
                wineId={item.rvi_id}
                name={item.rvi_nome}
                price={item.rvi_prezzo}
                isCampari={item.rvi_campari}
                isPago={item.rvi_pago}
                isSanbitter={item.rvi_sanbitter}
                weight={item.rvi_weight}
                onDelete={onDelete}
                onRefresh={onRefresh}
                onChangeWineNameAndPrice={onChangeWineNameAndPrice}
            />
        );
        if (!isCampari && item.rvi_pago !== 1 && item.rvi_sanbitter !== 1) {
            return (
                <Draggable draggableId={item.rvi_id.toString()} index={index}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            {itemMenuModify}
                        </div>
                    )}
                </Draggable>
            );
        } else {
            return itemMenuModify;
        }
    }

    const WineMenuModifyList = React.memo(({ wines }) => {
        return wines.length !== 0 ? wines.map((item, index, arr) => <WineMenuModify item={item} index={index} arr={arr} key={index} />) : <p className="ml-6 my-4">Nessun bevande inserito</p>;
    });

    return (
        <div className="ItemWineCategoryModify">
            <div className="border-mycia-dimGray px-mobile flex cursor-pointer justify-between border-t py-4">
                <div className="flex items-center">
                    {isWineMovable && !isCampari && !isPago && !isSanbitter && (
                        <div className="flex">
                            <ArrowDownOutline onClick={() => onSwipeCategories("down")} className={`w-5 h-5 ${isEnd ? "opacity-50" : ""}`} />
                            <ArrowUpOutline onClick={() => onSwipeCategories("up")} className={`ml-2 w-5 h-5 mr-4 ${isStart ? "opacity-50" : ""}`} />
                        </div>
                    )}
                    {!isWineMovable && !isCampari && !isPago && !isSanbitter && (
                        <WineRenameCategoryModal
                            restaurantId={restaurantId}
                            categoryName={name}
                            onRefresh={(catName) => {
                                setCategoryName(catName);
                                onRefresh();
                            }}
                        >
                            <Pen className="w-5 h-5 cursor-pointer mr-2" />
                        </WineRenameCategoryModal>
                    )}
                    <p className="text-lg font-semibold">{name}</p>
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className={`w-6 transition-all duration-300 ${tabVisibility ? `rotate-180` : `rotate-0`}`} onClick={onTabClickHandler}>
                    <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                </svg>
            </div>

            <div className={`${tabVisibility ? `visible` : `hidden`}`}>
                <WineAddModal restaurantId={restaurantId} categoryName={name} onWineCreated={onRefresh}>
                    <p className="mx-mobile my-2 cursor-pointer text-lg font-semibold text-mycia-orangeRed underline" onClick={() => {}}>
                        Aggiungi bevande
                    </p>
                </WineAddModal>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="wine">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <WineMenuModifyList wines={winesState?.sort((a, b) => b.rvi_weight - a.rvi_weight) ?? []} />
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
}

export default ItemWineCategoryModify;
