import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AccountForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const nextStep = (e) => {
        e.preventDefault();
        
        navigate("../registration/cia");
    }

    return (
        <form method="POST" action="" onSubmit={nextStep}>
            <fieldset>
                <legend className="font-light">
                    <span className="font-semibold">Registrazione gestore ristorante</span> <br /> <br />
                    Compila i campi sottostanti con i tuoi dati personali, una volta confermati potrai procedere
                    con la creazione del profilo del tuo locale in tutti i suoi dettagli. Attenzione: il “Nome
                    di Riferimento” non si riferisce al nome della struttura, ma a quello del gestore del
                    rispettivo account MyCIA.
                </legend>

                <label className="mt-8 inline-block text-sm font-semibold">RAGIONE SOCIALE</label>
                <input type="text" className="input-mycia-secondary mt-2" placeholder="Inserisci il nome della tua azienda" />

                <label className="mt-4 inline-block text-sm font-semibold">NOME DI RIFERIMENTO</label>
                <input type="text" className="input-mycia-secondary mt-2" placeholder="Inserisci il tuo nome" />

                <label className="mt-4 inline-block text-sm font-semibold">P. IVA</label>
                <input type="text" className="input-mycia-secondary mt-2" placeholder="-" />
                <p className="mt-2 text-sm font-light text-mycia-red">Non trovata</p>

                <label className="mt-4 inline-block text-sm font-semibold">INDIRIZZO</label>
                <input type="text" className="input-mycia-secondary mt-2" placeholder="-" />

                <button type="submit" className="button-mycia mt-10">CONTINUA</button>
            </fieldset>
        </form>
    );
}

export default AccountForm;
