import { doAuthPost, doPost } from "./api-client";
import { API_DOMAIN, LOCALSTORAGE_KEY } from "./constants";

async function login(email, password) {
    const loginPayload = new URLSearchParams({
        todo: 'login-ristoranti',
        script: 'login/scripts/login.php',
        user: email,
        password: password,
    });

    const loginResponse = (await doPost(`${API_DOMAIN}`, loginPayload));

    window.localStorage.setItem(LOCALSTORAGE_KEY, loginResponse.rac_token);

    return loginResponse;
}

async function forgotPassword(email) {
    const forgotPayload = new URLSearchParams({
        todo: 'email',
        script: 'password_dimenticata/scripts/email.php',
        email: email
    });

    return (await doPost(`${API_DOMAIN}`, forgotPayload));
} 

async function logout() { 
    const payload = new URLSearchParams({
        todo: 'logout',
        script: 'logout/scripts/logout.php',
    });

    const res = (await doAuthPost(`${API_DOMAIN}`, payload));

    if (res.outcome) window.localStorage.removeItem(LOCALSTORAGE_KEY);
    return res;
};

export {
    login,
    forgotPassword,
    logout,
}
