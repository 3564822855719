import React from 'react';

function MenuBodyThird(props) {
    const { name, subItems, isDescriptionEnabled } = props;
    return (
        <div className="MenuBodyThird">
            <p className="w-full mt-4 text-2xl text-mycia-orangeRed capitalize">{name}</p>
                                
            <div className="mt-4 flex flex-col gap-y-8">
                {
                    subItems.map((it, index) => (
                        <div key={index} className="w-full">
                            <p className="w-full text-xl">{it.name}</p>
                            {
                                isDescriptionEnabled && <p className="font-medium text-start text-sm uppercase">{it.description}</p>
                            }
                            <p className="text-xs font-secondStyle uppercase">{it.ingredients && it.ingredients.map(it => it.name).join(", ")}</p>
                            <p className="text-end">{it.price} €</p>
                            <div className="w-full h-[1px] mt-4 mx-auto bg-mycia-dimGray"></div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default MenuBodyThird;