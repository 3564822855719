import React, { useEffect, useState } from 'react';
import Delete from '../Icon/Delete';

function TimeSlot(props) {
    const { index, id, fromDate, toDate, onChangeTime, onDelete } = props;
 
    const [timeSlotData, setTimeSlotData] = useState({
        id: "",
        open: "",
        close: ""
    });

    useEffect(() => {
        setTimeSlotData({
            id: id,
            open: fromDate,
            close: toDate
        });
    }, [id, fromDate, toDate]);

    const onChangeHandle = ({ target }) => {
        const data = ({
            ...timeSlotData,
            [target.name]: target.value
        });
        setTimeSlotData(data)
        onChangeTime(data, index)
    }

    return (
         <div className="flex items-center mt-2">
            <div className="flex-1 bg-mycia-whiteSmoke rounded-lg ">
                <div className="flex items-center justify-between px-6 py-3">
                    <p>Dalle</p>

                    <input name="open" type="time" value={timeSlotData.open} onChange={onChangeHandle} className="input-mycia valid:bg-white ml-6"/>
                </div>

                <div className="flex items-center justify-between px-6 py-3">
                    <p>Alle</p>

                    <input name="close" type="time" value={timeSlotData.close} onChange={onChangeHandle} className="input-mycia valid:bg-white ml-8"/>
                </div>
            </div>  

            <Delete className="icon-mycia flex-shrink-0 mx-6 cursor-pointer fill-black" onClick={onDelete} />
        </div>
    )
}

export default TimeSlot;