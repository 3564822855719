import React, { useState, useEffect, useRef } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import ReactToPrint from 'react-to-print';
import { getCoursesById, getDishById, getFoodSetList, getLanguages, getRestaurantByToken } from '../apis/restaurants';
import { getTastingMenu } from '../apis/tasting';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import MenuBodyFirst from '../components/MenuPrint/DishMenuBody/MenuBodyFirst';
import MenuBodySecond from '../components/MenuPrint/DishMenuBody/MenuBodySecond';
import MenuBodyThird from '../components/MenuPrint/DishMenuBody/MenuBodyThird';
import MenuHeaderFirst from '../components/MenuPrint/DishMenuHeader/MenuHeaderFirst';
import MenuHeaderSecond from '../components/MenuPrint/DishMenuHeader/MenuHeaderSecond';
import MenuHeaderThird from '../components/MenuPrint/DishMenuHeader/MenuHeaderThird';
import TastingMenuFirst from '../components/MenuPrint/TastingMenuBody/TastingMenuFirst';
import TastingMenuSecond from '../components/MenuPrint/TastingMenuBody/TastingMenuSecond';
import TastingMenuThird from '../components/MenuPrint/TastingMenuBody/TastingMenuThird';
import NavBack from '../components/NavBack/NavBack';
import { buildContactlessMenuURL, PRINT_ENUM } from '../logic/utils';
import { myCIASelect, myCIASelectWhiteControl } from '../style';

function DishMenuPrint(props) {
    const { restaurantId, token } = useParams();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [restaurantId]);

    const [languages, setLanguages] = useState([]);
    const [selectedLang, setSelectedLang] = useState({
        label: "Italiano",
        value: "it"
    });
    const [selectedStyleMenu, setSelectedStyleMenu] = useState(1);
    const [isTastingEnabled, setIsTastingEnabled] = useState(false);
    const [isDescriptionEnabled, setIsDescriptionEnabled] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(0);
    const [menuUrl, setMenuUrl] = useState();
    const [restaurantData, setRestaurantData] = useState({
        name: "",
        description: "",
        image_path: ""
    });
    const [menu, setMenu] = useState([]);
    const [tastingMenu, setTastingMenu] = useState([]);
    const [allergensList, setAllergensList] = useState([]);
    
    const printRef = useRef();

    const mapItemLanguage = (data) => {
        return data ? {
            label: data.name.charAt(0).toUpperCase() + data.name.slice(1),
            value: data.short_code
        } : null
    }

    useEffect(() => {
        async function loadLanguages() {
            const res = await getLanguages();
            if (res.response) {
                const lng = res.response.map(it => mapItemLanguage(it));
                setLanguages(lng);
            }
        }
        loadLanguages();
    }, []);

    useEffect(() => {
        async function loadTastingMenu() {
            const res = await getTastingMenu(restaurantId);
            let tempTastingMenu = [];
            if (res.outcome) {
                tempTastingMenu = res.menus;
                tempTastingMenu.forEach((it) => {
                    it.plates && it.plates.forEach(async (plate, index) => {
                        const tempDish = await getDishById(plate.id);
                        it.plates[index] = tempDish.dish;
                    });
                })
                setTastingMenu(tempTastingMenu);
            }
        }

        async function loadCourses() {
            const restaurantDetail = await getRestaurantByToken(token, selectedLang.value, selectedCourse);
            setRestaurantData(restaurantDetail)
            let temp = [];
            restaurantDetail.courses.forEach(async (item) => {
                const res = await getCoursesById(item.id, selectedLang.value, selectedCourse);
                temp.push(res);
                setMenu(temp);
            });
        }

        async function loadAllergens() {
            const res = await getFoodSetList(selectedLang.value);
            const urlSulfites = "https://mycontactlessmenu.mycia.it/assets/images/icons/allergie/solfiti.png";
            const urlFrozen = "https://mycontactlessmenu.mycia.it/assets/images/icons/allergie/surgelati.png";

            res.allergens = res.allergens.filter((it) => it.id !== 12 && it.id !== 17)
            res.allergens.push({
                path: urlFrozen,
                name: "Surgelati"
            })
            res.allergens.push({
                path: urlSulfites,
                name: "Solfiti"
            })
            if (res.outcome) setAllergensList(res.allergens);
        }
        
        loadTastingMenu();
        loadCourses();
        loadAllergens();
    }, [selectedLang, selectedCourse, restaurantId, token]);

    useEffect(() => {
        setMenuUrl(buildContactlessMenuURL(PRINT_ENUM.ORDER_AT_TABLE, token));
    }, [token])

    const getHeaderMenu = () => {
        switch (selectedStyleMenu) {
            case 1:
                return MenuHeaderFirst(restaurantData);
            case 2:
                return MenuHeaderSecond(restaurantData);
            case 3:
                return MenuHeaderThird(restaurantData);
            default:
                return MenuBodyFirst(restaurantData);
        }
    }

    const getBodyMenu = (item) => {
        const params = {
            name: item.name,
            subItems: item.dishes,
            isDescriptionEnabled
        }
        switch (selectedStyleMenu) {
            case 1:
                return MenuBodyFirst(params);
            case 2:
                return MenuBodySecond(params);
            case 3:
                return MenuBodyThird(params);
            default:
                return MenuBodyFirst(params);
        }
    }

    const getTastingBodyMenu = (item) => {
        switch (selectedStyleMenu) {
            case 1:
                return TastingMenuFirst(item);
            case 2:
                return TastingMenuSecond(item);
            case 3:
                return TastingMenuThird(item);
            default:
                return TastingMenuFirst(item);
        }
    }

    const noOptionsMessage = ({ inputValue }) => (inputValue ? "Nessuno" : "No results found");

    const onLanguageOptionSelected = (event) => setSelectedLang(event)

    return (
        <div className="DishMenuPrint">
            <Header />

            <div className="px-mobile bg-mycia-lightWhiteSmoke">
                <NavBack label="Scheda ristorante" />

                <p className="text-lg font-semibold">In che lingua vuoi che sia il menù?</p>
                <Select
                    id="type_1"
                    className="mt-4"
                    styles={{
                        option: myCIASelect.option,
                        control: myCIASelectWhiteControl.control,
                    }}
                    isClearable={false}
                    isSearchable={true}
                    options={languages}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={selectedLang}
                    onChange={onLanguageOptionSelected}
                />
                
                <p className="mt-6 text-lg font-semibold">Stile del menù</p>
                <div className="mt-4 flex gap-x-4">
                    {
                        [1, 2, 3].map((it, key) => (
                            <div key={key} className={`py-2 px-8 flex justify-center items-center bg-white rounded-lg cursor-pointer ${selectedStyleMenu === it ? `select-mycia-shadow` : `opacity-50`}`} onClick={() => setSelectedStyleMenu(it)}>
                                {it}
                            </div>
                        ))
                    }
                </div>

                <div className="flex justify-between mt-6">
                    <p className="text-lg font-semibold">Stampa solo menù degustazione</p>

                    <label
                        htmlFor="toggle-tasting-menu"
                        className="relative mx-mobile ml-2 inline-flex cursor-pointer items-center"
                    >
                        <input id="toggle-tasting-menu" className="peer sr-only" type="checkbox" checked={isTastingEnabled} onChange={() => setIsTastingEnabled(prev => !prev)}/>
                        <div className="peer toggle-mycia h-6 w-11 after:h-5 after:w-5 ml-0"></div>
                    </label>
                </div>

                <div className="flex justify-between mt-6">
                    <p className="text-lg font-semibold">Includi descrizioni dei piatti</p>
                    
                    <label
                        htmlFor="toggle-description"
                        className="relative mx-mobile ml-2 inline-flex cursor-pointer items-center"
                    >
                        <input id="toggle-description" className="peer sr-only" type="checkbox" checked={isDescriptionEnabled} onChange={() => setIsDescriptionEnabled(prev => !prev)} />
                        <div className="peer toggle-mycia h-6 w-11 after:h-5 after:w-5 ml-0"></div>
                    </label>
                </div>

                <p className="mt-6 text-lg font-semibold">Stampa i menù con i piatti contrassegnati con:</p>
                <div className="mt-4 flex flex-wrap justify-center gap-y-6 gap-x-6">
                    <div className={`column-item ${selectedCourse === 0 ? `select-mycia-shadow` : `select-mycia-shadow-none opacity-40`}`} onClick={() => setSelectedCourse(0)}>
                        <p className="m-auto text-center">Pranzo</p>
                    </div>
                    <div className={`column-item ${selectedCourse === 1 ? `select-mycia-shadow` : `select-mycia-shadow-none opacity-40`}`} onClick={() => setSelectedCourse(1)}>
                        <p className="m-auto text-center">Cena</p>
                    </div>
                    <div className={`column-item ${selectedCourse === 2 ? `select-mycia-shadow` : `select-mycia-shadow-none opacity-40`}`} onClick={() => setSelectedCourse(2)}>
                        <p className="m-auto text-center">Entrambi</p>
                    </div>
                </div>

                <ReactToPrint
                    trigger={() =>
                        <div className="button-mycia mt-10">
                            STAMPA MENÙ
                        </div>
                    }
                    content={() => printRef.current}
                    bodyClass={"visible-print-div"}
                    pageStyle="@page { size: A4; }"
                />

                <div ref={printRef} className="min-h-[400px] flex flex-col mt-10 py-4 px-8 rounded-xl bg-white">
                    <div className="visible-item">
                        <img className="mx-auto max-h-24" src={restaurantData.image_path} alt="logo"/>

                        {getHeaderMenu()}

                        {menuUrl &&
                            <QRCode
                                size={220}
                                style={{ margin: "20px auto", height: "200px", maxWidth: "200px" }}
                                value={menuUrl}
                                viewBox={`0 0 220 220`}
                            />
                        }
                    </div>
                    <div className="flex-1">
                        {
                            !isTastingEnabled ? (
                                <div>
                                    <p className="w-full font-semibold text-center">Menù</p>
                                    {
                                        menu.map((it, index) => (
                                            <div key={index}>
                                                {getBodyMenu(it)}
                                            </div>
                                        ))
                                    }
                                </div>
                            ) : (
                                <div className="flex flex-col gap-y-8">
                                    {
                                        tastingMenu.map((it, index) => (
                                            <div key={index}>
                                                {getTastingBodyMenu(it)}
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>

                    <hr className="mb-4 mt-12 border-b-2 border-mycia-dimGray" />

                    <div className="flex flex-wrap gap-x-6 gap-y-4 my-4 mx-4">
                        {
                            allergensList.map((it, index) => (
                                <div key={index}>
                                    <img src={it.path} className="w-6 h-6 mx-auto" alt="allergen"/>
                                    <p className="mt-2 text-center text-xs">{it.name}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <ReactToPrint
                    trigger={() =>
                        <div className="button-mycia mt-10">
                            STAMPA MENÙ
                        </div>
                    }
                    content={() => printRef.current}
                    pageStyle="@page { size: A4; }"
                />
            </div>

            <Footer />
        </div>
    )
}

export default DishMenuPrint;