import React from "react";

function Delete(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.92 69.35" className={className} onClick={onClick}> 
            <g>
                <g>
                    <path d="M8.25 61.56L6.23 18.23h-3.1A3.02 3.02 0 010 15.32a3.02 3.02 0 012.91-3.13h13.34V7.75c0-4.84 3.06-7.67 8.42-7.67h11.55c4.01-.59 7.74 2.19 8.33 6.2.07.49.09.99.07 1.48v4.44H57.8a3.02 3.02 0 013.13 2.91 3.02 3.02 0 01-2.91 3.13h-3.31l-2.02 43.3c.14 4.18-3.13 7.68-7.31 7.81-.31.01-.62 0-.93-.03H16.52c-4.15.4-7.84-2.64-8.24-6.8-.03-.32-.04-.64-.03-.96m35.36 1.66c1.54.04 2.82-1.18 2.85-2.72v-.25l1.91-42.01H12.46l2.02 42.01a2.79 2.79 0 002.62 2.96h26.51zm-24.3-6.81l-.91-31.15c-.09-1.13.75-2.12 1.88-2.21h.34c1.2-.07 2.22.84 2.3 2.03v.15l.91 31.15c.08 1.15-.79 2.14-1.94 2.22h-.33c-1.19.05-2.19-.87-2.24-2.05v-.13m3.27-44.21h15.73V7.99c.03-1.26-.97-2.32-2.23-2.35-.1 0-.21 0-.31.01H25.13c-1.25-.15-2.38.74-2.53 1.99-.01.12-.02.23-.02.35l.02 4.21zm5.6 44.21V25.23c-.04-1.16.86-2.14 2.03-2.19h.25c1.16-.09 2.18.78 2.27 1.94V56.4c.04 1.16-.86 2.14-2.03 2.19h-.24c-1.16.09-2.18-.77-2.27-1.93v-.25m8.91-.03l.91-31.15c0-1.18.95-2.15 2.14-2.15h.17c1.12-.1 2.11.74 2.21 1.86v.32l-.91 31.15c.02 1.19-.93 2.16-2.11 2.18h-.13c-1.15.11-2.16-.74-2.27-1.89-.01-.11-.01-.22 0-.33"></path>
                </g>
            </g>
        </svg>
    );
}

export default Delete;
