import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteDeliveryRate, getDeliveryHours, getDeliveryInfo, getDeliveryRate, setDeliveryEmail, setDeliveryPhone, toggleDeliveryEmail, toggleDeliveryPhone } from '../apis/restaurants';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import DateSection from '../components/MenuService/DateSection';
import ItemRate from '../components/MenuService/ItemRate';

const weekDays = [
    {
        name: 'MON',
        label: 'Lunedì',
    },
    {
        name: 'TUE',
        label: 'Martedì',
    },
    {
        name: 'WED',
        label: 'Mercoledì',
    },
    {
        name: 'THU',
        label: 'Giovedì',
    },
    {
        name: 'FRI',
        label: 'Venerdì',
    },
    {
        name: 'SAT',
        label: 'Sabato',
    },
    {
        name: 'SUN',
        label: 'Domenica',
    }
]

function CustomizeDelivery(props) {
    const navigate = useNavigate();
    const { restaurantId } = useParams();
    const [weekSlots, setWeekSlots] = useState([]);
    const [rateData, setRateData] = useState([]);
    const [info, setInfo] = useState({});
    const [infoStatus, setInfoStatus] = useState({});

    const loadData = useCallback(async () => {
        const res = await getDeliveryHours(restaurantId);
        setWeekSlots(res.hours);
    }, [restaurantId]);

    useEffect(() => {
        async function loadRateData() {
            const res = await getDeliveryRate(restaurantId);
            setRateData(res.response)
        }

        async function loadInfo() {
            const res = await getDeliveryInfo(restaurantId);
            setInfoStatus(res.data.status);
            setInfo(res.data.fields);
        }

        loadInfo();
        loadData();
        loadRateData();
    }, [loadData, restaurantId]);

    const addNewDeliveryRate = () => {
        setRateData(prev => [
            ...prev,
            {
                id: "",
                distance_from: "0.00",
                distance_to: "0.00",
                cost_increase: "0.00",
                medium_time: "0",
                min_amount: "0",
            }
        ])
    }

    const filterWeekSlots = (name) => weekSlots.filter(it => it.day === name);

    const onClickBackHandler = () => navigate(-1)

    const onDeleteRate = async (index, id) => {
        if (id) {
            const res = await deleteDeliveryRate(restaurantId, id);
            if (!res.outcome) return;
        }
        setRateData(prev => prev.filter((it, k) => k !== index));
    }

    const onChangeEmail = async ({ target }) => await setDeliveryEmail(restaurantId, target.value);
    const onChangePhone = async ({ target }) => await setDeliveryPhone(restaurantId, target.value);
    
    const toggleEmail = async ({ target }) => {
        const res = await toggleDeliveryEmail(restaurantId, infoStatus.email);
        if (res.outcome) setInfoStatus(prev => ({
            ...prev,
            email: res.status
        }));
    }

    const togglePhone = async ({ target }) => {
        const res = await toggleDeliveryPhone(restaurantId, infoStatus.sms);
        if (res.outcome) setInfoStatus(prev => ({
            ...prev,
            sms: res.status
        }));
    }

    return (
        <div className="CustomizeDelivery">
            <Header isDividerExist={true} />

            <div className="px-6 py-6">
                <div className="flex cursor-pointer items-center" onClick={onClickBackHandler}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="h-4 w-4">
                        <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                    </svg>

                    <p className="text-lg font-light underline">Servizi menù digitale</p>
                </div>

                <p className="font-semibold text-xl mt-6">Opzioni delivery</p>

                <p className="font-semibold mt-4">Specifica gli orari in cui effettui le consegne</p>

                {
                    weekDays.map((it, index) => (
                        <DateSection key={index} className="mt-6" label={it.label} type="delivery" name={it.name} timeSlots={filterWeekSlots(it.name)} restaurantId={restaurantId} />
                    ))
                }
            </div>

            <hr className="my-4 border-mycia-dimGray border-t" />
            
            <div className="p-6">
                <p className="font-semibold">Opzioni in base alla distanza</p>
                
                {
                    rateData.map((it, index) => (
                        <ItemRate key={index} index={index} restaurantId={restaurantId} data={it} className="mt-4" onDelete={onDeleteRate} />
                    ))
                }

                <div className="button-mycia-white mt-6" onClick={addNewDeliveryRate}>
                    <p>NUOVA FASCIA DI CONSEGNA</p>
                </div>
            </div>

            <hr className="my-2 border-mycia-dimGray border-t" />

            <div className="px-6 py-4">
                <label
                    htmlFor="toggle-phone"
                    className="relative mx-mobile ml-2 inline-flex cursor-pointer items-center"
                >
                    <input id="toggle-phone" className="peer sr-only" type="checkbox" checked={infoStatus.sms} onChange={togglePhone} />
                    <div className="peer toggle-mycia h-6 w-11 after:h-5 after:w-5 ml-0"></div>
                    <span className="ml-6 text-lg font-semibold">Attiva le notifiche per ricevere gli ordini via SMS</span>
                </label>

                <p className="mt-4">Modifica il tuo numero di telefono.</p>

                <div className="flex gap-x-4 mt-4">
                    <input value="+39" className="input-mycia-secondary w-[7ch]" disabled/>
                    <input type="tel" placeholder="333 4567777" className="input-mycia-secondary" defaultValue={info.phone} onBlur={onChangePhone}/>
                </div>
            </div>

            
            <hr className="my-2 border-mycia-dimGray border-t" />

            <div className="px-6 pt-4 pb-16">
                <label
                    htmlFor="toggle-mail"
                    className="relative mx-mobile ml-2 inline-flex cursor-pointer items-center"
                >
                    <input id="toggle-mail" className="peer sr-only" type="checkbox" checked={infoStatus.email} onChange={toggleEmail} />
                    <div className="peer toggle-mycia h-6 w-11 after:h-5 after:w-5 ml-0"></div>
                    <span className="ml-6 text-lg font-semibold">Attiva le notifiche per ricevere gli ordini via mail</span>
                </label>

                <p className="mt-4">Modifica il tuo indirizzo email.</p>

                <input type="email" placeholder="mail@mail.com" className="input-mycia-secondary mt-4" defaultValue={info.email} onBlur={onChangeEmail}/>
            </div>

            <Footer />
        </div>
    )
}

export default CustomizeDelivery;