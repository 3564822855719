import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Analytics from '../Icon/Analytics';
import Communication from '../Icon/Communication';
import Person from '../Icon/Person';
import Transparency from '../Icon/Transparency';

function BenefitsPartnership(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { className } = props;

    const data = [
        {
            label: t("loyalty"),
            description: t("loyalty_desc"),
            icon: <Person className="w-12 h-12" />
        },
        {
            label: t("transparency"),
            description: t("transparency_desc"),
            icon: <Transparency className="w-12 h-12" />
        },
        {
            label: t("analytics"),
            description: t("analytics_desc"),
            icon: <Analytics className="w-12 h-12" />
        },
        {
            label: t("communication"),
            description: t("communication_desc"),
            icon: <Communication className="w-12 h-12" />
        }
    ];

    const onClickCreateProfile = () => {
        navigate("./registration/account");
    }
    
    return (
        <div className={className}>
            <p className="mx-mobile mt-12 font-bold text-center text-xl">I vantaggi per i partner di MyCIA</p>

            <div className="flex flex-row space-x-8 mt-6 px-mobile overflow-x-auto">
                {
                    data.map((item, key) => (
                        <div key={key} className="flex flex-col items-center justify-center px-4 bg-white flex-shrink-0 rounded-2xl md:w-72 w-11/12 h-56">
                            {item.icon}

                            <p className="font-semibold text-center mt-4">{item.label}</p>
                            <p className="font-light text-center mt-1">{item.description}</p>
                        </div>
                    ))
                }
            </div>

            <div className="button-mycia mx-mobile mt-10" onClick={onClickCreateProfile}>
                <p>CREA UN ACCOUNT</p>
            </div>
        </div>
    )
}

export default BenefitsPartnership;