import { doAuthPost } from "./api-client";
import { API_DOMAIN } from "./constants";

async function createTastingMenu(resId, menu) {
    const payload = new URLSearchParams({
        todo: 'create-menu',
        script: 'scripts/step-5-d.php',
        ris_id: resId,
        name: menu.name,
        description: menu.description,
        prezzo_unico: menu.price
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getTastingMenu(resId) {
    const payload = new URLSearchParams({
        todo: 'get-menus',
        script: 'scripts/step-5-d.php',
        ris_id: resId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteTastingMenu(resId, menuId) {
    const payload = new URLSearchParams({
        todo: 'delete-menu',
        script: 'scripts/step-5-d.php',
        ris_id: resId,
        mnd_id: menuId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateFieldTastingMenu(resId, menuId, fieldName, fieldValue) {
    const payload = new URLSearchParams({
        todo: 'update-menu',
        script: 'scripts/step-5-d.php',
        ris_id: resId,
        mnd_id: menuId,
        field: fieldName,
        value: fieldValue
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function addPlate(resId, menuId, plateId) {
    const payload = new URLSearchParams({
        todo: 'add-plate',
        script: 'scripts/step-5-d.php',
        ris_id: resId,
        mnd_id: menuId,
        pia_id: plateId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deletePlate(resId, menuId, plateId, menuPlateId) {
    const payload = new URLSearchParams({
        todo: 'remove-plate',
        script: 'scripts/step-5-d.php',
        ris_id: resId,
        mnd_id: menuId,
        pia_id: plateId,
        mpd_id: menuPlateId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getCourses(resId) {
    const payload = new URLSearchParams({
        todo: 'get-courses',
        script: 'scripts/step-5-d.php',
        ris_id: resId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getPlates(resId, courseId) {
    const payload = new URLSearchParams({
        todo: 'get-plates',
        script: 'scripts/step-5-d.php',
        ris_id: resId,
        prt_id: courseId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

export {
    createTastingMenu,
    getTastingMenu,
    deleteTastingMenu,
    updateFieldTastingMenu,
    addPlate,
    deletePlate,
    getCourses,
    getPlates,
}
