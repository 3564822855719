import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { deletePhoto, deletePhotoFromRestaurant } from '../../../apis/restaurants';
import { modalStyle } from '../../../style';
import Close from '../../Icon/Close';
import Delete from '../../Icon/Delete';
import { useTranslation } from "react-i18next";

function RestaurantPhotoModal(props) {
    const { t } = useTranslation();
    const { children, photoId, imgBig, imgSmall, onRefresh } = props;
    const { restaurantId } = useParams();

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onDelete = async () => {
        let res = await deletePhoto(imgBig, imgSmall)
        console.log(res);
        if (res.msg === "ok") {
            res = await deletePhotoFromRestaurant(restaurantId, photoId);
            if (res.msg === "ok") {
                onRefresh();
                closeModal();
            }
        }
    }

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="RestaurantPhotoModal"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Foto Ristorante</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <div className="mt-8 flex justify-center items-center min-h-[10rem]">
                        {imgBig && <img src={imgBig} className="mx-auto max-h-[20rem] rounded-mobile" alt="restaurant" />}
                    </div>


                    <div className="button-mycia-white w-full mt-8 flex items-center justify-center shadow-none bg-mycia-whiteSmoke" onClick={onDelete}>
                        <Delete className="h-4 w-4 cursor-pointer fill-black" />
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default RestaurantPhotoModal;