import React from "react";

function ArrowUpOutline(props) {
    const { className, onClick } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={`${className} ionicon`}
            onClick={onClick}
        >
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M176 249.38L256 170l80 79.38m-80-68.35V342"
            ></path>
            <path
                fill="none"
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="32"
                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
            ></path>
        </svg>
    );
}

export default ArrowUpOutline;
