import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getServicesTabs, getTranslationsPages, setServicesTabs, setTranslationPages } from "../../../apis/restaurants";
import { useTranslation } from "react-i18next";

const initialState = {
    "activity": "",
    "courses": "",
    "beverages": ""
}

function PersonalizeMenuService() {
    const { t } = useTranslation();
    const { restaurantId } = useParams();

    const [tabs, setTabs] = useState(initialState);
    const [translationToggle, setTranslationToggle] = useState(false);

    const loadTabs = useCallback(async () => {
        const res = await getServicesTabs(restaurantId);
        setTabs(res);
    }, [restaurantId]);

    const loadTranslationToggle = useCallback(async () => {
        const res = await getTranslationsPages(restaurantId);
        if (res.outcome) setTranslationToggle(res.traduzione_piatti);
    }, [restaurantId]);

    useEffect(() => {
        loadTabs();
        loadTranslationToggle();
    }, [loadTabs, loadTranslationToggle]);

    const onChangeHandler = ({ target }) => {
        setTabs(tabs => ({
            ...tabs,
            [target.name]: target.value
        }));
    }

    const onTranslationToggle = async () => {
        const res = await setTranslationPages(restaurantId, !translationToggle);
        if (res.outcome) setTranslationToggle(prev => !prev);
    }

    const onChangeTabs = async () => {
        const res = await setServicesTabs(restaurantId, tabs);
        if (res.outcome) alert('Le modifiche sono avvenute correttamente');
    }
    
    return (
        <div className="PersonalizeMenuService flex flex-col gap-y-6">
            <div className="rounded-mobile border-2 border-mycia-dimGray">
                <div className="relative flex items-start p-3.5">
                    <label htmlFor="default-toggle" className="relative inline-flex cursor-pointer items-center">
                        <input type="checkbox" checked={translationToggle} onChange={onTranslationToggle} id="default-toggle" className="peer sr-only" />
                        <div className="peer toggle-mycia"></div>
                        <span className="ml-4 font-semibold">Traduci i nomi dei piatti</span>
                    </label>
                </div>

                <hr className="border-t-2 border-mycia-dimGray" />

                <p className="p-3.5 font-light">
                    Se disattivi questo box i nomi dei piatti del tuo menù non verranno tradotti. In questo modo
                    preserverai l'identità dei tuoi piatti, ma la clientela estera potrebbe non capirne il nome. Gli
                    ingredienti e tutti gli altri dettagli del menù verranno comunque tradotti nelle diverse lingue.
                </p>
            </div>

            <div className="rounded-lg bg-mycia-whiteSmoke hidden">
                <div className="relative flex items-start p-3.5">
                    <label htmlFor="default-toggle" className="relative inline-flex cursor-pointer items-center">
                        <input type="checkbox" value="" id="default-toggle" className="peer sr-only" />
                        <div className="peer h-5 w-9 rounded-full bg-gray-200 after:absolute after:top-1 after:left-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-mycia-orangeRed peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-mycia-titaniumGray"></div>
                        <span className="ml-4 font-semibold">Rendi visibile il tasto “Filtra menù”</span>
                    </label>
                </div>

                <hr className="border-t-2 border-mycia-dimGray" />

                <div className="button-mycia-white mx-3.5 my-6">
                    <p>SCOPRI DI PIÙ</p>
                </div>
            </div>

            <div className="rounded-mobile border-2 border-mycia-dimGray py-4 px-3.5">
                <p className="font-light">
                    <span className="font-semibold">Le categorie del menù digitale</span><br/>Puoi rinominare a piacimento le
                    2 categorie del tuo menù digitale. Ricorda sempre però che il primo pulsante indica i tuoi piatti,
                    mentre il secondo le tue bevande.
                </p>

                <input type="text" className="input-mycia-secondary mt-4 text-sm" name="courses" value={tabs.courses} onChange={onChangeHandler} placeholder="PIATTI" />
                <input type="text" className="input-mycia-secondary mt-2 text-sm" name="beverages" value={tabs.beverages} onChange={onChangeHandler} placeholder="LISTA VINI/BEVANDE" />

                <div className="button-mycia mt-6" onClick={onChangeTabs}>
                    <p>SALVA MODIFICHE</p>
                </div>
            </div>
        </div>
    );
}

export default PersonalizeMenuService;
