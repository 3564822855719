import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import forms from "../../../logic/forms";
import { myCIASelect } from "../../../style";
import { useAuth } from "../../../logic/AuthContext";
import MyCIAInput from "../../Input/MyCIAInput";
import MyCIACheckBox from "../../Input/MyCIACheckBox";
import ItemService from "../Services/ItemService";
import { getRestaurantAllDetail, getCoursesAndTypesList, getServicesList, toggleService, modifyRestaurant } from "../../../apis/restaurants"
import { mapWeekDays } from "../../../logic/utils";
import { useTranslation } from "react-i18next";
import TimePicker from 'rc-time-picker';

const restaurantDataInitialState = {
    "NomeRistorante": "",
    "Indirizzo": "",
    "IndirizzoDefault": "",
    "LinkSito": "",
    "Orari": [],
    "Email": "",
    "Telefono": "",
    "LinkSocial[0]": "",
    "LinkSocial[1]": "",
    "FasciaPrezzo": "",
    "Descrizione": "",
    "Citta": "",
    "Provincia": "",
    "Regione": "",
    "Stato": "",
    "Zip": "",
    "MapsUrl": "",
    "Lat": "",
    "Long": ""
};

const dayListInitialState = [
    {
        name: "MON",
        label: "Lunedì",
        checked: false
    },
    {
        name: "TUE",
        label: "Martedì",
        checked: false
    },
    {
        name: "WED",
        label: "Mercoledi",
        checked: false
    },
    {
        name: "THU",
        label: "Giovedì",
        checked: false
    },
    {
        name: "FRI",
        label: "Venerdì",
        checked: false
    },
    {
        name: "SAT",
        label: "Sabato",
        checked: false
    },
    {
        name: "SUN",
        label: "Domenica",
        checked: false
    }
];

const timeInitialState = {
    firstStart: "",
    firstEnd: "",
    secondStart: "",
    secondEnd: ""
};

function EditForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { restaurantId } = useParams();

    const [weekDayDropDown, setWeekDayDropDown] = useState(false);
    const [coursesList, setCoursesList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [weekDayList, setWeekDayList] = useState([]);
    const [restaurantData, setRestaurantData] = useState(restaurantDataInitialState);
    const [errors, setErrors] = useState({});
    
    const [allDayClosed, setAllDayClosed] = useState(null);
    const [continuedWork, setContinuedWork] = useState(false);
    const [daysList, setDaysList] = useState(dayListInitialState);
    const [timeState, setTimeState] = useState(timeInitialState);
    
    // TODO: DEPENDS ON BACKEND
    const validators = {
        "NomeRistorante": forms.list(),
        "Indirizzo": forms.list(),
        "IndirizzoDefault": forms.list(),
        "LinkSito": forms.list(),
        "Email": forms.list(),
        "Telefono": forms.list(),
        "LinkSocial[0]": forms.list(),
        "LinkSocial[1]": forms.list(),
        "FasciaPrezzo": forms.list(),
        "Descrizione": forms.list(),
        "Citta": forms.list(),
        "Provincia": forms.list(),
        "Regione": forms.list(),
        "Stato": forms.list(),
        "Zip": forms.list(),
        "MapsUrl": forms.list(),
        "Lat": forms.list(),
        "Long": forms.list()
    };
    const prices = [
        {
            label: "€ - Economico",
            price: "(10-20€)"
        },
        {
            label: "€€- Prezzi moderati",
            price: "(20-35€)"
        },
        {
            label: "€€€ - Alto",
            price: "(35-50€)"
        },
        {
            label: "€€€€ - Raffinato",
            price: "(50+€)"
        }
    ];

    useEffect(() => {
        async function loadBusinessDetail() {
            const businessDetailResponse = (await getRestaurantAllDetail(restaurantId));
            const businessDetail = businessDetailResponse.data;

            let courses = {}, types = {};
            for (let index = 0; index < 3; index++) {
                const typeId = (businessDetail.ran_tipo_ristorante && businessDetail.ran_tipo_ristorante[index] && businessDetail.ran_tipo_ristorante[index].id) || 0;
                const courseId = (businessDetail.ran_linee_cucina && businessDetail.ran_linee_cucina[index] && businessDetail.ran_linee_cucina[index].id) || 0;
                
                types[`TipoRistorante[${index + 1}]`] = typeId;
                courses[`LineeCucina[${index + 1}]`] = courseId;
            }

            setWeekDayList(businessDetailResponse.aperture);
            setRestaurantData({
                "NomeRistorante": businessDetail.ran_ragione_sociale,
                "Indirizzo": businessDetail.ran_indirizzo,
                "IndirizzoDefault": businessDetail.ran_indirizzo,
                ...types,
                "LinkSito": businessDetail.ran_website,
                "Email": businessDetail.ran_email,
                "Telefono": businessDetail.ran_telefono,
                ...courses,
                "LinkSocial[0]": businessDetail.ran_instagram_url || "",
                "LinkSocial[1]": businessDetail.ran_facebook_url || "",
                "FasciaPrezzo": businessDetail.ran_fascia_prezzo,
                "Descrizione": businessDetail.ran_descrizione,
                "Citta": businessDetail.ran_citta,
                "Provincia": businessDetail.ran_provincia,
                "Regione": businessDetail.ran_regione,
                "Stato": businessDetail.ran_stato,
                "Zip": businessDetail.ran_zip,
                "MapsUrl": businessDetail.ran_url_maps,
                "Lat": "",
                "Long": ""
            });
        }

        async function loadCoursesAndTypes() {
            const response = await getCoursesAndTypesList();
            const types = response.tipi.map((item) => ({
                value: item.id,
                label: item.text
            }));
            const courses = response.linee.map((item) => ({
                value: item.id,
                label: item.text
            }));

            setTypesList(types);
            setCoursesList(courses);
        }

        async function loadSelectedServices() {
            const response = await getServicesList(restaurantId);
            setServicesList(response.servizi);
        }

        loadBusinessDetail();
        loadCoursesAndTypes();
        loadSelectedServices();
    }, [restaurantId]);

    const renderModifyError = fieldName => {
        if (undefined !== errors[fieldName]) {
            return (
                <p className="mt-2 text-sm text-mycia-red">{errors[fieldName]}</p>
            );
        }
    };

    const onBlurHandler = ({ target }) => {
        const result = forms.validateMany(validators, { [target.name]: target.value });
        setErrors({ ...errors, ...result });
    };

    const onChangeHandler = ({ target }) => {
        return setRestaurantData(restaurantData => ({
            ...restaurantData,
            [target.name]: target.value,
        }));
    };

    const onSelectHandler = async (id, selected) => await toggleService(restaurantId, id, selected);

    const onChangeWeekDayHandler = ({ target }) => {
        const data = daysList;
        data[data.findIndex(item => item.name === target.name)].checked = target.checked;
        setDaysList(data);
    };

    const onChangeTimeHandler = (name, value) => {
        return setTimeState(timeState => ({
            ...timeState,
            [name]: value,
        }));
    };

    function onSubmitWeekDay() {
        const nameOfDays = daysList.filter(item => item.checked !== false).map(item => item.name);
        let tempWeekDayList = weekDayList.filter(item => item.day_of_week.length !== 0);
        
        tempWeekDayList = tempWeekDayList.filter(item => !nameOfDays.includes(item.day_of_week));

        if (allDayClosed) {
            nameOfDays.forEach(item => {
                tempWeekDayList.push({
                    day_of_week: item,
                    open: "",
                    close: ""
                });
            });
        } else {
            if (continuedWork) {
                nameOfDays.forEach(item => {
                    tempWeekDayList.push({
                        day_of_week: item,
                        open: timeState.firstStart,
                        close: timeState.firstEnd
                    });
                });
            } else {
                nameOfDays.forEach(item => {
                    tempWeekDayList.push({
                        day_of_week: item,
                        open: timeState.firstStart,
                        close: timeState.firstEnd
                    });
                    tempWeekDayList.push({
                        day_of_week: item,
                        open: timeState.secondStart,
                        close: timeState.secondEnd
                    });
                });
            }
        }
        setWeekDayList(tempWeekDayList);
    };

    const onSubmitHandler = async evt => {
        evt.preventDefault();

        let formData = {};
        mapWeekDays(weekDayList).forEach(item => {
            formData = {
                ...formData,
                [`Orari[${item.name}]`]: item.time,
            }
        });

        const payload = {
            ...restaurantData,
            ...formData
        };

        if (true) {
            let errors = forms.validateMany(validators, payload);

            if (forms.anyErrors(errors)) {
                setErrors(errors);
            } else {
                let response = await modifyRestaurant(restaurantId, payload);

                if (response.rac_token) {
                    alert("Dati modificati correttamente");
                    navigate(-1);
                }
            }
        } else {
            alert("Accedi o registrati");
        }
    };

    const mapReactSelect = (name, value) => {
        return {
            target: {
                name: name,
                value: value
            }
        };
    };

    const findOptionFromList = (list, value) => list.find(item => item.value == value );

    const noOptionsMessage = ({ inputValue }) => (inputValue ? "Nessuno" : "No results found");

    let onClickStampMenuHandler = () => setWeekDayDropDown(!weekDayDropDown);

    return (
        <form method="POST" action="" onSubmit={onSubmitHandler} className="RestaurantEditForm">
            <fieldset>
                <legend className="text-3xl font-semibold">Modifica profilo ristorante</legend>

                <label htmlFor="name" className="mt-8 inline-block text-sm">NOME RISTORANTE</label>
                <input id="name" name="NomeRistorante" type="text" className="input-mycia-secondary" value={restaurantData.NomeRistorante} onBlur={onBlurHandler} onChange={onChangeHandler} />

                <label htmlFor="email" className="mt-4 inline-block text-sm">EMAIL</label>
                <input id="email" name="Email" type="email" className="input-mycia-secondary" value={restaurantData.Email} onBlur={onBlurHandler} onChange={onChangeHandler} />

                <label htmlFor="address" className="mt-4 inline-block text-sm">INDIRIZZO</label>
                <input id="address" name="Indirizzo" type="text" className="input-mycia-secondary" value={restaurantData.Indirizzo} onBlur={onBlurHandler} onChange={onChangeHandler} />

                <label htmlFor="telephone" className="mt-4 inline-block text-sm">TELEFONO</label>
                <input id="telephone" name="Telefono" type="text" className="input-mycia-secondary" value={restaurantData.Telefono} onBlur={onBlurHandler} onChange={onChangeHandler} />

                <label className="mt-4 inline-block text-sm">TIPO DI RISTORANTE</label>
                <Select
                    id="type_1"
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={typesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(typesList, restaurantData["TipoRistorante[1]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("TipoRistorante[1]", event.value))}
                />
                <Select
                    id="type_2"
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={typesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(typesList, restaurantData["TipoRistorante[2]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("TipoRistorante[2]", event.value))}
                />
                <Select
                    id="type_3"
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={typesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(typesList, restaurantData["TipoRistorante[3]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("TipoRistorante[3]", event.value))}
                />

                <label className="mt-4 inline-block text-sm">LINEA DI CUCINA</label>
                <Select
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={coursesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(coursesList, restaurantData["LineeCucina[1]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("LineeCucina[1]", event.value))}
                />
                <Select
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={coursesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(coursesList, restaurantData["LineeCucina[2]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("LineeCucina[2]", event.value))}
                />
                <Select
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={coursesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(coursesList, restaurantData["LineeCucina[2]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("LineeCucina[3]", event.value))}
                />

                <label htmlFor="website" className="mt-4 inline-block text-sm">LINK SITO WEB</label>
                <input id="website" name="LinkSito" type="text" className="input-mycia-secondary" value={restaurantData.LinkSito} onBlur={onBlurHandler} onChange={onChangeHandler} />

                <label className="mt-4 inline-block text-sm">LINK PAGINE SOCIAL</label>
                <input id="facebook_url" name="LinkSocial[0]" type="text" className="input-mycia-secondary" value={restaurantData["LinkSocial[0]"]} onBlur={onBlurHandler} onChange={onChangeHandler} />
                <input id="instagram_url" name="LinkSocial[1]" type="text" className="input-mycia-secondary mt-2" value={restaurantData["LinkSocial[1]"]} onBlur={onBlurHandler} onChange={onChangeHandler} />

                <label className="mt-8 inline-block text-sm">FASCIA DI PREZZO</label>
                <div>
                    {prices.map((item, key) => (
                        <MyCIAInput
                            id={`price-${key + 1}`}
                            name="FasciaPrezzo"
                            type="radio"
                            value={key + 1}
                            key={key}
                            className="mt-1"
                            labelClassName="p-1"
                            isLabelMaxWidth={true}
                            onChange={onChangeHandler}
                            onBlur={onBlurHandler}
                            checked={key + 1 == restaurantData.FasciaPrezzo}
                        >
                            <div className="flex justify-between text-lg font-light text-black ">
                                <p>{item.label}</p>
                                <p>{item.price}</p>
                            </div>
                        </MyCIAInput>
                    ))}
                </div>

                <label className="mt-8 inline-block text-sm">DESCRIZIONE</label>
                <textarea
                    id="description"
                    name="Descrizione"
                    placeholder="Inserisci una descrizione del tuo locale"
                    className="input-mycia-secondary resize-none"
                    rows="10"
                    value={restaurantData.Descrizione}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                />

                <label className="mt-8 inline-block text-sm">SERVIZI</label>
                <div className="mt-4 flex flex-wrap gap-y-6 gap-x-6">
                    {servicesList.map((item, key) => (
                        <ItemService
                            key={key}
                            id={item.ser_id}
                            name={item.nome}
                            photo={item.immagine}
                            selected={item.selected}
                            onSelected={onSelectHandler}
                        />
                    ))}
                </div>

                <label className="mt-4 inline-block text-sm">ORARI DEL RISTORANTE</label>

                <div className="button-mycia-white relative mt-2">
                    <div className="flex cursor-pointer select-none items-center" onClick={onClickStampMenuHandler}>
                        <p className="flex-1 font-semibold uppercase">SCEGLI UNO O PIÙ GIORNI</p>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            className={`w-6 self-end transition-all duration-300 ${weekDayDropDown ? `rotate-180` : `rotate-0`}`}
                        >
                            <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                        </svg>
                    </div>

                    <div
                        className={`shadow-mycia absolute left-0 max-h-0 mt-5 w-full rounded-lg overflow-hidden transition-all z-50 duration-300 ${weekDayDropDown ? `max-h-96` : ``} bg-white`}
                    >
                        {daysList.map((item, key) => (
                            <MyCIACheckBox
                                id={`weekday_${key}`}
                                name={item.name}
                                key={key}
                                className="mx-4 pr-8 my-3 z-50"
                                labelClassName="w-full"
                                onChange={onChangeWeekDayHandler}
                            >
                                <span className="font-semibold text-center w-full">{item.label}</span>
                            </MyCIACheckBox>
                        ))}
                    </div>
                </div>

                <p className="mt-8 text-center text-lg font-semibold">È turno di chiusura?</p>
                <div className="mt-1 flex justify-center">
                    <MyCIAInput
                        id="closetime-yes"
                        name="closetime"
                        type="radio"
                        className="mx-4 mt-1"
                        labelClassName="p-1"
                        onChange={({target}) => setAllDayClosed(target.checked)}
                        checked={allDayClosed}
                    >
                        <div className="text-center text-lg font-semibold text-black ">
                            <p>Si</p>
                        </div>
                    </MyCIAInput>

                    <MyCIAInput
                        id="closetime-no"
                        name="closetime"
                        type="radio"
                        className="mx-4 mt-1"
                        labelClassName="p-1"
                        onChange={({target}) => setAllDayClosed(!target.checked)}
                        checked={!allDayClosed}
                    >
                        <div className="text-center text-lg font-semibold text-black ">
                            <p>No</p>
                        </div>
                    </MyCIAInput>
                </div>
                {   
                    !allDayClosed &&
                    <div>
                        <p className="mt-8 text-center text-lg font-semibold">Fai orario continuato?</p>
                        <div className="mt-1 flex justify-center">
                            <MyCIAInput
                                id="fulltime-yes"
                                name="fulltime"
                                type="radio"
                                className="mx-4 mt-1"
                                labelClassName="p-1"
                                onChange={({target}) => setContinuedWork(target.checked)}
                                checked={continuedWork}
                            >
                                <div className="text-center text-lg font-semibold text-black ">
                                    <p>Si</p>
                                </div>
                            </MyCIAInput>

                            <MyCIAInput
                                id="fulltime-no"
                                name="fulltime"
                                type="radio"
                                className="mx-4 mt-1"
                                labelClassName="p-1"
                                onChange={({target}) => setContinuedWork(!target.checked)}
                                checked={!continuedWork}
                            >
                                <div className="text-center text-lg font-semibold text-black ">
                                    <p>No</p>
                                </div>
                            </MyCIAInput>
                        </div>
                        <div>
                            {continuedWork != null  &&
                                <div>
                                    <p className="mt-8 text-center text-lg font-semibold">Inserisci l'orario</p>

                                    <div className="mt-4 flex items-center justify-center">
                                        <TimePicker showSecond={false} minuteStep={15} placeholder="--:--" className="mycia-time-picker-input" onChange={(e) => onChangeTimeHandler("firstStart", e.format("HH:mm"))}/>
                                        <p className="mx-4 text-lg font-semibold">-</p>
                                        <TimePicker showSecond={false} minuteStep={15} placeholder="--:--" className="mycia-time-picker-input" onChange={(e) => onChangeTimeHandler("firstEnd", e.format("HH:mm"))}/>
                                    </div>

                                    {!continuedWork && 
                                        <div className="mt-4 flex items-center justify-center">
                                            <TimePicker showSecond={false} minuteStep={15} placeholder="--:--" className="mycia-time-picker-input" onChange={(e) => onChangeTimeHandler("secondStart", e.format("HH:mm"))}/>
                                            <p className="mx-4 text-lg font-semibold">-</p>
                                            <TimePicker showSecond={false} minuteStep={15} placeholder="--:--" className="mycia-time-picker-input" onChange={(e) => onChangeTimeHandler("secondEnd", e.format("HH:mm"))}/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }

                <div className="button-mycia-white mt-10 cursor-pointer select-none uppercase" onClick={onSubmitWeekDay}>
                    <p>AGGIUNGI</p>
                </div>

                <label className="mt-8 inline-block w-full text-center font-semibold">ORARI SALVATI</label>
                <div className="mt-6 flex flex-col gap-y-4">
                    {mapWeekDays(weekDayList).map((item, key) => (
                        <div className="flex justify-between text-lg font-light" key={key}>
                            <p>{item.day}</p>
                            <p className="ml-8 text-end">{item.time !== "" ? item.time : "Chiuso"}</p>
                        </div>
                    ))}
                </div>
            </fieldset>

            <button className="button-mycia w-full mt-12">CONFERMA MODIFICHE</button>
        </form>
    );
}

export default EditForm;
