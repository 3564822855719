import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import ReactToPrint from 'react-to-print';
import { getBevarages, getLanguages } from '../apis/restaurants';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import MenuBodyFirst from '../components/MenuPrint/DishMenuBody/MenuBodyFirst';
import MenuBodySecond from '../components/MenuPrint/DishMenuBody/MenuBodySecond';
import MenuBodyThird from '../components/MenuPrint/DishMenuBody/MenuBodyThird';
import NavBack from '../components/NavBack/NavBack';
import { myCIASelect, myCIASelectWhiteControl } from '../style';

function DrinkMenuPrint(props) {
    const { restaurantId, token } = useParams();

     useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     }, [restaurantId]);
    
    const [languages, setLanguages] = useState([]);
    const [selectedLang, setSelectedLang] = useState({
        label: "Italiano",
        value: "it"
    });
    const [selectedStyleMenu, setSelectedStyleMenu] = useState(1);
    const [menu, setMenu] = useState([]);
    
    const printRef = useRef();

    const mapItemLanguage = (data) => {
        return data ? {
            label: data.name.charAt(0).toUpperCase() + data.name.slice(1),
            value: data.short_code
        } : null
    }

    useEffect(() => {
        async function loadLanguages() {
            const res = await getLanguages();
            if (res.response) {
                const lng = res.response.map(it => mapItemLanguage(it));
                setLanguages(lng);
            }
        }
        loadLanguages();
    }, []);

    useEffect(() => {
        async function loadDrinks() {
            const res = await getBevarages(restaurantId, selectedLang.value);
            setMenu(res);
        }

        loadDrinks();
    }, [selectedLang, restaurantId, token]);

    const getBodyMenu = (item) => {
        const params = {
            name: item.name,
            subItems: item.drinks
        }
        switch (selectedStyleMenu) {
            case 1:
                return MenuBodyFirst(params);
            case 2:
                return MenuBodySecond(params);
            case 3:
                return MenuBodyThird(params);
            default:
                return MenuBodyFirst(params);
        }
    }

    const noOptionsMessage = ({ inputValue }) => (inputValue ? "Nessuno" : "No results found");

    const onLanguageOptionSelected = (event) => setSelectedLang(event)

    return (
        <div className="DishMenuPrint">
            <Header />

            <div className="px-mobile bg-mycia-lightWhiteSmoke">
                <NavBack label="Scheda ristorante" />

                <p className="text-lg font-semibold">In che lingua vuoi che sia il menù?</p>
                <Select
                    id="type_1"
                    className="mt-4"
                    styles={{
                        option: myCIASelect.option,
                        control: myCIASelectWhiteControl.control,
                    }}
                    isClearable={false}
                    isSearchable={true}
                    options={languages}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={selectedLang}
                    onChange={onLanguageOptionSelected}
                />
                
                <p className="mt-6 text-lg font-semibold">Stile del menù</p>
                <div className="mt-4 flex gap-x-4">
                    {
                        [1, 2, 3].map((it, key) => (
                            <div className={`py-2 px-8 flex justify-center items-center bg-white rounded-lg cursor-pointer ${selectedStyleMenu === it ? `select-mycia-shadow` : `opacity-50`}`} onClick={() => setSelectedStyleMenu(it)}>
                                {it}
                            </div>
                        ))
                    }
                </div>

                <ReactToPrint
                    trigger={() =>
                        <div className="button-mycia mt-10">
                            STAMPA MENÙ
                        </div>
                    }
                    content={() => printRef.current}
                    bodyClass={"visible-print-div"}
                    pageStyle="@page { size: A4; }"
                />

                <div ref={printRef} className="min-h-[400px] flex flex-col mt-10 py-4 px-8 rounded-xl bg-white">
                    <div className="flex-1">
                        <p className="w-full font-semibold text-center">Menù</p>
                        <div className="flex flex-col gap-y-8">
                            {
                                menu.map((it, index) => (
                                    <div key={index}>
                                        {getBodyMenu(it)}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <ReactToPrint
                    trigger={() =>
                        <div className="button-mycia mt-10">
                            STAMPA MENÙ
                        </div>
                    }
                    content={() => printRef.current}
                    pageStyle="@page { size: A4; }"
                />
            </div>

            <Footer />
        </div>
    )
}

export default DrinkMenuPrint;