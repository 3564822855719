import React from 'react';

function MenuHeaderSecond(props) {
    const { name, description } = props;
    return (
        <div className="MenuHeaderSecond">
            <p className="mt-6 text-5xl text-center text-mycia-red font-secondStyle font-bold">{name}</p>
            <p className="mx-10 mt-8 text-lg text-start font-secondStyle font-semibold">{description}</p>
        </div>
    )
}

export default MenuHeaderSecond;