import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Services from "../components/MenuService/Services";

function MenuServices(props) {  
    return (
        <div className="MenuServices">
            <Header isDividerExist={true} isTopBarEnabled={true}/>

            <Services />

            <Footer />
        </div>
    );
}

export default MenuServices;
