import React from "react";
import { useParams } from "react-router-dom";
import { deleteIngredient } from "../../../apis/modify-restaurant";
import Delete from "../../Icon/Delete";
import Pen from "../../Icon/Pen";
import DishIngredientElemsModal from "./Modal/DishIngredientElemsModal";

function ItemIngredient(props) {
    const { restaurantId } = useParams();
    const { dishId, id, name, isEditable, onDelete } = props;

    const onClickDelete = async (e) => {
        const res = await deleteIngredient(restaurantId, dishId, id);
        if (res.rac_token && onDelete) onDelete(id);
    }

    return (
        <div className={`flex w-fit items-center rounded-lg ${isEditable ? `bg-mycia-red` : `bg-mycia-dimGray`} px-2.5 py-1`}>
            {isEditable === 1 &&
                <DishIngredientElemsModal restaurantId={restaurantId} dishId={dishId} composedId={id}>
                    <Pen className="cursor-pointer icon-mycia fill-white" />
                </DishIngredientElemsModal>
            }

            <p className="ml-3 select-none text-center text-lg font-semibold text-white">{name}</p>

            <Delete onClick={onClickDelete} className="ml-3 h-3.5 w-3.5 cursor-pointer fill-white" />
        </div>
    );
}

export default ItemIngredient;
