import React, { useState, useEffect, useCallback } from "react";
import ItemCategory from "./ItemCategory";
import ItemService from "./ItemService";
import ItemWeekDay from "./ItemWeekDay";
import { getRestaurantDetail, getRestaurantServices, getRestaurantDishes, getRestaurantWines } from "../../apis/restaurants";
import { mapArrayToString, mapDishCategories, mapNumberStr, mapPrice, mapWeekDays, mapWineCategories } from "../../logic/utils";
import { Link, useNavigate } from "react-router-dom";
import QrCode from "../Icon/QrCode";
import Address from "../Icon/Address";
import Print from "../Icon/Print";
import { restaurantNoImage } from "../../apis/assets";
import { useTranslation } from "react-i18next";
import Cam from "../Icon/Cam";
import RestaurantPhotoModal from "./Modal/RestaurantPhotoModal";
import RestaurantPhotoUploadModal from "./Modal/RestaurantPhotoUploadModal";

const INITIAL_STATE_RESTAURANT_OPTIONS = {
    isContactlessMenuEnabled: false,
    isBookingEnabled: false,
    isMenuServicesEnabled: false,
    isQrCodeGeneratorEnabled: false,
}

function Details(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { restaurantId } = props;

    const [restaurantDetail, setRestaurantDetail] = useState([]);
    const [restaurantOptions, setRestaurantOptions] = useState(INITIAL_STATE_RESTAURANT_OPTIONS);
    const [restaurantDays, setRestaurantDays] = useState([]);
    const [services, setServices] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [restaurantLogo, setRestaurantLogo] = useState("");
    const [restaurantImages, setRestaurantImages] = useState([]);
    const [dishCategories, setDishCategories] = useState([]);
    const [wineCategories, setWineCategories] = useState([]);
    const [stampMenu, setStampMenu] = useState(false);

    const loadDetail = useCallback(async () => {
        const restaurantResponse = await getRestaurantDetail(restaurantId);

        setRestaurantDays(restaurantResponse.aperture);
        setRestaurantDetail(restaurantResponse.data);
        setReviews(restaurantResponse.valutazione);
        setRestaurantImages(restaurantResponse.foto_ris);
        setRestaurantLogo(restaurantResponse.data && restaurantResponse.data.ran_immagine);
        setRestaurantOptions({
            isContactlessMenuEnabled: restaurantResponse.isContactlessMenuEnabled,
            isBookingEnabled: restaurantResponse.isBookingEnabled,
            isMenuServicesEnabled: restaurantResponse.isMenuServicesEnabled,
            isQrCodeGeneratorEnabled: restaurantResponse.isQrCodeGeneratorEnabled,
        })
    }, [restaurantId]);

    useEffect(() => {
        loadDetail();

        async function loadService() {
            const serviceResponse = await getRestaurantServices(restaurantId);

            setServices(serviceResponse.servizi);
        }

        async function loadDishes() {
            const dishCatResponse = await getRestaurantDishes(restaurantId);

            setDishCategories(mapDishCategories(dishCatResponse));
        }

        async function loadWines() {
            const wineCatResponse = await getRestaurantWines(restaurantId);

            setWineCategories(mapWineCategories(wineCatResponse.elenco));
        }

        loadDetail();
        loadService();
        loadDishes();
        loadWines();
    }, [restaurantId, loadDetail]);

    let onClickStampMenuHandler = () => setStampMenu(!stampMenu);

    const navigateMenuModify = () => {
        navigate("./../menu");
        window.scrollTo(0, 0);
    };

    return (
        <div>
            <a href="/docs/MyCIA_Registrazione_Ristoratore.pdf">
                <div className="flex items-center justify-center gap-x-4 bg-mycia-blue py-2">
                    <svg id="Livello_2" className="h-5 w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <g id="Livello_1-2">
                            <g>
                                <path d="M11.71,15.32s-.02,0-.03,0c-.72-.02-1.32,.56-1.34,1.28h0s0,.01,0,.02c.03,.72,.65,1.28,1.37,1.25,.06,0,.11,0,.17,0,.7-.05,1.24-.66,1.19-1.36-.05-.7-.66-1.24-1.36-1.19Z" />
                                <path d="M12.1,6.05c-1.98,0-3.34,.9-3.7,1.99-.07,.19-.11,.4-.12,.6,0,.02,0,.05,0,.07,.03,.5,.47,.88,.97,.85,.68,0,.84-.35,1.2-.74,.31-.57,.9-.92,1.54-.92,.05,0,.1-.01,.14-.01,.74-.02,1.36,.57,1.38,1.31,0,.71-.49,1.09-1.47,1.76-.84,.43-1.38,1.28-1.43,2.22v.13h0c0,.67,.41,1,1.13,1,.53,.06,1.01-.32,1.08-.85,0-.05,0-.09,.01-.14,.04-.59,.44-.99,1.2-1.49,1.15-.74,1.88-1.42,1.88-2.75,0-1.93-1.74-3.04-3.8-3.04Z" />
                                <path d="M11.98,0C5.38,.04,.04,5.39,0,12c.04,6.61,5.39,11.96,11.99,12,6.61-.04,11.96-5.39,12.01-12C23.95,5.38,18.6,.04,11.98,0Zm.02,21.6c-5.29,0-9.58-4.3-9.59-9.59,0-5.3,4.29-9.6,9.59-9.6,5.3,0,9.6,4.29,9.6,9.59v.03c0,5.3-4.31,9.59-9.6,9.58Z" />
                            </g>
                        </g>
                    </svg>

                    <p className="text-lg font-semibold uppercase text-white">Tutorial</p>
                </div>
            </a>

            <div className="px-mobile py-8">
                {restaurantOptions?.isContactlessMenuEnabled && (
                    <Link to={`//orders.mycontactlessmenu.mycia.it/?token=${restaurantDetail.ran_token_iscrizione}`} target="_blank" rel="noopener noreferrer">
                        <div className="button-mycia-white">I TUOI ORDINI</div>
                    </Link>
                )}

                {restaurantOptions?.isMenuServicesEnabled && (
                    <Link to={`./../services/${restaurantDetail.ran_token_iscrizione}`}>
                        <div className="button-mycia-white mt-4 flex items-center justify-center">
                            <QrCode className="w-5 h-5" />

                            <p className="ml-4">SERVIZI MENÙ DIGITALE</p>
                        </div>
                    </Link>
                )}

                {restaurantOptions?.isQrCodeGeneratorEnabled && (
                    <Link to={`./../services/${restaurantDetail.ran_token_iscrizione}/customize/booking`}>
                        <div className="button-mycia-white mt-4 flex items-center justify-center">
                            <QrCode className="w-5 h-5" />

                            <p className="ml-4">GESTIONE DELLE PRENOTAZIONI</p>
                        </div>
                    </Link>
                )}
            </div>

            <div className="relative mt-2">
                <img className="aspect-[16/9] w-full md:aspect-auto md:max-h-[26rem]" src={restaurantLogo || restaurantNoImage} alt="" />

                <RestaurantPhotoUploadModal onRefresh={loadDetail}>
                    <div type="button" className="absolute bottom-4 right-4 p-2 bg-white rounded-full shadow-xl">
                        <Cam className="w-6 h-6" />
                    </div>
                </RestaurantPhotoUploadModal>
            </div>

            <div className="px-mobile flex select-none gap-x-2 overflow-y-auto pt-4">
                {restaurantImages.map((item, key) => (
                    <RestaurantPhotoModal photoId={item.rfo_id} imgBig={item.big} imgSmall={item.small} onRefresh={loadDetail}>
                        <img key={key} className="aspect-square w-20 rounded-md object-fill" src={item.small} alt="" />
                    </RestaurantPhotoModal>
                ))}
            </div>

            <div className="border-mycia-dimGray px-mobile mt-2 border-y py-4">
                <Link to="../restaurant/reviews">
                    <div className="button-mycia-white w-full my-4 font-semibold uppercase cursor-pointer">
                        RECENSIONI: <span className="text-mycia-red">{mapNumberStr(reviews.media)}</span> ({reviews.nr_voti} VALUTAZIONI)
                    </div>
                </Link>
            </div>

            <div className="px-mobile py-6">
                <Link to="./../modify">
                    <div className="flex items-center justify-between">
                        <p className="text-3xl font-semibold">{restaurantDetail.ran_ragione_sociale}</p>

                        <p className="cursor-pointer text-lg text-mycia-red underline">Modifica profilo</p>
                    </div>
                </Link>

                <div className="mt-5 flex items-center">
                    <Address className="w-5 h-5" />

                    <p className="ml-2 font-light">{restaurantDetail.ran_indirizzo}</p>
                </div>

                <hr className="border-mycia-dimGray my-6 border-t" />

                <p className="font-light">{restaurantDetail.ran_descrizione}</p>

                <p className="mt-6">
                    <span className="text-base font-semibold">Tipologie ristorante:</span> {mapArrayToString(restaurantDetail.tipi)}
                </p>

                <p className="mt-4">
                    <span className="text-base font-semibold">Linee di cucina:</span> {mapArrayToString(restaurantDetail.linee)}
                </p>

                <p className="mt-4">
                    <span className="text-base font-semibold">Fascia di prezzo:</span> {mapPrice(restaurantDetail.ran_fascia_prezzo)}
                </p>

                <p className="mt-4">
                    <span className="text-base font-semibold">Telefono:</span> {restaurantDetail.ran_telefono}
                </p>

                <p className="mt-6 text-lg font-semibold">Orari</p>

                <div>
                    {mapWeekDays(restaurantDays).map((item, key) => (
                        <ItemWeekDay key={key} day={item.day} time={item.time !== "" ? item.time : "Chiuso"} />
                    ))}
                </div>
            </div>

            <hr className="border-mycia-dimGray border-t" />

            <div className="px-mobile py-4">
                <Link to="./../modify">
                    <div className="flex items-center justify-between ">
                        <p className="text-xl font-semibold">Servizi</p>

                        <p className="cursor-pointer text-lg font-semibold text-mycia-red underline">Modifica servizi</p>
                    </div>
                </Link>
                <div className="mt-4 flex flex-col gap-y-6">{services && services.map((item, key) => <ItemService key={key} name={item.nome} photo={item.immagine} />)}</div>
            </div>

            <div className="bg-mycia-whiteSmoke">
                <div className="relative z-50 px-mobile pt-8">
                    <div className="button-mycia-white relative">
                        <div className="flex px-2 cursor-pointer items-center justify-between" onClick={onClickStampMenuHandler}>
                            <Print className="w-6 h-6" />

                            <p className="text-lg font-semibold uppercase">STAMPA MENÙ</p>

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className={`w-6 transition-all duration-300 ${stampMenu ? `rotate-180` : `rotate-0`}`}>
                                <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                            </svg>
                        </div>

                        <div className={`absolute z-50 left-0 mt-1 max-h-0 w-full overflow-hidden rounded-b-lg transition-all duration-300 ${stampMenu ? `max-h-96` : ``} bg-white`}>
                            <Link to={`./../menu/${restaurantDetail.ran_token_iscrizione}/dish`}>
                                <p className="cursor-pointer py-4 text-center text-lg font-semibold underline">Stampa listino piatti</p>
                            </Link>
                            <Link to={`./../menu/${restaurantDetail.ran_token_iscrizione}/drink`}>
                                <p className="cursor-pointer py-4 text-center text-lg font-semibold underline">Stampa listino bevande</p>
                            </Link>
                        </div>
                    </div>

                    <div className="button-mycia mt-8" onClick={navigateMenuModify}>
                        MODIFICA PIATTI MENÙ
                    </div>
                </div>

                <div className="bg-mycia-whiteSmoke py-8">
                    <div>
                        {dishCategories.map((item, key, arr) => (
                            <ItemCategory key={`dish_categories_${item.id}`} categoryRow={item} lastItem={arr.length - 1 === key} />
                        ))}
                    </div>

                    <hr className="border-mycia-dimGray my-8 border-t" />

                    <div className="px-mobile my-6">
                        <div className="button-mycia" onClick={navigateMenuModify}>
                            MODIFICA LISTA VINI E BEVANDE
                        </div>
                    </div>

                    <div>{wineCategories && wineCategories.map((item, key, arr) => <ItemCategory key={`wine_categories_${item.name}`} categoryRow={item} lastItem={arr.length - 1 === key} />)}</div>
                </div>
            </div>

            <div className="px-mobile py-12">
                <p className="text-xl font-semibold">Hai più di un ristorante?</p>

                <p className="mt-2 text-lg">
                    <a href="mailto:nicodonati@cartaidentitalimentare.com">
                        <span className="text-mycia-orangeRed font-light underline">Clicca qui</span>
                    </a>{" "}
                    per contattarci, ti invieremo una mail tramite la quale potrai fornirci i dati necessari all'inserimento delle altre tue strutture.
                </p>
            </div>
        </div>
    );
}

export default Details;
