import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteTable, getTableServices, setTables } from '../apis/restaurants';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Delete from '../components/Icon/Delete';

function CustomizeLocalOrder(props) {
    const navigate = useNavigate();
    const { restaurantId } = useParams();

    const [range, setRange] = useState([]);
    const [table, setTable] = useState({
        type: range, 
        number: "",
        from: "",
        to: "",
        prefix: "",
        suffix: "",
    });

    const [singles, setSingles] = useState([]);
    const [customTable, setCustomTable] = useState({
        from: "",
        to: "",
    });


    const loadData = useCallback(async () => {
        const res = await getTableServices(restaurantId);
        setRange(res.range || []);
        setSingles(res.singles || []);
    }, [restaurantId]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    const onClickBackHandler = () => navigate(-1)

    const onAddTable = async () => {
        const res = await setTables(restaurantId, table);
        if (res.outcome) await loadData();
    }

    const onAddSpecificTable = async () => {
        const res = await setTables(restaurantId, customTable);
        if (res.outcome) await loadData();
    }

    const onDeleleTable = async (tableId) => {
        const res = await deleteTable(restaurantId, tableId);
        if (res.outcome) {
            const data = range.filter(it => it.table_id !== tableId);
            setRange(data);
        }
    }

    const onDeleleSpecificTable = async (tableId) => {
        const res = await deleteTable(restaurantId, tableId);
        if (res.outcome) {
            const data = singles.filter(it => it.id !== tableId);
            setSingles(data);
        }
    }

    const onTableChangeHandler = ({ target }) => {
        return setTable(table => ({
            ...table,
            [target.name]: target.value,
        }));
    };

    const onCustomTableChangeHandler = ({ target }) => {
        return setCustomTable(customTable => ({
            ...customTable,
            "from": target.value,
            "to": target.value,
        }));
    };

    return (
        <div className="CustomizeLocalOrder">
            <Header isDividerExist={true} />

            <div className="px-6 py-6">
                <div className="flex cursor-pointer items-center" onClick={onClickBackHandler}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="h-4 w-4">
                        <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                    </svg>

                    <p className="text-lg font-light underline">Servizi menù digitale</p>
                </div>

                <p className="font-semibold text-xl mt-6">Opzioni Servizio al tavolo</p>

                <p className="font-light mt-6">
                    Gli orari del servizio saranno gli stessi orari d'apertura del tuo ristorante. Inserisci l'intervallo dei numeri dei tavoli nei quali sarà attivo il pagamento tramite smartphone (ad es: 1-40 oppure 10-55 …).
                </p>

                <div className="flex mt-6 gap-x-2">
                    <input name="prefix" type="text" className="input-mycia-secondary" placeholder="Prefisso" onChange={onTableChangeHandler}/>
                    <input name="from" type="number" className="input-mycia-secondary" placeholder="- g" onChange={onTableChangeHandler}/>
                    <input name="to" type="number" className="input-mycia-secondary" placeholder="- g" onChange={onTableChangeHandler}/>
                    <input name="suffix" type="text" className="input-mycia-secondary" placeholder="Suffisso" onChange={onTableChangeHandler}/>
                </div>

                <div className="button-mycia-white mt-6" onClick={onAddTable}>
                    <p className="ml-4">AGGIUNGI TAVOLO</p>
                </div>

                <div className="mt-10">
                    {range.map((item, index) => (
                        <div key={`payment_table_active_${index}`} className={`flex items-center justify-between py-2.5 border-mycia-dimGray ${index === 0 ? `border-t` : ``} border-b`}>
                            <p className="font-semibold text-xl">{item.prefix} {item.number_from}-{item.number_to}{item.suffix}</p>
                                
                            <Delete className="icon-mycia cursor-pointer" onClick={() => onDeleleTable(item.table_id)} />
                        </div>
                    ))}
                </div>

                <p className="font-light mt-10">
                    Hai dei numeri particolari di alcuni tavoli (ad esempio con delle lettere)? Segnali qua sotto, altrimenti lascia il campo vuoto.
                </p>

                <input type="text" className="input-mycia-secondary mt-6" placeholder="Nome del tavolo" onChange={onCustomTableChangeHandler}/>
            
                <div className="button-mycia-white mt-6">
                    <p className="ml-4" onClick={onAddSpecificTable}>AGGIUNGI TAVOLO</p>
                </div>

                <div className="mt-10">
                    {singles.map((item, index) => (
                        <div key={`custom_table_${index}`} className={`flex items-center justify-between py-2.5 border-mycia-dimGray ${index === 0 ? `border-t` : ``} border-b`}>
                            <p className="font-semibold text-xl">{item.number}</p>
                                
                            <Delete className="icon-mycia cursor-pointer" onClick={() => onDeleleSpecificTable(item.id)} />
                        </div>
                    ))}
                </div>
                    
                <p className="font-light mt-10">
                    <span className="font-semibold">NB:</span> ti raccomandiamo la massima precisione nel segnare il numero dei tavoli, oltre a ricordati di associarli correttamente nel locale in modo che i tuoi clienti riescano a capire quale segnalare.
                </p>
            </div>

            <Footer />
        </div>
    )
}

export default CustomizeLocalOrder;