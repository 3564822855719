import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { getWineYoutubeLink, setWineYoutubeLink } from '../../../../apis/modify-restaurant';
import { modalStyle } from '../../../../style';
import Close from '../../../Icon/Close';
import { useTranslation } from "react-i18next";

function WineYoutubeModal(props) {
    const { t } = useTranslation();
    const { children, restaurantId, wineId } = props;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [data, setData] = useState();
    const [link, setLink] = useState();
    
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onUpdateLink = async () => await setWineYoutubeLink(restaurantId, wineId, link);

    useEffect(() => {
        async function loadData() {
            const res = await getWineYoutubeLink(restaurantId, wineId);
            setData(res);
        };

        if(modalIsOpen)
            loadData();
    }, [restaurantId, wineId, modalIsOpen]);

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="Youtube Modal"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Inserisci il link ad un video YouTube</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <input type="text" className="input-mycia-secondary mt-6" value={data} onChange={({target}) => setLink(target.value)} />

                    <div className="button-mycia mt-8" onClick={onUpdateLink}>CONFERMA</div>

                    {data &&
                        <div className="mt-8">
                            <p className="text-lg font-semibold">Preview</p>

                            <iframe
                                src={data}
                                frameborder='0'
                                allow='autoplay; encrypted-media'
                                allowfullscreen
                                title='video'
                                width="100%"
                                className="mt-2"
                            />
                        </div>
                    }
                </Modal>
            )}
        </div>
    );
}

export default WineYoutubeModal;
