import React from "react";

function Hide(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.25 58" className={className} onClick={onClick}>
            <g>
                <path d="M0 29.56c0-3.68 5.84-12.13 15.75-18.62l5.44 5.44C13.35 21.29 8.53 27.56 8.53 29.56c0 4.23 14.91 20.04 34.09 20.04 3.32 0 6.63-.48 9.82-1.42l5.74 5.77a47.646 47.646 0 01-15.56 2.58C17.32 56.52 0 35.86 0 29.56zM14.27 4.37A2.527 2.527 0 0114.15.8a2.527 2.527 0 013.57-.12c.05.04.09.09.14.14l52.98 52.92c.96 1.01.92 2.61-.1 3.57-.98.93-2.52.92-3.49-.02L14.27 4.37zm12.32 25.2c-.02-2.03.42-4.04 1.27-5.89L48.45 44.3c-1.83.84-3.82 1.27-5.84 1.27-8.83-.02-15.99-7.17-16.02-16zm58.67 0c0 3.61-5.59 11.92-15.19 18.34l-5.31-5.34c7.37-4.75 11.98-10.65 11.98-13 0-3.58-14.94-20.04-34.12-20.04-3.07 0-6.12.42-9.08 1.23L27.8 5.02c4.78-1.6 9.78-2.42 14.82-2.41 25.66 0 42.64 20.59 42.64 26.95zM37.38 14.56c1.67-.66 3.45-1 5.25-.99 8.79-.06 15.97 7.02 16.02 15.82v.15c.01 1.8-.36 3.57-1.08 5.22l-20.19-20.2z"></path>
            </g>
        </svg>
    );
}

export default Hide;
