import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { addPlate, deletePlate, deleteTastingMenu, getCourses, getPlates, updateFieldTastingMenu } from '../../apis/tasting';
import { myCIASelect } from '../../style';
import Delete from '../Icon/Delete';
import { useTranslation } from "react-i18next";

function ItemMenu(props) {
    const { t } = useTranslation();
    const { className, menu, restaurantId, refreshMenu } = props;

    const [menuState, setMenuState] = useState({
        ...menu,
        mnd_name: menu.name,
        mnd_description: menu.description,
        mnd_prezzo_unico: menu.price
    });
    const [platesState, setPlatesState] = useState(menu.plates);
    const [tabVisibility, setTabVisibility] = useState(false);
    const [coursesOptions, setCoursesOptions] = useState([]);
    const [platesOptions, setPlatesOptions] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedPlate, setSelectedPlate] = useState(null);

    const mapOptions = (data) => {
        return data && data.map(it => ({
            label: it.name,
            value: it.id
        }));
    }

    useEffect(() => {
        async function loadCourses() {
            const res = await getCourses(restaurantId);
            setCoursesOptions(mapOptions(res.courses))
        }

        if(tabVisibility) loadCourses();
    }, [tabVisibility, restaurantId]);

    const onTabClickHandler = () => setTabVisibility(!tabVisibility);
    const noOptionsMessage = ({ inputValue }) => (inputValue ? "Nessuno" : "No results found");

    const onCourseSelected = async (target) => {
        const res = await getPlates(restaurantId, target.value);
        if (res.outcome) {
            setSelectedCourse(target);
            setPlatesOptions(mapOptions(res.plates));
        }
        setSelectedPlate({});
    }

    const onChangeHandler = ({target}) => {
        setMenuState(menuState => ({
            ...menuState,
            [target.name]: target.value
        }));
    }

    const onBlurHandler = async ({ target }) => await updateFieldTastingMenu(restaurantId, menuState.mnd_id, target.name, target.value);

    const onDeleteMenu = async () => {
        const res = await deleteTastingMenu(restaurantId, menuState.mnd_id);
        if (res.outcome) await refreshMenu(); 
    }

    const onDeletePlate = async (mpdId, dishId) => {
        const res = await deletePlate(restaurantId, menuState.mnd_id, dishId, mpdId);
        if (res.outcome) {
            const temp = platesState.filter(it => it.mpd_id !== mpdId);
            setPlatesState(temp);
        } 
    }

    const onAddPlate = async () => {
        const res = await addPlate(restaurantId, menuState.mnd_id, selectedPlate.value);
        if (res.outcome) {
            setPlatesState(plates => ([
                ...plates,
                {
                    id: res.pia_id,
                    mpd_id: res.mpd_id,
                    prt_nome: res.nome_portata,
                    portata_id: res.id_portata,
                    name: selectedPlate.label
                }
            ]));
        }
    }

    return (
        <div className={`ItemMenu ${className}`}>
            <div className="px-mobile select-none">
                <div className="flex items-center justify-between py-4">
                    {
                        !tabVisibility ? (<p className="text-xl font-semibold">{menuState.mnd_name}</p>) :
                            (
                                <div className="flex flex-1 items-center">
                                    <Delete className="w-4 h-4 cursor-pointer" onClick={onDeleteMenu} />

                                    <input name="mnd_name" className="input-mycia ml-4" value={menuState.mnd_name} onChange={onChangeHandler} onBlur={onBlurHandler} />
                                </div>
                            )
                    }

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        onClick={onTabClickHandler}
                        className={`flex-shrink-0 ml-4 w-6 transition-all duration-300 ${tabVisibility ? `rotate-180` : `rotate-0`} cursor-pointer`}
                    >
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                    </svg>
                </div>

                {tabVisibility && 
                    <div className="mb-mobile">
                        <div className="flex items-center justify-between mt-4">
                            <p className="text-lg font-semibold">Costo del menù completo</p>

                            <input type="number" name="mnd_prezzo_unico" className="input-mycia-secondary w-[10ch] mt-2" placeholder="-" value={menuState.mnd_prezzo_unico} onChange={onChangeHandler} onBlur={onBlurHandler} />
                        </div>

                        <p className="mt-4 inline-block font-semibold uppercase text-sm">DESCRIZIONE</p>
                        <textarea
                            id="description"
                            name="mnd_description"
                            placeholder="Descrizione del menù"
                            className="input-mycia-secondary resize-none mt-2"
                            rows="10"
                            value={menuState.mnd_description}
                            onChange={onChangeHandler}
                            onBlur={onBlurHandler}
                        /> 

                        <p className="mt-4 font-semibold text-lg">Aggiungi i piatti del menù</p>
                        <Select
                            className="mt-2"
                            styles={myCIASelect}
                            isClearable={false}
                            isSearchable={false}
                            options={coursesOptions}
                            placeholder="Nessuno"
                            noOptionsMessage={noOptionsMessage}
                            value={selectedCourse}
                            onChange={onCourseSelected}
                        />
                        <Select
                            className="mt-2"
                            styles={myCIASelect}
                            isClearable={false}
                            isSearchable={false}
                            options={platesOptions}
                            placeholder="Nessuno"
                            noOptionsMessage={noOptionsMessage}
                            value={selectedPlate}
                            onChange={(target) => setSelectedPlate(target)}
                        />

                        <button type="submit" className="button-mycia w-full mt-8" onClick={onAddPlate}>AGGIUNGI PIATTO</button>
                    </div>
                }
            </div>

            {tabVisibility && 
                <div className="mb-4">
                    {
                        platesState && platesState.map((item, key) => (
                            <div className={`px-mobile flex items-center py-3 ${key === 0 ? 'border-t-2' : ''} border-b-2 border-mycia-dimGray`}>
                                <Delete className="w-4 h-4 cursor-pointer" onClick={() => onDeletePlate(item.mpd_id, item.id)} />

                                <p className="ml-4 font-light">{item.name}</p>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default ItemMenu;
