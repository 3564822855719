import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import RecoverForm from "../components/RecoverForm/RecoverForm";

function RecoverPassword() {
    return (
        <div className="RecoverPassword">
            <Header />

            <RecoverForm />
            
            <Footer />
        </div>
    );
}

export default RecoverPassword;
