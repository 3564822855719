import React from 'react';

function MenuHeaderThird(props) {
    const { name, description } = props;
    return (
        <div className="MenuHeaderThird">
            <p className="mt-6 text-5xl text-center text-mycia-red">{name}</p>
            <p className="mx-10 mt-8 text-lg text-start font-semibold">{description}</p>
        </div>
    )
}

export default MenuHeaderThird;