import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toggleVariation, deleteVariation } from "../../../apis/modify-restaurant";
import Delete from "../../Icon/Delete";
import EyeIcon from "../../Icon/Eye";
import Hide from "../../Icon/Hide";
import { useTranslation } from "react-i18next";

function ItemAddVariation(props) {
    const { t } = useTranslation();
    const { restaurantId } = useParams();
    const { className, index, item, isDeletable, onDelete, onBlurHandler } = props;

    const [addVariation] = useState(item);
    const [nameState, setNameState] = useState(item.nome);
    const [priceState, setPriceState] = useState(item.variazione_prezzo);
    const [isVisibleState, setIsVisibleState] = useState(!!item.active);

    const onBlurHandlerInputs = async (e) => {
        const res = await onBlurHandler(index, e);
        if (!res) setPriceState("");
    }

    const onDeleteVariation = async () => {
        let res = {};
        if (addVariation.pva_id) res = await deleteVariation(restaurantId, addVariation.pva_id);
        if (!addVariation.pva_id || res.outcome) onDelete(index);
    }

    const onClickVisible = async () => {
        const res = await toggleVariation(restaurantId, item.pva_id, !isVisibleState)
        if (res.outcome) setIsVisibleState(prev => !prev);
    }

    return (
        <div className={`${className} ${!isVisibleState ? 'opacity-50' : ''} flex w-full items-center gap-x-4`}>
            {
                isDeletable && <Delete className="ml-3 h-3.5 w-3.5 cursor-pointer fill-black" onClick={onDeleteVariation} />
            }

            <input type="text" name="nome" value={nameState} onChange={({target}) => setNameState(target.value)} onBlur={onBlurHandlerInputs} placeholder="Aggiungi ingrediente" className="input-mycia flex-1 valid:bg-white" />

            <input type="number" name="variazione_prezzo" value={priceState} onChange={({ target }) => setPriceState(target.value)} onBlur={onBlurHandlerInputs} placeholder="€" className="input-mycia w-[10ch] flex-none valid:bg-white" />
            
            {
                item.pva_id && 
                (<div className="w-6 h-6 cursor-pointer" onClick={onClickVisible}>
                    {!isVisibleState ? <EyeIcon /> : <Hide />}
                </div>)
            }
        </div>
    );
}

export default ItemAddVariation;
