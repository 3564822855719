import React, { useState, useEffect } from 'react';
import { deleteDeliveryHour, deleteTakeAwayHour, setDeliveryHour, setTakeAwayHour } from '../../apis/restaurants';
import TimeSlot from './TimeSlot';

function DateSection(props) {
    const { className, label, name, timeSlots, restaurantId, type } = props;

    const [timeSlotsState, setTimeSlotsState] = useState([]);
    const [isChangeDisabled, setIsChangeDisabled] = useState(false);

    const addNewTimeSlot = () => {
        setTimeSlotsState(prev => ([
            ...prev,
            {
                id: null,
                open: null,
                close: null,
                day: name
            }
        ]));
    }

    useEffect(() => {
        setTimeSlotsState(timeSlots);
    }, [timeSlots]);

    useEffect(() => {
        setIsChangeDisabled(!timeSlotsState.every(it => it.open !== null && it.close !== null))
    }, [timeSlotsState]);
    
    const onChangeTimeSlotItem = (data, i) => {
        const temp = timeSlotsState;
        const index = timeSlotsState.findIndex((it, key) => (it.id !== null && it.id === data.id) || i === key)
        temp[index].open = data.open;
        temp[index].close = data.close;
        setIsChangeDisabled(!temp.every(it => it.open !== null && it.close !== null))
        setTimeSlotsState(temp);
    }
    
    const saveTimeSlots = async () => {
        const data = timeSlotsState;
        timeSlotsState.forEach(async (it, index) => {
            let res = {};
            if (type === "delivery") 
                res = await setDeliveryHour(restaurantId, it.open, it.close, it.day, it.id);
            else
                res = await setTakeAwayHour(restaurantId, it.open, it.close, it.day, it.id);
            if (res.outcome) data[index].id = res.id;
        });
        setTimeSlotsState(data)
    }

    const onDelete = async (index) => {
        const id = timeSlotsState[index].id;
        if (id) {
            let res = {};
            if (type === "delivery") 
                res = await deleteDeliveryHour(restaurantId, id);
            else 
                res = await deleteTakeAwayHour(restaurantId, id);
            if (!res.outcome) return;
        }
        setTimeSlotsState(prev => prev.filter((it, k) => k !== index));
    }

    return (
        <div className={`DateSection ${className}`}>
            <p className="font-semibold">{label}</p>
        
            {
                timeSlotsState.map((it, index) => (
                    <TimeSlot key={`${label}_${index}`} index={index} id={it.id} fromDate={it.open} toDate={it.close} onChangeTime={onChangeTimeSlotItem} onDelete={() => onDelete(index)} />
                ))
            }

            {
                timeSlotsState.length > 0 && <button className="button-mycia w-full disabled:bg-mycia-medium disabled:shadow-mycia-medium disabled:text-mycia-dimGray mt-6" disabled={isChangeDisabled} onClick={saveTimeSlots}>
                    <p>AGGIORNA ORARIO</p>
                </button>
            }

            {
                timeSlotsState.length < 2 && <div className="button-mycia-white mt-6" onClick={addNewTimeSlot}>
                    <p>NUOVA FASCIA ORARIA</p>
                </div>
            }
           
        </div>
    )
}

export default DateSection;