import React, { useState } from "react";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { getDishesByMenuId, updateOrderCategory, updateOrderDish } from "../../../apis/modify-restaurant";
import ItemDishMenuModify from "./ItemDishMenuModify";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DishAdd from "./Modal/DishhAddModal";
import ItemDishMenuArchieve from "./ItemDishMenuArchieve";
import { useTranslation } from "react-i18next";
import ArrowDownOutline from "../../Icon/ArrowDownOutline";
import ArrowUpOutline from "../../Icon/ArrowUpOutline";
import Pen from "../../Icon/Pen";
import DishRenameCategoryModal from "./Modal/DishRenameCategoryModal";

function ItemDishCategoryModify(props) {
    const { t } = useTranslation();
    const { restaurantId } = useParams();
    const { isArchieve, id, name, weight, onRefresh, isStart, isEnd, isDishMovable } = props;

    const [tabVisibility, setTabVisibility] = useState(false);
    const [dishList, setDishList] = useState([]);

    const loadDishMenuById = useCallback(async () => {
        const dishData = await getDishesByMenuId(restaurantId, id, +isArchieve);
        setDishList(dishData.dishes);
    }, [restaurantId, id, isArchieve]);

    const onChangeIngredients = async (dishId) => {
        const res = await getDishesByMenuId(restaurantId, id, isArchieve);
        if (res.dishes) {
            const ingredients = res.dishes.find(it => it.pia_id == dishId).ingredienti;
            const temp = dishList;
            temp[temp.findIndex(it => it.pia_id == dishId)].ingredienti = ingredients;
            setDishList([...temp]);
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);

        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result.forEach((item, index) => {
            item.pia_weight = result.length - index;
        });

        return result;
    };

    const deleteByIndex = (ind) => {
        const temp = dishList.filter((it, i) => i !== ind);
        setDishList(temp);
    };

    const onRefreshDish = (ind, dish) => {
        const tempDish = dishList;
        tempDish[ind] = dish;
        setDishList(tempDish);
    }

    function DishMenuModify({ item, index }) {
        return (
            <Draggable draggableId={item.pia_id.toString()} index={index}>
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {
                            !isArchieve ? <ItemDishMenuModify key={`item_modify_${item.pia_id}`} dish={item} index={index} dishCategoryId={id} deleteByIndex={deleteByIndex} onRefreshDish={onRefreshDish} onRefresh={loadDishMenuById} onRefreshIngredients={onChangeIngredients} />
                                : <ItemDishMenuArchieve key={`item_dish_archieve_${item.pia_id}`} dish={item} index={index} onRefresh={deleteByIndex} />
                        }
                    </div>
                )}
            </Draggable>
        );
    }

    const DishMenuModifyList = React.memo(({ dishList }) => {
        return dishList.length !== 0 ?
            dishList.map((item, index) => (<DishMenuModify item={item} index={index} key={index} />))
            : (<p className="ml-6 my-4">Nessun piatto inserito</p>)
    });

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const endWeight = dishList.length - 1 - result.destination.index;
        const startWeight = dishList.length - 1 - result.source.index;

        const tempDish = reorder(dishList, result.source.index, result.destination.index);
        setDishList(tempDish);

        await updateOrderDish(restaurantId, id, result.draggableId, startWeight, endWeight);
    }

    const onTabClickHandler = async () => {
        if (!tabVisibility) await loadDishMenuById();
        setTabVisibility(!tabVisibility);
    };

    const onSwipeCategories = async (dir) => {
        if (dir === "down" && isEnd) return;
        if (dir === "up" && isStart) return;
        const res = await updateOrderCategory(restaurantId, id, weight, dir);
        if (res.outcome) onRefresh();
    }
    
    return (
        <div className="ItemDishCategoryModify">
            <div
                className="border-mycia-dimGray px-mobile flex cursor-pointer justify-between border-t py-4"
            >
                <div className="flex items-center">
                    {isDishMovable &&
                        <div className="flex">
                            <ArrowDownOutline onClick={() => onSwipeCategories("down")} className={`w-5 h-5 ${isEnd ? 'opacity-50' : ''}`} />
                            <ArrowUpOutline onClick={() => onSwipeCategories("up")} className={`ml-2 w-5 h-5 mr-4 ${isStart ? 'opacity-50' : ''}`} />
                        </div>    
                    }
                    {
                        !isDishMovable &&
                        <DishRenameCategoryModal restaurantId={restaurantId} categoryId={id} categoryName={name} onRefresh={onRefresh} >
                            <Pen className="w-5 h-5 cursor-pointer"/>
                        </DishRenameCategoryModal>
                    }
                    <p className="ml-2 text-lg font-semibold">{name}</p>
                </div>

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    className={`w-6 transition-all duration-300 ${tabVisibility ? `rotate-180` : `rotate-0`}`}
                    onClick={onTabClickHandler}
                >
                    <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                </svg>
            </div>

            <div className={`${tabVisibility ? `visible` : `hidden`}`}>
                {!isArchieve &&
                    <DishAdd restaurantId={restaurantId} categoryId={id} onDishCreated={loadDishMenuById}>
                        <div className="button-mycia mx-mobile bg-mycia-greenCyan shadow-mycia-greenCyan my-4">
                            AGGIUNGI PIATTO
                        </div>
                    </DishAdd>
                }

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="dish">
                        {provided => (
                             <div ref={provided.innerRef} {...provided.droppableProps}>
                                <DishMenuModifyList dishList={dishList.sort((a, b) => b.pia_weight - a.pia_weight)} />
                                { provided.placeholder }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
}

export default ItemDishCategoryModify;
