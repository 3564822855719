import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import List from "../components/Businesses/List";

function Businesses() {
    return (
        <div className="Businesses">
            <Header isDividerExist={true} isTopBarEnabled={true} isJustLogoutVisible={true}/>

            <List />

            <Footer />
        </div>
    );
}

export default Businesses;
