import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getRestaurantDetail } from "../../apis/restaurants";
import { logout } from "../../apis/users";
import Close from "../Icon/Close";

function NavBar(props) {
    const { isNavBarEnabled, isJustLogoutVisible, setIsNavBarEnabled } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { restaurantId } = useParams();
    const [restaurantToken, setRestaurantToken] = useState("");

    const [data, setData] = useState([
        {
            id: "restaurants",
            label: t("top_nav.restaurant_list"),
            onClick: () => navigate("/businesses"),
            visibility: true,
        },
        {
            id: "menu",
            label: t("top_nav.dishes_and_menu"),
            onClick: () => navigate(`/restaurant/${restaurantId}/detail`),
            visibility: true,
        },
        {
            id: "orders",
            label: t("top_nav.orders_dashboard"),
            onClick: () => window.open(`https://orders.mycontactlessmenu.mycia.it/?token=${restaurantToken}`, "_blank"),
            visibility: false,
        },
        {
            id: "create_menu",
            label: t("top_nav.create_tasting_menu"),
            onClick: () => navigate(`/restaurant/${restaurantId}/menu/tasting`),
            visibility: true,
        },
    ]);

    const loadRestaurantRating = useCallback(async () => {
        const res = await getRestaurantDetail(restaurantId);
        if (!res.outcome) return;

        setRestaurantToken(res.data.ran_token_iscrizione);

        if (!res.isContactlessMenuEnabled) return;

        setData((prev) =>
            prev.map((item) => {
                if (item.id === "orders") {
                    return {
                        ...item,
                        visibility: true,
                        onClick: () => window.open(`https://orders.mycontactlessmenu.mycia.it/?token=${res.data.ran_token_iscrizione}`, "_blank"),
                    };
                } else return item;
            }),
        );
    }, [restaurantId]);

    useEffect(() => {
        if (restaurantId) loadRestaurantRating();
    }, [restaurantId, loadRestaurantRating]);

    const onLogout = async () => {
        const res = await logout();
        if (res.outcome) navigate("/");
    };

    return (
        <div className={`flex fixed flex-col justify-between w-full h-full bg-white px-mobile py-10 inset-y-0 z-[10000] transition-all duration-700 ${isNavBarEnabled ? `left-0` : `left-[-100%]`}`}>
            <div>
                <div className="flex justify-end">
                    <Close
                        className="cursor-pointer w-4 h-4 mr-2"
                        onClick={() => {
                            setIsNavBarEnabled(false);
                        }}
                    />
                </div>

                {!isJustLogoutVisible && (
                    <div className="flex flex-col mt-10 space-y-6">
                        {data
                            .filter((it) => it.visibility === true)
                            .map((item, key) => (
                                <p className="text-lg font-semibold cursor-pointer" key={key} onClick={item.onClick}>
                                    {item.label}
                                </p>
                            ))}
                    </div>
                )}
            </div>

            <p className="select-none text-2xl font-semibold text-mycia-medium cursor-pointer" onClick={onLogout}>
                Esci
            </p>
        </div>
    );
}

export default NavBar;
