import React from "react";
import { TYPE_ENUM } from "../../logic/utils";

function ItemMenu(props) {
    const { item, index } = props;

    return (
        <div
            className={`px-mobile ${index % 2 === 0 ? `bg-mycia-silver` : ``
                } flex items-center justify-between py-3`}
        >
            <div className="flex">
                {item.type === TYPE_ENUM.DISH && {/* TODO: ICON */}}

                <p className="ml-2 font-light">{item.name}</p>
            </div>

            <p className="font-light">{item.price}€</p>
        </div>
    );
}

export default ItemMenu;
