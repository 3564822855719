import React from "react";

function Print(props) {
    const { className, onClick } = props; 

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.05 72.65" className={className} onClick={onClick}>
            <g>
                <path d="M.05 51.72V22.37c-.55-5.3 3.31-10.05 8.61-10.59.69-.07 1.38-.07 2.07 0h2.76v-1.8c0-6.81 3.61-9.99 10.03-9.99h28.05c6.62 0 10.03 3.18 10.03 10v1.8h2.76c6.88 0 10.68 3.7 10.68 10.58v29.34c0 6.92-3.8 10.58-10.68 10.58h-3.28v2.73a6.6 6.6 0 01-5.51 7.54c-.68.11-1.38.1-2.06 0H21.58c-3.59.59-6.97-1.85-7.56-5.44-.11-.69-.12-1.4 0-2.1v-2.73h-3.28C3.82 62.3.05 58.64.05 51.72zm61.04-11.36v15.42h3.25c1.87.23 3.57-1.09 3.8-2.96.04-.29.03-.58 0-.87V22.18a3.437 3.437 0 00-2.93-3.87c-.29-.04-.58-.04-.86 0H10.76a3.438 3.438 0 00-3.81 3.01c-.03.28-.03.57 0 .85v29.77a3.405 3.405 0 002.93 3.83c.29.04.58.04.87 0H14V40.36a6.637 6.637 0 017.56-7.54h31.95c3.6-.58 6.98 1.88 7.56 5.48.11.68.11 1.38 0 2.06zm-40.8-28.58h34.54V9.44c.21-1.61-.93-3.07-2.53-3.28-.25-.03-.51-.03-.77 0H23.59c-1.6-.22-3.08.91-3.3 2.51-.03.25-.04.51 0 .77v2.34zm32.17 54.55c1.03.15 2-.57 2.15-1.6.03-.18.03-.35 0-.53v-23c.14-1.02-.58-1.97-1.61-2.11-.18-.02-.36-.02-.54 0H22.65c-1.02-.16-1.98.54-2.14 1.56-.03.18-.03.36 0 .54v23c-.15 1.03.57 1.99 1.61 2.14.18.03.36.03.54 0h29.81zM24.91 47.29c0-1.3 1.07-2.35 2.37-2.34H47.9c1.31 0 2.37 1.06 2.37 2.37s-1.06 2.37-2.37 2.37H27.32c-1.31.03-2.39-1-2.42-2.31v-.1zm0 10.84c0-1.33 1.07-2.4 2.4-2.4H47.9c1.31 0 2.37 1.06 2.37 2.37v.03a2.32 2.32 0 01-2.29 2.34H27.32c-1.29.05-2.37-.95-2.42-2.24v-.1zm31.93-36.86c2.39 0 4.33 1.94 4.33 4.34 0 2.39-1.94 4.33-4.33 4.33a4.328 4.328 0 01-4.38-4.29v-.06a4.399 4.399 0 014.38-4.32z"></path>
            </g>
        </svg>
    );
}

export default Print;
