import React, { useState } from 'react';
import Modal from 'react-modal';
import { addWineCategory } from '../../../../apis/modify-restaurant';
import { modalStyle } from '../../../../style';
import Close from '../../../Icon/Close';
import { useTranslation } from "react-i18next";

function WineAddCategoryModal(props) {
    const { t } = useTranslation();
    const { children, restaurantId, onRefresh } = props;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [name, setName] = useState();

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onCreateCategory = async () => {
        const res = await addWineCategory(restaurantId, name);
        if (res.status === "ok") {
            closeModal();
            onRefresh();
        }
    }

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="Nuova Categoria Modal"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Aggiungi categoria</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <input type="text" className="input-mycia-secondary mt-6" placeholder='Nuovo nome' onChange={({target}) => setName(target.value)} />

                    <p className="mt-6">La nuova categoria verrà inserita sara inserita in fondo all'elenco; l'ordinamento potrà essere poi modificato con le apposite frecce</p>
                    <div className="button-mycia mt-8" onClick={onCreateCategory}>CONFERMA</div>
                </Modal>
            )}
        </div>
    );
}

export default WineAddCategoryModal;
