import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../logic/AuthContext";
import { useTranslation } from "react-i18next";

function LoginForm() {
    const { t } = useTranslation();
    const { login } = useAuth();
    const navigate = useNavigate();

    const onClickCreateProfile = () => {
        navigate("./registration/account");
    }

    const onSubmitHandler = async (evt) => {
        evt.preventDefault();

        const status = await login(evt.target.elements["email"].value, evt.target.elements["password"].value);
        if (status) navigate("/businesses");
        else alert("Il nome utente o la password sono errati.");
    };

    return (
        <div className="px-mobile">
            <form method="POST" onSubmit={onSubmitHandler} className="LoginForm">
                <fieldset className="mb-6">
                    <legend className="mb-4 font-semibold text-2xl">Area privata ristoratori</legend>

                    <div className="mb-4">
                        <input type="email" name="email" id="email" className="input-mycia" placeholder="Email" required />
                    </div>

                    <div className="mb-4">
                        <input type="password" name="password" id="password" className="input-mycia" placeholder="Password" required />
                    </div>

                <p className="cursor-pointer text-sm underline"><Link to="./forgot-password">Password dimenticata?</Link></p>
            </fieldset>

                <button type="sumbit" className="button-mycia w-full">ACCEDI</button>

                <p className="mt-6 font-semibold text-center text-sm" onClick={onClickCreateProfile}>
                    Non hai un account? <span className="cursor-pointer underline">Registra la tua attività ora</span>
                </p>
            </form>
        </div>
    );
}

export default LoginForm;
