import React from "react";
import Settings from "../../Icon/Settings";

function ItemLocal(props) {
    const { item, onClickCustomize, onChange } = props;

    const onChangeHandler = async () => await onChange(item.todo, item.state);    

    return (
        <div className="ItemLocal rounded-mobile border-2 border-mycia-dimGray">
            <div className="flex">
                <div className="flex px-4 w-[calc(66.6%-1px)] items-center justify-center border-r-2 border-mycia-dimGray py-3">
                    <div className="flex-shrink-0">
                        {item.icon}
                    </div>

                    <p className="ml-4 text-center font-semibold">{item.label}</p>
                </div>

                <div className="relative flex w-1/3 justify-center py-3">
                    <label htmlFor={`default-toggle-${item.id}`} className="relative inline-flex cursor-pointer items-center">
                        <span className="text-center font-semibold">Stato</span>
                        <input type="checkbox" id={`default-toggle-${item.id}`} className="peer sr-only" checked={item.state} onChange={onChangeHandler} />
                        <div className="peer toggle-mycia"></div>
                    </label>
                </div>
            </div>

            <hr className="border-t-2 border-mycia-dimGray" />

            <div className="flex items-center justify-center py-3 cursor-pointer" onClick={onClickCustomize}>
                <Settings className="h-4 w-4" />

                <p className="ml-3">Personalizza</p>
            </div>
        </div>
    );
}

export default ItemLocal;
