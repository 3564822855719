import React from 'react';

function TastingMenuFirst(props) {
    const { name, plates, price } = props;
    return (
        <div>
            <p className="text-2xl text-center text-mycia-red font-firstStyle italic font-black capitalize">{name}</p>
            
            {
                plates && plates.map((item, index) => (
                    <div key={index} className="mt-2">
                        <p className="text-xl text-center font-firstStyle italic capitalize">{item.pia_nome}</p>
                        <p className="text-xs text-center font-firstStyle uppercase">{item.ingredients && item.ingredients.map(it => it.name).join(", ")}</p>
                    </div>
                ))
            }

            <p className="mt-4 text-lg text-center font-firstStyle capitalize">{price}€</p>
        </div>
    )
}

export default TastingMenuFirst;