import { useEffect } from "react";
import AppRoutes from "./components/Routes";
import { AuthProvider } from "./logic/AuthContext";

function App() {
    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobileOrTablet = /mobile|tablet/.test(userAgent);

        if (!isMobileOrTablet) {
          // If it's not a mobile or tablet, redirect to another website
          window.location.href = "https://ristoratori.mycia.it";
        }
    }, []);

    return (
        <div className="App font-sans">
            <AuthProvider>
                <AppRoutes />
            </AuthProvider>
        </div>
    );
}

export default App;
