import React from "react";

function Close(props) {
    const { className, onClick } = props; 
        
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.25 60.27" className={className} onClick={onClick}>
            <g>
                <path d="M1.61 1.62c2.14-2.11 5.59-2.11 7.74 0l20.76 20.76L50.88 1.61c2.14-2.14 5.62-2.14 7.76 0s2.14 5.62 0 7.76L37.88 30.13l20.76 20.76c2.14 2.14 2.14 5.62 0 7.76s-5.62 2.14-7.76 0L30.13 37.9 9.37 58.66c-2.14 2.14-5.62 2.14-7.76 0s-2.14-5.62 0-7.76l20.76-20.76L1.61 9.38a5.51 5.51 0 010-7.76z"></path>
            </g>
        </svg>
    );
}

export default Close;
