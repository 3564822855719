import React, { useState, useEffect } from 'react';
import { saveDeliveryRate } from '../../apis/restaurants';
import Delete from '../Icon/Delete';

const initialState = {
    "id": "",
    "distance_from": "0.00",
    "distance_to": "0.00",
    "cost_increase": "0.00",
    "medium_time": "0.00",
    "min_amount": "0.00",
}

function ItemRate(props) {
    const { className, restaurantId, index, data, onDelete } = props;
    
    const [rateData, setRateData] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState();
     
    useEffect(() => {
        setRateData(data);
        setIsDisabled(data && data.id);
    }, [data]);

    const onChangeHandle = ({target}) => {
        setRateData(prev => ({
            ...prev,
            [target.name]: target.value
        }));
    }

    const saveRate = async () => {
        const res = await saveDeliveryRate(
            restaurantId,
            rateData.distance_from,
            rateData.distance_to,
            rateData.cost_increase,
            rateData.medium_time,
            rateData.min_amount
        );

        if (res.outcome) {
            setIsDisabled(true);
            setRateData(prev => ({
                ...prev,
                "id": res.id
            }))
        }
    }

    return (
        <div className={`ItemRate ${className}`}>
            <div className="flex items-center ">
                <div className="flex-1 bg-mycia-whiteSmoke rounded-xl px-4 py-6">
                    <p className="font-semibold">Raggio di consegna</p>
                    <div className="flex justify-between items-center mt-2">
                        <input name="distance_from" type="number" placeholder="- km" value={rateData.distance_from} onChange={onChangeHandle} disabled={isDisabled} className="input-mycia valid:bg-white"/>
                        <p className="mx-4">a</p>
                        <input name="distance_to" type="number" placeholder="- km" value={rateData.distance_to} onChange={onChangeHandle} disabled={isDisabled} className="input-mycia valid:bg-white"/>
                    </div>

                    <div className="flex justify-between items-center mt-4">
                        <p className="font-semibold">Costo aggiuntivo del servizio</p>
                        <input name="cost_increase" type="number" placeholder="- €" value={rateData.cost_increase} onChange={onChangeHandle} disabled={isDisabled} className="input-mycia valid:bg-white w-[10ch]" />
                    </div>
                    
                    <div className="flex justify-between items-center mt-4">
                        <p className="font-semibold">Tempo medio di consegna</p>
                        <input name="medium_time" type="number" placeholder="- min" value={rateData.medium_time} onChange={onChangeHandle} disabled={isDisabled} className="input-mycia valid:bg-white w-[10ch]" />
                    </div>
                    
                    <div className="flex justify-between items-center mt-4">
                        <p className="font-semibold">Importo minimo per la consegna</p>
                        <input name="min_amount" type="number" placeholder="- €" value={rateData.min_amount} onChange={onChangeHandle} disabled={isDisabled} className="input-mycia valid:bg-white w-[10ch]" />
                    </div>
                </div>
                
                <Delete className="icon-mycia flex-shrink-0 mx-6 cursor-pointer fill-black" onClick={() => onDelete(index, rateData.id)} />
            </div>
                
            {
                !isDisabled && <div className="button-mycia mt-6" onClick={saveRate}>
                    <p>CONFERMA</p>
                </div>
            }
        </div>
    )
}

export default ItemRate;