import React from "react";

function Communication(props) {
    const { className, onClick } = props;
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.33 62.26" className={className} onClick={onClick}>
            <path d="M0 46.73c0-3.28 2.75-5.98 5.48-8.5 2.23-2.02 2.7-5.98 3.19-10.69.59-10.63 4.01-17.99 11.54-20.68C21.41 2.88 24.75 0 29.17 0s7.73 2.87 8.96 6.86c7.5 2.7 10.93 10.05 11.51 20.68.53 4.72.97 8.67 3.19 10.69 2.75 2.52 5.48 5.21 5.48 8.5 0 2.81-2.05 4.66-5.3 4.66H41.2c-.23 5.62-4.95 10.87-12.04 10.87S17.27 56.9 17.12 51.39H5.33C2.05 51.39 0 49.54 0 46.73zm49.98-1.58v-.32c-.97-.82-2.49-2.26-3.87-3.87-1.9-2.29-2.81-6.97-3.16-12.39-.44-10.96-3.93-14.82-8.38-16.03-.7-.18-1.11-.5-1.2-1.35-.18-2.9-1.76-4.78-4.19-4.78s-4.04 1.88-4.22 4.78c-.09.85-.5 1.17-1.2 1.35-4.45 1.2-7.94 5.07-8.38 16.03-.35 5.42-1.23 10.11-3.16 12.39-1.38 1.61-3.02 3.05-3.98 3.87v.32h41.74zm-15.21 6.24H23.55c.21 3.4 2.49 5.65 5.62 5.65s5.45-2.29 5.6-5.65z"></path>
        </svg>
    );
}

export default Communication;
