import React, { useState } from "react";

function ItemDishType(props) {
    const {
        label,
        name,
        checked,
        onClickHandler = function () {}
    } = props;

    const [isChecked, setIsChecked] = useState(checked);

    return (
        <div
            className={`w-[calc(50%-1rem)] p-2.5 sm:w-[calc(33.3%-1rem)] md:w-[calc(20%-1.2rem)] lg:max-w-[12.5rem] ${isChecked ? `select-mycia-shadow` : `select-mycia-shadow-none opacity-40`
                } cursor-pointer select-none`}
            onClick={async (e) => {
                const result = onClickHandler(name, !isChecked);
                if(result) setIsChecked(!isChecked);
            }}
        >
            <p className="m-auto text-center">{label}</p>
        </div>
    );
}

export default ItemDishType;
