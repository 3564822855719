import React from "react";
import LoginForm from "../components/LoginForm/LoginForm";
import BenefitsPartnership from "../components/LoginForm/BenefitsPartnership";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

function Homepage() {
    return (
        <div className="Homepage">
            <Header />

            <div className="py-16 bg-mycia-gray">
                <LoginForm />

                <BenefitsPartnership className="mt-16" />
            </div>

            <Footer />
        </div>
    );
}

export default Homepage;
