import React, { useCallback, useEffect, useState } from "react";
import ItemReview from "../Review/ItemReview";
import Pagination from "../Pagination/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import { getRestaurantDetail, getReviews } from "../../apis/restaurants";
import { useTranslation } from "react-i18next";

function Reviews() {
    const { restaurantId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [currentPage, setCurrentPage] = useState(1);
    const [reviews, setReviews] = useState([])
    const [rating, setRating] = useState({})

    const loadRestaurantReviews = useCallback(async (pageId = 1) => {
        const res = await getReviews(restaurantId);
        // if(res.status === "success") setReviews(res);
    }, [restaurantId])

    const loadRestaurantRating = useCallback(async (pageId = 1) => {
        const res = await getRestaurantDetail(restaurantId);
        if(res.outcome) setRating(res.valutazione);
    }, [restaurantId])

    useEffect(() => {
        loadRestaurantReviews();
        loadRestaurantRating();
    }, [loadRestaurantReviews, loadRestaurantRating]);

    const onClickBackHandler = () => {
        navigate(-1);
    }

    return (
        <div className="p-mobile">
            <div className="flex cursor-pointer items-center" onClick={onClickBackHandler}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="h-4 w-4">
                    <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                </svg>

                <p className="text-lg font-light underline">Scheda ristorante</p>
            </div>

            <p className="mt-4 text-xl font-semibold">Recensioni</p>
            <p className="mt-4 font-semibold uppercase">VALUTAZIONE MEDIA: {rating.media || 0} / 10</p>

            <div className="mt-6 flex flex-col gap-y-4">
                {reviews ? reviews.map((item, key) => (
                    <ItemReview
                        name="Alessandro"
                        date="23.01.2021"
                        title="Cena con amici ottima"
                        description="location perfetta, cena molto deliziosa, abbiamo preso tutti tartare diverse, ostiche e crostini deliziosi. Merita nonostante il prezzo sia un po alto."
                        rating={key}
                        key={key}
                    />
                )) : <p className="font-semibold text-lg">Nessuna recensione inserita</p>}
            </div>

            <Pagination
                fromPage={1}
                toPage={10}
                selectedPage={currentPage}
                onPageChanged={setCurrentPage}
                className="mt-8 hidden"
            />
        </div>
    );
}

export default Reviews;
