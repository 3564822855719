import React, { useState } from 'react';
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async';
import { getIngredientsByName, updateContaminatingIngredient } from '../../../../apis/modify-restaurant';
import { modalStyle, myCIASelect } from '../../../../style';
import Close from '../../../Icon/Close';
import ItemIngredient from '../ItemIngredient';
import { useTranslation } from "react-i18next";

function DishContaminantingModal(props) {
    const { t } = useTranslation();
    const { children, restaurantId, dishId, ingredients, onUpdate } = props;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [suggestedIngredient, setSuggestedIngredient] = useState();
    const [data, setData] = useState(ingredients);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onDelete = async (key) => {
        const temp = data.filter((it, index) => index !== key);
        setData(temp);
    }
    
    function mapSuggestedIngredients(data) {
        return data && data.map(it => ({
            label: it.text,
            value: it.id
        }));
    }

    const onChangeSuggestedIngredient = async (target) => {
        setData(data => ([
            ...data,
            {
                id: target.value,
                nome: target.label
            }
        ]));
        setSuggestedIngredient();
    }

    const promiseIngredientOptions = (inputValue) => {
        return new Promise(async (resolve) => {
            if (inputValue.length > 1) {
                const data = await getIngredientsByName(restaurantId, inputValue.trim());
                resolve(mapSuggestedIngredients(data.response));
            } else {
                resolve([]);
            }
        });
    }

    const onChangeIngredients = async () => {
        const payload = data.map(it => it.id);
        const res = await updateContaminatingIngredient(restaurantId, dishId, payload);
        if (res.outcome) {
            onUpdate(data)
            closeModal();
        }
    }

    const noOptionsMessage = ({ inputValue }) => (inputValue.length > 1 ? "Nessun risultato trovato" : `Per favore inserisci ${2 - inputValue.length} o più caratteri`);

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="Example Modal"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Ingredienti contaminanti</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <AsyncSelect
                        name="alimento_id"
                        cacheOptions
                        className="flex-1 mt-4"
                        styles={myCIASelect}
                        isClearable={false}
                        isSearchable={true}
                        placeholder="Scrivi un ingrediente"
                        value={suggestedIngredient}
                        onChange={onChangeSuggestedIngredient}
                        loadOptions={promiseIngredientOptions}
                        noOptionsMessage={noOptionsMessage}
                    />

                    <div className="mt-6 flex flex-wrap gap-x-4 gap-y-6">
                        {data && data.map((item, key) => (
                            <ItemIngredient key={key} dishId={dishId} id={item.id} name={item.nome} isEditable={item.composto} onDelete={() => onDelete(key)} />
                        ))}
                    </div>

                    <div className="button-mycia flex-1 mt-6" onClick={onChangeIngredients}>CONFERMA</div>
                </Modal>
            )}
        </div>
    );
}

export default DishContaminantingModal;
