import React from 'react';

function CreateRestaurantPages(props) {
    const { className, selectedPage } = props;

    return (
        <div className={`CreateRestaurantPages flex ${className}`}>
            <p className={`rounded-3xl ${selectedPage === 0 ? `bg-mycia-red w-2/5` : `bg-mycia-medium w-1/5`} py-3 text-center my-auto text-white text-lg font-semibold`}>{`1. ${selectedPage === 0 ? `Ristorante` : ``}`}</p>
            <hr className="w-[calc(10%-1rem)] mx-2 my-auto border-t border-mycia-dimGray" />
            <p className={`rounded-3xl ${selectedPage === 1 ? `bg-mycia-red w-2/5` : `bg-mycia-medium w-1/5`} py-3 text-center my-auto text-white text-lg font-semibold`}>{`2. ${selectedPage === 1 ? `Dettagli` : ``}`}</p>
            <hr className="w-[calc(10%-1rem)] mx-2 my-auto border-t border-mycia-dimGray" />
            <p className={`rounded-3xl ${selectedPage === 2 ? `bg-mycia-red w-2/5` : `bg-mycia-medium w-1/5`} py-3 text-center my-auto text-white text-lg font-semibold`}>{`3. ${selectedPage === 2 ? `Orario` : ``}`}</p>
        </div>
    )
}

export default CreateRestaurantPages;
