import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function NavBack(props) {
    const { label } = props;
    const navigate = useNavigate();
    const onClickBackHandler = () => navigate(-1)

    return (
        <div className="NavBack py-6 flex items-center cursor-pointer" onClick={onClickBackHandler}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="h-5 w-5">
                <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
            </svg>

            <p className="ml-2 text-lg font-light underline">{label}</p>
        </div>
    )
}

export default NavBack;