import React, { useState, useEffect } from "react";
import ItemService from "../Services/ItemService";
import CreateRestaurantPages from "../CreateRestaurantPages";
import forms from "../../../logic/forms";
import { getServicesList, uploadPhoto } from "../../../apis/restaurants"
import { buildPublicImageURL } from "../../../logic/utils";
import { useTranslation } from "react-i18next";

const detailInitialState = {
    "LinkSocial[0]": "",
    "LinkSocial[1]": "",
    "Descrizione": "",
    "img": "",
}

function DetailForm(props) {
    const { t } = useTranslation();
    const { onFormCompleted, onSubmitHandler } = props;
    
    const [detailData, setDetailData] = useState(detailInitialState);
    const [servicesList, setServicesList] = useState([]);
    const [errors, setErrors] = useState({});
    const [restaurantPicture, setRestaurantPicture] = useState(null)

    // TODO: DEPENDS ON BACKEND
    const validators = {
        "LinkSocial[0]": forms.list(),
        "LinkSocial[1]": forms.list(),
        "Descrizione": forms.list(),
        "CodiceUtente": forms.list(),
    };

    useEffect(() => {
        async function loadServices() {
            const response = await getServicesList();
            setServicesList(response.servizi);
        }

        loadServices();
    }, []);

    const renderModifyError = fieldName => {
        if (undefined !== errors[fieldName]) {
            return (
                <p className="mt-2 text-sm text-mycia-red">{errors[fieldName]}</p>
            );
        }
    };

    const onBlurHandler = ({ target }) => {
        const result = forms.validateMany(validators, { [target.name]: target.value });
        setErrors({ ...errors, ...result });
    };

    const onChangeHandler = ({ target }) => {
        return setDetailData(detailData => ({
            ...detailData,
            [target.name]: target.value,
        }));
    };

    const onSelectHandler = async (id, selected) => {
        let tempServList = servicesList;
        tempServList[tempServList.findIndex(it => it.ser_id === id)].selected = selected ? 1 : 0;
        setServicesList(tempServList);
    };

    const onImageUpload = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const response = await uploadPhoto(event.target.files[0]);
            setRestaurantPicture(URL.createObjectURL(event.target.files[0]));
            return setDetailData(detailData => ({
                ...detailData,
                [event.target.name]: buildPublicImageURL(response[0]),
            }));
        }
    }

    const onSubmit = (evt) => {
        evt.preventDefault();
        
        let servFormData = {
            "ServiziRistorante[1]": "",
            "servizi": servicesList.filter(it => it.selected === 1).map(it => (`${it.ser_id},${it.nome}`)).join(","),
        }; 

        servicesList.filter(it => it.selected === 1).forEach((it, index) => {
            servFormData[`ServiziRistorante[${index + 1}]`] = it.ser_id;
        });

        const payload = {
            ...detailData,
            ...servFormData
        }

        let errors = forms.validateMany(validators, payload);
        if (forms.anyErrors(errors)) {
            setErrors(errors);
        } else {
            onFormCompleted(payload);
            onSubmitHandler();
        }
    }

    return (
        <form method="POST" action="" onSubmit={onSubmit}>
            <fieldset>
                <legend className="font-light mt-4 inline-block">
                    <span className="font-semibold">Creazione profilo ristorante</span> <br /> <br />
                    Compila i campi sottostanti con i dati del tuo ristorante per crearne il profilo su MyCIA. Ricordati di tornare su questa pagina dopo la sua creazione per aggiungere i servizi disponibili nel tuo locale.
                </legend>

                <CreateRestaurantPages selectedPage={1} className="mt-10" />

                {restaurantPicture && <img src={restaurantPicture} className="mt-10 rounded-mobile" alt="preview" /> }
                <input id="file_upload" name="img" type="file" className="hidden" onChange={onImageUpload} />
                <label htmlFor="file_upload">
                    <div className="button-mycia shadow-mycia bg-mycia-medium mt-10">
                        <p className="text-center">CARICA UNA FOTO PROFILO</p>
                    </div>
                </label>

                <p className="text-center mt-6 font-light">Peso massimo 4Mb. <br /> Preferibilmente in formato quadrato.</p>

                <label className="mt-6 inline-block text-sm">SERVIZI</label>
                <div className="mt-4 flex flex-wrap gap-y-6 gap-x-6">
                    {servicesList.map((item, key) => (
                        <ItemService
                            key={key}
                            id={item.ser_id}
                            name={item.nome}
                            photo={item.immagine}
                            selected={item.selected}
                            onSelected={onSelectHandler}
                        />
                    ))}
                </div>

                <label className="mt-4 inline-block text-sm">LINK PAGINE SOCIAL</label>
                <input id="facebook_url" name="LinkSocial[0]" className="input-mycia-secondary" type="url" placeholder="Facebok" onBlur={onBlurHandler} onChange={onChangeHandler} />
                <input id="instagram_url" name="LinkSocial[1]" className="input-mycia-secondary mt-2" type="url" placeholder="Instagram" onBlur={onBlurHandler} onChange={onChangeHandler} />

                <label className="mt-8 inline-block text-sm">DESCRIZIONE</label>
                <textarea
                    id="description"
                    name="Descrizione"
                    placeholder="Inserisci una descrizione del tuo locale"
                    className="input-mycia-secondary resize-none"
                    rows="10"
                    type="text"
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                />

                <label htmlFor="ref_code" className="mt-4 inline-block text-sm">HAI UN CODICE UTENTE DI INVITO? (OPZIONALE)</label>
                <input id="ref_code" name="CodiceUtente" className="input-mycia-secondary" type="text" placeholder="-" onBlur={onBlurHandler} onChange={onChangeHandler} />

                <button type="submit" className="button-mycia w-full mt-12">CONTINUA</button>
            </fieldset>
        </form>
    );
}

export default DetailForm;
