import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Reviews from "../components/Restaurant/Reviews";

function RestaurantReviews(props) {
    return (
        <div className="RestaurantReviews">
            <Header isDividerExist={true} />

            <Reviews />

            <Footer />
        </div>
    );
}

export default RestaurantReviews;
