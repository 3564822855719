import React from "react";

function Cam(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.14 59.08" className={className} onClick={onClick}>
            <g>
                <path d="M.07 48.77V17.05c-.61-5.05 3-9.64 8.05-10.24.75-.09 1.5-.09 2.24 0h7.82c1.78.2 3.54-.51 4.68-1.9l2.4-2.45A7.678 7.678 0 0131.52.03H43.5c2.36-.19 4.67.71 6.28 2.44l2.39 2.45a5.248 5.248 0 004.68 1.9h7.94a9.216 9.216 0 0110.29 7.99c.09.74.1 1.5 0 2.24v31.72c.6 5.05-3 9.63-8.05 10.24-.75.09-1.5.09-2.24 0H10.36A9.216 9.216 0 01.07 51.02c-.09-.74-.1-1.5 0-2.24zm64.07 3.32c1.98.26 3.79-1.14 4.05-3.12.04-.32.04-.64 0-.95V17.8a3.61 3.61 0 00-3.09-4.07c-.32-.04-.64-.04-.95 0h-9.48c-2.09.27-4.18-.52-5.58-2.1L46.8 9.18a5.687 5.687 0 00-5.19-2.23h-8.18c-2.03-.22-4.02.64-5.25 2.27l-2.23 2.4a6.371 6.371 0 01-5.58 2.1h-9.38a3.6 3.6 0 00-4.02 3.12c-.04.32-.04.65 0 .97v30.21a3.599 3.599 0 003.06 4.07c.32.04.64.05.95 0h53.15zM21.44 32.2c.03-8.91 7.27-16.11 16.18-16.08S53.73 23.39 53.7 32.3c-.03 8.88-7.23 16.07-16.12 16.08-8.89.03-16.12-7.15-16.15-16.04v-.14zm26.14 0c0-5.53-4.49-10-10.02-9.99s-10 4.49-9.99 10.02c0 5.53 4.49 10 10.02 9.99 5.52 0 9.99-4.47 9.99-9.99v-.03z"></path>
            </g>
        </svg>
    );
}

export default Cam;
