import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from "react-select";
import { getLanguagesList, getTranslationsList, updateNameTranslation } from '../../../../apis/modify-restaurant';
import { modalStyle, myCIASelect } from '../../../../style';
import Close from '../../../Icon/Close';
import { useTranslation } from "react-i18next";

function DishTranslationsModal(props) {
    const { t } = useTranslation();
    const { children, restaurantId, dishId, dishCategoryId } = props;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [translations, setTranslations] = useState({});
    const [name, setName] = useState();

    const noOptionsMessage = ({ inputValue }) => (inputValue ? "Nessuno" : "No results found");

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const mapItemLanguage = (data) => {
        return data ? {
            label: data.name.charAt(0).toUpperCase() + data.name.slice(1),
            value: data.id
        } : null
    }

    useEffect(() => {
        async function loadData() {
            let res = await getLanguagesList(restaurantId);
            if (res) {
                setSelectedOption(mapItemLanguage(res.find(it => it.lang === "en-US")))
                setOptions(res.map(item => mapItemLanguage(item)));
                setLanguages(res);
                res = await getTranslationsList(restaurantId, dishId, "", "en");
                if (res.outcome) setTranslations(res);
            }
        };

        if (modalIsOpen) loadData();
        setName();
    }, [restaurantId, dishId, dishCategoryId, modalIsOpen]);

    const onOptionSelected = async (target) => {
        setSelectedOption(target);
        const lang = languages.find(it => it.id === target.value).lang.split("-")[0];
        const res = await getTranslationsList(restaurantId, dishId, "", lang);
        if (res.outcome) setTranslations(res);
    }

    const onClickConfirm = async () => {
        if (selectedOption) {
            const lang = languages.find(it => it.id === selectedOption.value).lang.split("-")[0];
            const res = await updateNameTranslation(restaurantId, dishId, dishCategoryId, name, lang);
            if (res.outcome) closeModal();
        }
    }

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="Example Modal"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-xl font-semibold">Traduzioni</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <p className="mt-4 font-light">Controlla le traduzioni dei tuoi piatti in 60 lingue diverse e decidi se modificarle in base alle tue esigenze</p>

                    <hr className="border-t-2 border-mycia-dimGray" />

                    <p className="mt-4 text-lg font-light">Vedi la traduzione del piatto in</p>
                    <Select
                        id="type_1"
                        className="mt-4"
                        styles={myCIASelect}
                        isClearable={false}
                        isSearchable={false}
                        options={options}
                        placeholder="Nessuno"
                        noOptionsMessage={noOptionsMessage}
                        value={selectedOption}
                        onChange={(event) => onOptionSelected(event)}
                    />

                    <p className="mt-6 text-lg font-light">Traduzione MyCIA: <span className="font-semibold">{translations.nome_trandotto}</span></p>

                    <hr className="mt-2 border-t-2 border-mycia-dimGray" />

                    <p className="mt-6 font-light">Modifica traduzione in:</p>
                    <input type="text" className="input-mycia-secondary mt-2" value={name} onChange={({target}) => setName(target.value)} />

                    <div className="button-mycia flex-1 mt-10" onClick={onClickConfirm}>SALVA MODIFICHE</div>
                </Modal>
            )}
        </div>
    );
}

export default DishTranslationsModal;
