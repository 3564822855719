import React from "react";

function MyCIAInput(props) {
    const {
        id,
        type,
        name,
        shadow,
        className,
        value,
        labelClassName,
        vertical,
        isLabelMaxWidth,
        children,
        onBlur = function() {},
        onChange,
        checked
    } = props;

    return (
        <div className={`MyCIAInput flex items-center ${shadow ? (checked ? `select-mycia-shadow-none` : `select-mycia-shadow`) : ``} ${className}`}>
            <input id={id} type={type} value={value} name={name} className={`select-mycia hidden`} onChange={onChange} onBlur={onBlur} checked={checked} />

            <label
                htmlFor={id}
                className={`select-mycia-label items-center ${labelClassName} ${vertical ? `flex-col` : `flex-row`
                    } ${isLabelMaxWidth ? `w-full` : ``}`}
            >
                <div className="select-mycia-div">
                    <span className={`select-mycia-span mx-auto`}></span>
                </div>

                <div className={`${vertical ? `mx-auto mt-2` : `ml-1`} ${isLabelMaxWidth ? `w-full` : ``}`}>
                    {children}
                </div>
            </label>
        </div>
    );
}

export default MyCIAInput;
