import React from "react";

function Transparency(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.91 66.97" className={className} onClick={onClick}>
            <path d="M9.08 30.21c1.79 0 3.28 1.49 3.28 3.28s-1.49 3.28-3.28 3.28h-5.8C1.49 36.77 0 35.28 0 33.49s1.49-3.28 3.28-3.28h5.8zm4.8 18.13c1.26-1.23 3.43-1.23 4.69.06 1.23 1.23 1.2 3.37-.06 4.63l-4.13 4.1c-1.23 1.23-3.4 1.2-4.66-.06s-1.2-3.4.06-4.63l4.1-4.1zM9.79 14.47c-1.23-1.2-1.23-3.37.06-4.63 1.23-1.26 3.4-1.2 4.63.03l4.1 4.13c1.23 1.23 1.2 3.4-.06 4.66-1.26 1.23-3.4 1.23-4.63-.03l-4.1-4.16zm39.46 19.01c0 8.67-7.15 15.82-15.82 15.82s-15.79-7.15-15.79-15.82 7.15-15.82 15.79-15.82 15.82 7.15 15.82 15.82zm-25.28 0c0 5.19 4.31 9.49 9.46 9.49s9.49-4.31 9.49-9.49-4.31-9.49-9.49-9.49-9.46 4.31-9.46 9.49zM36.74 9.1c0 1.79-1.49 3.28-3.28 3.28s-3.31-1.49-3.31-3.28V3.31c0-1.79 1.52-3.31 3.31-3.31s3.28 1.52 3.28 3.31v5.8zm0 54.55c0 1.79-1.49 3.31-3.28 3.31s-3.31-1.52-3.31-3.31v-5.8c0-1.79 1.52-3.28 3.31-3.28s3.28 1.49 3.28 3.28v5.8zm15.7-53.78c1.26-1.26 3.4-1.29 4.66-.03s1.26 3.43.03 4.66l-4.1 4.13c-1.29 1.29-3.43 1.26-4.66.03s-1.29-3.4-.03-4.66l4.1-4.13zm4.69 42.57c1.26 1.26 1.26 3.4.03 4.66-1.29 1.26-3.4 1.29-4.66.06l-4.13-4.13c-1.26-1.29-1.29-3.4-.03-4.66s3.4-1.26 4.63-.03l4.16 4.1zm.7-15.67c-1.79 0-3.28-1.49-3.28-3.28s1.49-3.28 3.28-3.28h5.77c1.79 0 3.31 1.49 3.31 3.28s-1.52 3.28-3.31 3.28h-5.77z"></path>
        </svg>
    );
}

export default Transparency;
