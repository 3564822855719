import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import TastingMenu from "../components/TastingMenu/TastingMenu";

function RestaurantTastingMenu(props) {
    return (
        <div className="RestaurantTastingMenu">
            <Header isDividerExist={true} />

            <TastingMenu />

            <Footer />
        </div>
    );
}

export default RestaurantTastingMenu;
