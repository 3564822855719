import React from "react";

function Restore(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.88 65.82" className={className} onClick={onClick}>
            <path d="M9.93 0h28.45c6.47 0 9.93 3.49 9.93 9.99v13.82H41.6v-13.2c0-2.55-1.29-3.9-3.96-3.9H10.63c-2.64 0-3.93 1.35-3.93 3.9v44.6c0 2.52 1.29 3.93 3.93 3.93h27.01c2.67 0 3.96-1.41 3.96-3.93V42.12h6.71v13.71c0 6.53-3.46 9.99-9.93 9.99H9.93C3.46 65.82 0 62.36 0 55.83V9.99C0 3.49 3.46 0 9.93 0zm16.82 29.88h29.03l4.31.26-2.23-1.7-4.07-3.81c-.56-.53-.88-1.29-.88-2.05 0-1.44 1.08-2.67 2.61-2.67.76 0 1.35.29 1.9.85l9.4 9.79c.79.79 1.05 1.58 1.05 2.37s-.26 1.58-1.05 2.4l-9.4 9.79c-.56.53-1.14.82-1.9.82-1.52 0-2.61-1.23-2.61-2.7 0-.73.32-1.49.88-1.99l4.07-3.84 2.23-1.67-4.31.26H26.75c-1.61 0-3.02-1.38-3.02-3.08s1.41-3.05 3.02-3.05z"></path>
        </svg>
    );
}

export default Restore;
