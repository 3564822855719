import React from "react";

function Personalize(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.19 71.57" className={className} onClick={onClick}>
            <path d="M3.84 50.96c3.43-3.43 11.31-7.03 14.71-9.73l-5.95-5.95c-2.64-2.67-2.64-5.83-.09-8.38l3.9-3.9c1.88-1.88 4.04-2.37 6.12-1.49 2.43-3.87 2.87-6.33 5.19-16.35 1.23-5.3 7.09-6.8 10.66-3.22l23.03 23.09c3.63 3.63 3.72 7.91.18 11.46L49.7 48.36c1.32 2.26.94 4.69-1.11 6.77l-3.93 3.96c-2.52 2.55-5.77 2.52-8.35-.09l-5.98-6.01c-2.7 3.46-6.3 11.31-9.73 14.74-5.19 5.19-11.37 5.13-16.61-.15-5.27-5.24-5.3-11.43-.15-16.61zm13.97-18.57l7.12 7.12c1.32 1.29 1.17 3.05-.23 4.54-2.26 2.29-11.78 6.24-16.46 10.9-2.55 2.55-2.52 5.65.09 8.29 2.64 2.64 5.71 2.64 8.29.06 4.66-4.63 8.61-14.18 10.93-16.44 1.46-1.41 3.22-1.52 4.51-.23l7.09 7.12c.56.59 1.2.59 1.79-.03l2.17-2.17c.56-.59.56-1.23 0-1.76L21.77 28.46c-.56-.59-1.2-.56-1.76 0l-2.2 2.17c-.59.56-.59 1.2 0 1.76zM9.05 59.2c0-1.85 1.49-3.34 3.34-3.34s3.37 1.49 3.37 3.34-1.49 3.37-3.37 3.37-3.34-1.52-3.34-3.37zm33.46-26.95c2.99-2.99 5.51-6.39 6.33-10.9L35.28 7.79c-.76-.76-1.7-.44-1.99.67-2.26 8.29-2.87 12.04-6.06 17.08l18.4 18.4L56.94 32.6c1-1 1.03-2.17-.09-3.25l-2.08-2.08c-3.4 3.54-10.9 7.53-12.33 6.12-.26-.26-.29-.76.09-1.14z"></path>
        </svg>
    );
}

export default Personalize;
