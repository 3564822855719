import React, { useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import MyCIAInput from "../../Input/MyCIAInput";
import { myCIASelect } from "../../../style";
import ItemDishType from "./ItemDishType";
import ItemAddVariation from "./ItemAddVariation";
import ItemIngredient from "./ItemIngredient";
import { mapStrToNumberPrice } from "../../../logic/utils";
import { addCategoryAdditions, addVariation, archiveDish, deleteDishCategory, getDrinkByName, modifyVariation, setConsignedDrink, setVisibilityDish, toggleConsignment, updateDescriptionDish, updateDishCharacteristic, updateDishContains, updateNameDish, updatePriceDish, updateDishMealsOfDay } from "../../../apis/modify-restaurant";
import { useParams } from "react-router-dom";
import ItemAddCategory from "./ItemAddCategory";
import DishPhotoModal from "./Modal/DishPhotoModal";
import DishYoutubeModal from "./Modal/DishYoutubeModal";
import DishNutritionalModal from "./Modal/DishNutritionalModal";
import DishChangeCategoryModal from "./Modal/DishChangeCategoryModal";
import DishTranslationsModal from "./Modal/DishTranslationsModal";
import DropdownItem from "./Dropdown/DropdownItem";
import DishAddIngredient from "./Modal/DishAddIngredient";
import DishContaminantingModal from "./Modal/DishContaminantingModal";
import Pen from "../../Icon/Pen";
import Archieve from "../../Icon/Archieve";
import Global from "../../Icon/Global";
import Cam from "../../Icon/Cam";
import Youtube from "../../Icon/Youtube";
import Utentils from "../../Icon/Utentils";
import { useTranslation } from "react-i18next";
import { useDebounce } from "../../../logic/useDebounce";

function ItemDishMenuModify(props) {
    const { t } = useTranslation();
    const { restaurantId } = useParams();
    const { dish, index, dishCategoryId, deleteByIndex, onRefresh, onRefreshDish, onRefreshIngredients } = props;

    function mapSuggestedBevarage(data) {
        return data && data.map(it => ({
            label: it.text,
            value: it.id
        }));
    }

    const mapContaminants = (data) => {
        return data.ingredienti && data.ingredienti.filter(it => it.tipo === "A");
    }

    const [isModifyEnabled, setIsModifyEnabled] = useState(false);
    const [isOptionEnabled, setIsOptionEnabled] = useState(false);
    const [isMoreDetailEnabled, setIsMoreDetailEnabled] = useState(false);
    const [suggestedWine, setSuggestedWine] = useState(mapSuggestedBevarage(dish.suggested_beverages)[0] || {});
    const [dishInfo, setDishInfo] = useState({
        ...dish,
        'pia_prezzo': mapStrToNumberPrice(dish.pia_prezzo)
    });
    const [dishName, setDishName] = useState(dish.pia_nome);
    const [dishDescription, setDishDescription] = useState(dish.pia_descrizione);
    const [dishPrice, setDishPrice] = useState(dish.pia_prezzo);
    const [dishServedAt, setDishServedAt] = useState(dish.pia_piatto_per);
    const [dishAdvised, setDishAdvised] = useState(dish.pia_special != null);
    const [contaminants, setContaminants] = useState((mapContaminants(dish) || []).length !== 0);

    useEffect(() => {
        onRefreshDish(index, dishInfo);
    }, [onRefreshDish, dishInfo, index]);

    const debouncedUpdateName = useDebounce(async () => await updateNameDish(restaurantId, dishInfo.pia_id, dishName));
    const debouncedUpdatePrice = useDebounce(async () => await updatePriceDish(restaurantId, dishInfo.pia_id, dishPrice));
    const debouncedUpdateDescription = useDebounce(async () => await updateDescriptionDish(restaurantId, dishInfo.pia_id, dishDescription));
    const debouncedUpdateService = useDebounce(async () => await updateDishMealsOfDay(restaurantId, dishInfo.pia_id, dishServedAt));

    const onChangeHandler = ({ target }) => setDishInfo(dishInfo => ({
        ...dishInfo,
        [target.name]: target.value
    }));

    const onChangeName = (e) => {
        onChangeHandler(e);
        setDishName(e.target.value);
        debouncedUpdateName();
    }

    const onChangePrice = (e) => {
        onChangeHandler(e);
        setDishPrice(e.target.value);
        debouncedUpdatePrice();
    }

    const onChangeDescription = (e) => {
        onChangeHandler(e);
        setDishDescription(e.target.value);
        debouncedUpdateDescription();
    }

    const onChangeServedAt = (e) => {
        onChangeHandler(e);
        setDishServedAt(e.target.value);
        debouncedUpdateService();
    }

    const onChangeHandlerAdvised = async () => {
        const res = await toggleConsignment(restaurantId, dishInfo.pia_id, dishAdvised);
        if (res.pia_id) setDishAdvised(prev => !prev);
    }

    const onClickHandlerDishIs = async (name, isChecked) => {
        const res = await updateDishCharacteristic(restaurantId, dishInfo.pia_id, name, isChecked);
        return res.outcome;
    }

    const onClickHandlerDishContains = async (name, isChecked) => {
        const res = await updateDishContains(restaurantId, dishInfo.pia_id, name, isChecked);
        return res.outcome;
    }

    const onBlurHandlerName = async ({ target }) => await updateNameDish(restaurantId, dishInfo.pia_id, target.value);
    const onBlurHandlerPrice = async ({ target }) => await updatePriceDish(restaurantId, dishInfo.pia_id, target.value);
    const onBlurHandlerDescription = async ({ target }) => await updateDescriptionDish(restaurantId, dishInfo.pia_id, target.value);

    const promiseDrinkOptions = (inputValue) => {
        return new Promise(async (resolve) => {
            if (inputValue.length > 1) {
                const response = await getDrinkByName(restaurantId, inputValue.trim());
                resolve(mapSuggestedBevarage(response.results));
            } else {
                resolve([]);
            }
        });
    }

    const noOptionsMessage = ({ inputValue }) => (inputValue.length > 1 ? "Nessun risultato trovato" : `Per favore inserisci ${2 - inputValue.length} o più caratteri`);

    const onDelete = (id) => {
        const tempIngredients = dishInfo.ingredienti.filter(it => it.id !== id);
        setDishInfo(dishInfo => ({
            ...dishInfo,
            "ingredienti": tempIngredients
        }));
    }

    const onClickAddVariation = () => {
        setDishInfo(dishInfo => ({
            ...dishInfo,
            "variazioni": [
                ...dishInfo.variazioni,
                {
                    "pva_id": null,
                    "variazione_alimento_id": null,
                    "nome": null,
                    "variazione_prezzo": "",
                    "variazione_type": 0,
                    "active": 1,
                    "pos_id": null,
                    "scorte": null
                }
            ]
        }));
    }

    const onBlurHandlerVariation = async (key, e) => {
        const tempVariations = dishInfo.variazioni;
        const tempVariation = tempVariations[key];

        if (!tempVariation.pva_id) { // New
            if (e.target.name === "variazione_prezzo") {
                alert("Inserisci il nome della variazione prima del prezzo");
                return false;
            } else {
                const res = await addVariation(restaurantId, dishInfo.pia_id, e.target.value);
                tempVariations[key].nome = e.target.value;
                tempVariations[key].pva_id = res.pva_id;
                tempVariations[key].active = 1;
            }
        } else { // Update
            let name = "", value = "";
            if (e.target.name === "variazione_prezzo") {
                value = e.target.value;
                name = tempVariation.nome;
            } else {
                value = tempVariation.variazione_prezzo;
                name = e.target.value;
            }
            const res = await modifyVariation(restaurantId, dishInfo.pia_id, tempVariation.pva_id, name, value);
            if (res.outcome) {
                tempVariations[key].nome = name;
                tempVariations[key].variazione_prezzo = value;
            }
        }
        setDishInfo(dishInfo => ({
            ...dishInfo,
            "variazioni": tempVariations
        }));
        return true;
    }

    const onClickAddCategoryAdditions = async () => {
        const responseAdd = await addCategoryAdditions(restaurantId, dishInfo.pia_id);
        if (responseAdd && responseAdd.category) {
            setDishInfo(dishInfo => ({
                ...dishInfo,
                "categorie_aggiunte": [
                    ...dishInfo.categorie_aggiunte,
                    responseAdd.category
                ]
            }));
        } 
    }

    const onDeleteCategory = async (id) => {
        const responseDelete = await deleteDishCategory(restaurantId, dishInfo.pia_id, id);
        if (responseDelete.outcome) {
            setDishInfo(dishInfo => ({
                ...dishInfo,
                "categorie_aggiunte": dishInfo.categorie_aggiunte.filter(it => it.id != id)
            }))
        }
    }

    const onDrinkSelected = async (target) => {
        setSuggestedWine(target);
        setDishInfo(dishInfo => ({
            ...dishInfo,
            suggested_beverages: [{
                id: target.value,
                text: target.label
            }]
        }));
        await setConsignedDrink(restaurantId, dishInfo.pia_id, target.value);
    }

    const onDeleteVariation = (index) => {
        const tempVariations = dishInfo.variazioni.filter((it, key) => key !== index);
        setDishInfo(dishInfo => ({
            ...dishInfo,
            "variazioni": tempVariations
        }));
    }

    const onArchiveDish = async () => {
        const res = await setVisibilityDish(restaurantId, dishInfo.pia_id);
        if (res.outcome) {
            await archiveDish(restaurantId, dishCategoryId);
            deleteByIndex(index);
        }
    }

    const updateContaminants = (ingredients) => {
        const temp = dishInfo.ingredienti.filter(it => it.tipo !== "A");
        const tempIng = [
            ...ingredients,
            ...temp
        ]
        setDishInfo(prev => ({
            ...prev,
            "ingredienti": tempIng
        }))
    }

    const isContaminantExist = (ingredients) => {
        const temp = ingredients || dishInfo.ingredienti;
        setContaminants(!!temp)
    }

    return (
        <div className="ItemDishMenuModify">
            <div className="flex items-center justify-between border-b border-mycia-dimGray px-mobile py-4">
                <p className="font-light">{dishInfo.pia_nome}</p>

                {isModifyEnabled ? (
                    <p
                        className="cursor-pointer text-lg font-semibold text-mycia-orangeRed underline"
                        onClick={() => setIsModifyEnabled(!isModifyEnabled)}
                    >
                        Chiudi
                    </p>
                ) : (
                    <div className="flex items-center">
                        <p className="font-light">{mapStrToNumberPrice(dishInfo.pia_prezzo)}€</p>

                        <svg
                            id="Livello_2"
                            className="icon-mycia ml-3 cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 23.41 24"
                            onClick={() => setIsModifyEnabled(!isModifyEnabled)}
                        >
                            <g id="Livello_1-2">
                                <path id="Tracciato_399" d="M0,22.95c0-.57,.46-1.04,1.03-1.05H22.36c.58,0,1.04,.48,1.04,1.05,0,.57-.47,1.04-1.04,1.05H1.03C.46,23.98,0,23.52,0,22.95Zm1.14-3.77l1.64-1.7c-.41-.47-.49-1.13-.21-1.68l1.56-3.66c.1-.24,.24-.45,.42-.63L15.33,.8c.82-.96,2.27-1.08,3.23-.26,.1,.08,.19,.18,.27,.28l2.46,2.46c.97,.82,1.09,2.27,.27,3.24-.08,.1-.17,.19-.27,.27l-10.77,10.71c-.17,.19-.38,.33-.62,.42l-3.67,1.54c-.55,.28-1.22,.19-1.68-.22l-.74,.58c-.2,.18-.46,.28-.73,.25H1.53c-.28,.05-.54-.14-.58-.42-.03-.18,.04-.37,.19-.48Zm6.98-2.77l-2.46-2.48-.93,2.15c-.09,.17-.06,.38,.09,.5l.65,.64c.12,.15,.33,.2,.5,.1l2.16-.91Zm1.6-1.05l7.96-7.91-3-3.02-7.97,7.92,3.01,3.01ZM15.99,3.11l3,3.01,.65-.65c.23-.17,.28-.5,.11-.73-.02-.03-.05-.06-.09-.09l-2.19-2.21c-.19-.22-.53-.25-.75-.06-.03,.02-.06,.05-.08,.08l-.65,.65Z" />
                            </g>
                        </svg>
                    </div>
                )}
            </div>

            <div className={`${!isModifyEnabled && `hidden`} pb-8`}>
                <div className="px-mobile py-6">
                    <div className="button-mycia-white relative" onFocus={() => setIsOptionEnabled(false)}>
                        <div
                            className="flex cursor-pointer select-none items-center justify-between"
                            onClick={() => setIsOptionEnabled(!isOptionEnabled)}
                        >
                            <p className="font-semibold ml-2">OPZIONI</p>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                                className={`h-6 w-6 transition-all duration-300 ${isOptionEnabled ? `rotate-180` : `rotate-0`
                                    }`}
                            >
                                <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                            </svg>
                        </div>

                        <div
                            className={`z-30 absolute left-0 mt-6 flex w-full flex-col gap-y-5 rounded-lg py-4 px-6 shadow-mycia transition-all duration-150 ${isOptionEnabled ? `` : `hidden`} bg-white`}
                        >
                            <DishChangeCategoryModal restaurantId={restaurantId} dishId={dishInfo.pia_id} onRefresh={onRefresh}>
                                <DropdownItem label="Cambia categoria" icon={<Pen className="w-6 h-6" />} />
                            </DishChangeCategoryModal>
                            <DropdownItem label="Archivia" icon={<Archieve className="w-6 h-6" />} onClick={onArchiveDish} />
                            <DishTranslationsModal restaurantId={restaurantId} dishId={dishInfo.pia_id} dishCategoryId={dishCategoryId}>
                                <DropdownItem label="Traduzioni" icon={<Global className="w-6 h-6" />} />
                            </DishTranslationsModal>
                            <DishPhotoModal restaurantId={restaurantId} dishId={dishInfo.pia_id}>
                                <DropdownItem label="Aggiungi foto piatto" icon={<Cam className="w-6 h-6"/>} />
                            </DishPhotoModal>
                            <DishYoutubeModal restaurantId={restaurantId} dishId={dishInfo.pia_id} >
                                <DropdownItem label="Aggiungi link YouTube" icon={<Youtube className="w-6 h-6" />} />
                            </DishYoutubeModal>
                            <DishNutritionalModal restaurantId={restaurantId} dishId={dishInfo.pia_id} >
                                <DropdownItem label="Valori nutrizionali" icon={<Utentils className="w-6 h-6" />} />
                            </DishNutritionalModal>
                        </div>
                    </div>

                    <p className="mt-6 text-sm font-semibold uppercase">NOME PIATTO</p>
                    <input type="text" name="pia_nome" value={dishInfo.pia_nome} onChange={onChangeName} onBlur={onBlurHandlerName} className="input-mycia-secondary mt-2" />

                    <div className="mt-6 flex items-center">
                        <p className="text-lg font-semibold">Prezzo</p>
                        <input type="number" name="pia_prezzo" step=".01" placeholder="€" value={dishInfo.pia_prezzo || ""} onChange={onChangePrice} onBlur={onBlurHandlerPrice} className="input-mycia-secondary ml-4 w-[10ch]" />
                    </div>

                    <p className="mt-6 text-sm font-semibold uppercase">INGREDIENTI</p>
                    <DishAddIngredient restaurantId={restaurantId} dishId={dishInfo.pia_id} ingredients={dishInfo.ingredienti} onRefreshIngredients={onRefreshIngredients} >
                        <div className="button-mycia-white mt-4 flex items-center justify-center">
                            <p>AGGIUNGI INGREDIENTE</p>

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="icon-mycia ml-4">
                                <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
                            </svg>
                        </div>
                    </DishAddIngredient>

                    <div className="mt-6 flex flex-wrap gap-x-4 gap-y-6">
                        {dishInfo.ingredienti.map((item, key) => (
                            <ItemIngredient key={key} dishId={dishInfo.pia_id} id={item.id} name={item.nome} isEditable={item.composto} onDelete={onDelete} />
                        ))}
                    </div>

                    <p className="mt-8 text-sm font-semibold uppercase">DESCRIZIONE PIATTO</p>
                    <input type="text" name="pia_descrizione" placeholder="Descrivi il piatto" value={dishInfo.pia_descrizione || ""} onChange={onChangeDescription} onBlur={onBlurHandlerDescription} className="input-mycia-secondary mt-2" />

                    <p className="mt-8 text-lg font-semibold">Ci sono ingredienti contaminanti?</p>
                    <div className="flex items-center gap-x-10">
                        <DishContaminantingModal restaurantId={restaurantId} dishId={dishInfo.pia_id} ingredients={mapContaminants(dishInfo)} onUpdate={isContaminantExist} refreshContaminants={updateContaminants}>
                            <MyCIAInput
                                id={`contaminanting-yes-${dishInfo.pia_id}`}
                                name={`contaminanting-${dishInfo.pia_id}`}
                                type="checkbox"
                                value={true}
                                className="mt-1"
                                labelClassName="p-1"
                                checked={contaminants}
                                onChange={() => {}}
                            >
                                <p className="text-lg font-semibold text-black">Sì</p>
                            </MyCIAInput>
                        </DishContaminantingModal>
                        <MyCIAInput
                            id={`contaminanting-no-${dishInfo.pia_id}`}
                            name={`contaminanting-${dishInfo.pia_id}`}
                            type="checkbox"
                            value={false}
                            className="mt-1"
                            labelClassName="p-1"
                            checked={!contaminants}
                            onChange={() => {isContaminantExist()}}
                        >
                            <p className="text-lg font-semibold text-black">No</p>
                        </MyCIAInput>
                    </div>

                    <label
                        htmlFor={`toggle-dish-rec-${dishInfo.pia_id}`}
                        className="relative mx-mobile mt-6 ml-2 inline-flex cursor-pointer items-center"
                    >
                        <span className="text-center text-lg font-semibold">Piatto consigliato</span>
                        <input id={`toggle-dish-rec-${dishInfo.pia_id}`} className="peer sr-only" type="checkbox" value={dishAdvised} checked={dishAdvised} onChange={onChangeHandlerAdvised} />
                        <div className="peer toggle-mycia h-6 w-11 after:h-5 after:w-5"></div>
                    </label>

                    <div
                        className="button-mycia-white mt-8 flex items-center justify-between"
                        onClick={() => setIsMoreDetailEnabled(!isMoreDetailEnabled)}
                    >
                        <p>MAGGIORI DETTAGLI</p>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            className={`h-6 w-6 transition-all duration-300 ${isMoreDetailEnabled ? `rotate-180` : `rotate-0`}`}
                        >
                            <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                        </svg>
                    </div>
                </div>

                {isMoreDetailEnabled && (
                    <div>
                        <div className="mt-6 bg-mycia-whiteSmoke py-6 px-mobile">
                            <div className="flex cursor-pointer items-center justify-center py-4" onClick={onClickAddVariation}>
                                <p className="text-lg font-semibold text-mycia-red underline">AGGIUNGI VARIAZIONE</p>

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    className="icon-mycia ml-4 fill-mycia-red"
                                >
                                    <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
                                </svg>
                            </div>

                            <div className="mt-4 flex flex-col gap-y-4">
                                {
                                    dishInfo.variazioni.map((item, key) => (
                                        <ItemAddVariation key={key} index={key} item={item} isDeletable={true} onDelete={onDeleteVariation} onBlurHandler={onBlurHandlerVariation} />
                                    ))
                                }
                            </div>
                        </div>

                        <p className="text-center text-lg font-semibold text-mycia-red underline my-6 select-none cursor-pointer" onClick={onClickAddCategoryAdditions}>
                            Nuova categoria di aggiunte +
                        </p>

                        <div className="flex flex-col space-y-8">
                            {
                                dishInfo.categorie_aggiunte && dishInfo.categorie_aggiunte.map((item, key) => (
                                    <ItemAddCategory key={item.id} dishId={dishInfo.pia_id} category={item} onDeleteCategory={onDeleteCategory} />
                                ))
                            }
                        </div>
                        <p className="mt-6 text-center text-lg font-semibold">Il piatto è</p>
                        <div className="mx-mobile mt-2 flex flex-wrap justify-center gap-y-6 gap-x-6">
                            <ItemDishType label="Crudista" name="Crudista" checked={dishInfo.pia_crudista === 1} onClickHandler={onClickHandlerDishIs} />
                            <ItemDishType label="Biologico" name="Biologico" checked={dishInfo.pia_biologico === 1} onClickHandler={onClickHandlerDishIs} />
                            <ItemDishType label="Senza glutine" name="SenzaGlutine" checked={dishInfo.pia_senza_glutine === 1} onClickHandler={onClickHandlerDishIs} />
                        </div>

                        <p className="mt-6 text-center text-lg font-semibold">Il piatto contiene</p>
                        <div className="mx-mobile mt-2 flex flex-wrap justify-center gap-y-6 gap-x-6">
                            <ItemDishType label="Solfiti" name="solfiti" checked={dishInfo.pia_solfiti === 1} onClickHandler={onClickHandlerDishContains} />
                            <ItemDishType label="Surgelati" name="congelati" checked={dishInfo.pia_congelati === 1} onClickHandler={onClickHandlerDishContains} />
                        </div>

                        <p className="mx-mobile mt-8 text-lg font-semibold">Servito a</p>
                        <div className="mx-mobile flex items-center flex-wrap gap-x-4">
                            <MyCIAInput
                                id={`servito-pranzo-${dishInfo.pia_id}`}
                                name="pia_piatto_per"
                                type="checkbox"
                                value="0"
                                className="mt-1"
                                labelClassName="p-1"
                                onChange={onChangeServedAt}
                                checked={dishInfo.pia_piatto_per == 0}
                            >
                                <p className="text-lg font-semibold text-black">Pranzo</p>
                            </MyCIAInput>

                            <MyCIAInput
                                id={`servito-cena-${dishInfo.pia_id}`}
                                name="pia_piatto_per"
                                type="checkbox"
                                value="1"
                                className="mt-1"
                                labelClassName="p-1"
                                onChange={onChangeServedAt}
                                checked={dishInfo.pia_piatto_per == 1}
                            >
                                <p className="text-lg font-semibold text-black">Cena</p>
                            </MyCIAInput>

                            <MyCIAInput
                                id={`servito-entrambi-${dishInfo.pia_id}`}
                                name="pia_piatto_per"
                                type="checkbox"
                                value="2"
                                className="mt-1"
                                labelClassName="p-1"
                                onChange={onChangeServedAt}
                                checked={dishInfo.pia_piatto_per == 2}
                            >
                                <p className="text-lg font-semibold text-black">Entrambi</p>
                            </MyCIAInput>
                        </div>

                        <p className="mt-8 text-center text-lg font-semibold">Abbina un vino o bevanda al piatto</p>
                        <AsyncSelect
                            cacheOptions
                            name=""
                            className="mx-mobile mt-2"
                            styles={myCIASelect}
                            isClearable={false}
                            isSearchable={true}
                            placeholder="Nessuno"
                            value={suggestedWine}
                            onChange={onDrinkSelected}
                            loadOptions={promiseDrinkOptions}
                            noOptionsMessage={noOptionsMessage}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ItemDishMenuModify;
