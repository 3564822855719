import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { uploadRestaurantPhoto, uploadLogoPhoto, updateLogoRestaurant, updatePhotoRestaurant, checkPhotoRestaurant } from '../../../apis/restaurants';
import { buildPublicImageURL, buildPublicAssetsUrl } from "../../../logic/utils";
import { modalStyle } from '../../../style';
import Close from '../../Icon/Close';;

function RestaurantPhotoUploadModal(props) {
    const { t } = useTranslation();
    const { children, onRefresh } = props;
    const { restaurantId } = useParams();

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onLogoUpload = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const response = await uploadLogoPhoto(restaurantId, event.target.files[0]);
            if (response) {
                const resUpdate = await updateLogoRestaurant(restaurantId, buildPublicImageURL(response[0]));
                if (resUpdate) {
                    onRefresh();
                    closeModal();
                }
            }
        }
    }

    const onExtraImageUpload = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const fileSizeInMB = event.target.files[0].size / (1024 * 1024);
            console.log(fileSizeInMB);
            if (fileSizeInMB < 1) {
                const images = await uploadRestaurantPhoto(restaurantId, event.target.files[0]);
                const checkFileResponse = await checkPhotoRestaurant(images[0]);
                if (checkFileResponse) {
                    const resUpdate = await updatePhotoRestaurant(restaurantId, buildPublicAssetsUrl(images[0]),  buildPublicAssetsUrl(images[1]));
                    if (resUpdate) {
                        onRefresh();
                        closeModal();
                    }
                }
            } else {
                alert("non può essere caricato. La dimensione massima è 1MB")
            }
        }
    };

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="RestaurantPhotoUploadModal"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Carica immagine</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <input id="logo_upload" name="img" type="file" className="hidden" onChange={onLogoUpload} />
                    <label htmlFor="logo_upload">
                        <div className="button-mycia mt-8">NUOVA FOTO PROFILO</div>
                    </label>

                    <input id="extra_image_upload" name="img" type="file" className="hidden" onChange={onExtraImageUpload} />
                    <label htmlFor="extra_image_upload">
                        <div className="button-mycia my-8">NUOVA FOTO GALLERIA</div>
                    </label>
                </Modal>
            )}
        </div>
    );
}

export default RestaurantPhotoUploadModal;