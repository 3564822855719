import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import AccountForm from "../components/Registration/AccountForm";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function RegistrationAccount(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onClickHandler = () => {
        navigate(-1);
    }
    
    return (
        <div className="Registration">
            <Header isDividerExist={true} />

            <div className="px-mobile pb-10">
                <div className="my-6 flex cursor-pointer items-center" onClick={onClickHandler}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="h-5 w-5">
                        <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                    </svg>

                    <p className="ml-2 text-lg font-light underline">Login</p>
                </div>

                <AccountForm />
            </div>

            <Footer />
        </div>
    );
}

export default RegistrationAccount;
