import React, { useState } from "react";

function ItemService(props) {
    const { id, name, photo, selected, onSelected } = props;
    const [isChecked, setIsChecked] = useState(!!selected);

    const onClickHandler = () => {
        setIsChecked(prev => !prev);
        onSelected(id, !isChecked);
    }

    return (
        <div
            className={`w-[calc(50%-1rem)] p-4 sm:w-[calc(33.3%-1rem)] md:w-[calc(20%-1.2rem)] lg:max-w-[12.5rem] ${isChecked ? `select-mycia-shadow` : `select-mycia-shadow-none opacity-40`} cursor-pointer select-none`}
            onClick={onClickHandler}
        >
            <img className="m-auto h-12 w-12" src={photo} alt="service" />
            <p className="m-auto text-center break-words">{name}</p>
        </div>
    );
}

export default ItemService;
