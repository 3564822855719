import React, { useState } from "react";
import MyCIAInput from "../../Input/MyCIAInput";
import MyCIACheckBox from "../../Input/MyCIACheckBox";
import CreateRestaurantPages from "../CreateRestaurantPages";
import { mapWeekDays } from "../../../logic/utils";
import { useTranslation } from "react-i18next";
import TimePicker from 'rc-time-picker';

const dayListInitialState = [
    {
        name: "MON",
        label: "Lunedì",
        checked: false
    },
    {
        name: "TUE",
        label: "Martedì",
        checked: false
    },
    {
        name: "WED",
        label: "Mercoledi",
        checked: false
    },
    {
        name: "THU",
        label: "Giovedì",
        checked: false
    },
    {
        name: "FRI",
        label: "Venerdì",
        checked: false
    },
    {
        name: "SAT",
        label: "Sabato",
        checked: false
    },
    {
        name: "SUN",
        label: "Domenica",
        checked: false
    }
];

const initialWeekDayList = [
    {
        "day_of_week": "MON",
        "open": "",
        "close": ""
    },
    {
        "day_of_week": "MON",
        "open": "",
        "close": ""
    },
    {
        "day_of_week": "TUE",
        "open": "",
        "close": ""
    },
    {
        "day_of_week": "WED",
        "open": "",
        "close": ""
    },
    {
        "day_of_week": "THU",
        "open": "",
        "close": ""
    },
    {
        "day_of_week": "FRI",
        "open": "",
        "close": ""
    },
    {
        "day_of_week": "SAT",
        "open": "",
        "close": ""
    },
    {
        "day_of_week": "SAT",
        "open": "",
        "close": ""
    },
    {
        "day_of_week": "SUN",
        "open": "",
        "close": ""
    }
];

const timeInitialState = {
    firstStart: "",
    firstEnd: "",
    secondStart: "",
    secondEnd: ""
};

function TimeForm(props) {
    const { t } = useTranslation();
    const [weekDayDropDown, setWeekDayDropDown] = useState(false);

    let onClickWeekDayHandler = () => setWeekDayDropDown(!weekDayDropDown);

    const { onSubmitHandler } = props;
    
    const [timeFormData, setTimeFormData] = useState([]);
    const [allDayClosed, setAllDayClosed] = useState(false);
    const [continuedWork, setContinuedWork] = useState(false);
    const [weekDayList, setWeekDayList] = useState(initialWeekDayList);
    const [daysList, setDaysList] = useState(dayListInitialState);
    const [timeState, setTimeState] = useState(timeInitialState);
    
    const onChangeWeekDayHandler = ({ target }) => {
        const data = daysList;
        data[data.findIndex(item => item.name === target.name)].checked = target.checked;
        setDaysList(data);
    }

    const onChangeTimeHandler = ({ target }) => {
        return setTimeState(timeState => ({
            ...timeState,
            [target.name]: target.value,
        }));
    }

    function onSubmitWeekDay() {
        const nameOfDays = daysList.filter(item => item.checked !== false).map(item => item.name);
        let tempWeekDayList = weekDayList.filter(item => item.day_of_week.length !== 0);
        
        tempWeekDayList = tempWeekDayList.filter(item => !nameOfDays.includes(item.day_of_week));

        if (allDayClosed) {
            nameOfDays.forEach(item => {
                tempWeekDayList.push({
                    day_of_week: item,
                    open: "",
                    close: ""
                });
            });
        } else {
            if (continuedWork) {
                nameOfDays.forEach(item => {
                    tempWeekDayList.push({
                        day_of_week: item,
                        open: timeState.firstStart,
                        close: timeState.firstEnd
                    });
                });
            } else {
                nameOfDays.forEach(item => {
                    tempWeekDayList.push({
                        day_of_week: item,
                        open: timeState.firstStart,
                        close: timeState.firstEnd
                    });
                    tempWeekDayList.push({
                        day_of_week: item,
                        open: timeState.secondStart,
                        close: timeState.secondEnd
                    });
                });
            }
        }
        setWeekDayList(tempWeekDayList);
    }

    const onSubmit = (evt) => {
        evt.preventDefault();

        let formData = {};
        mapWeekDays(weekDayList).forEach(item => {
            formData = {
                ...formData,
                [`Orari[${item.name}]`]: item.time,
            }
        });

        const payload = {
            ...timeFormData,
            ...formData
        };

        onSubmitHandler(payload);
    }
    
    return (
        <form method="POST" action="" onSubmit={onSubmit}>
            <fieldset>
                <legend className="font-light mt-4 inline-block">
                    <span className="font-semibold">Creazione profilo ristorante</span> <br /> <br />
                    Compila i campi sottostanti con i dati del tuo ristorante per crearne il profilo su MyCIA. Ricordati di tornare su questa pagina dopo la sua creazione per aggiungere i servizi disponibili nel tuo locale.
                </legend>

                <CreateRestaurantPages selectedPage={2} className="mt-10" />

                <label className="mt-8 inline-block text-sm">ORARI DEL RISTORANTE</label>

                <div className="button-mycia-white relative mt-2">
                    <div className="flex cursor-pointer select-none items-center" onClick={onClickWeekDayHandler}>
                        <p className="flex-1 font-semibold uppercase">SCEGLI UNO O PIÙ GIORNI</p>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            className={`w-6 self-end transition-all duration-300 ${weekDayDropDown ? `rotate-180` : `rotate-0`}`}
                        >
                            <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                        </svg>
                    </div>

                    <div
                        className={`shadow-mycia absolute left-0 max-h-0 mt-5 w-full rounded-lg overflow-hidden transition-all z-50 duration-300 ${weekDayDropDown ? `max-h-96` : ``} bg-white`}
                    >
                        {daysList.map((item, key) => (
                            <MyCIACheckBox
                                key={key}
                                id={`weekDay${key}`}
                                name={item.name}
                                className="mx-4 pr-8 my-3"
                                labelClassName="w-full"
                                onChange={onChangeWeekDayHandler}>
                                <span className="font-semibold text-center w-full">{item.label}</span>
                            </MyCIACheckBox>
                        ))}
                    </div>
                </div>

                <p className="mt-8 text-center text-lg font-semibold">È turno di chiusura?</p>
                <div className="mt-1 flex justify-center">
                    <MyCIAInput
                        type="radio"
                        name="closetime"
                        id="closetime-yes"
                        className="mx-4 mt-1"
                        labelClassName="p-1"
                        onChange={({target}) => setAllDayClosed(target.checked) }
                        checked={allDayClosed}
                    >
                        <div className="text-center text-lg font-semibold text-black ">
                            <p>Si</p>
                        </div>
                    </MyCIAInput>

                    <MyCIAInput
                        type="radio"
                        name="closetime"
                        id="closetime-no"
                        className="mx-4 mt-1"
                        labelClassName="p-1"
                        onChange={({ target }) => setAllDayClosed(!target.checked)}
                        checked={!allDayClosed}
                    >
                        <div className="text-center text-lg font-semibold text-black ">
                            <p>No</p>
                        </div>
                    </MyCIAInput>
                </div>

                {   
                    !allDayClosed &&
                        <div>
                            <p className="mt-8 text-center text-lg font-semibold">Fai orario continuato?</p>
                            <div className="mt-1 flex justify-center">
                                <MyCIAInput
                                    type="radio"
                                    name="fulltime"
                                    id="fulltime-yes"
                                    className="mx-4 mt-1"
                                    labelClassName="p-1"
                                    onChange={({target}) => setContinuedWork(target.checked)}
                                    checked={continuedWork}
                                >
                                    <div className="text-center text-lg font-semibold text-black ">
                                        <p>Si</p>
                                    </div>
                                </MyCIAInput>

                                <MyCIAInput
                                    type="radio"
                                    name="fulltime"
                                    id="fulltime-no"
                                    className="mx-4 mt-1"
                                    labelClassName="p-1"
                                    onChange={({target}) => setContinuedWork(!target.checked)}
                                    checked={!continuedWork}
                                >
                                    <div className="text-center text-lg font-semibold text-black ">
                                        <p>No</p>
                                    </div>
                                </MyCIAInput>
                        </div>
                        <div>
                            {continuedWork != null  &&
                                <div>
                                    <p className="mt-8 text-center text-lg font-semibold">Inserisci l'orario</p>

                                    <div className="mt-4 flex items-center justify-center">
                                        <TimePicker showSecond={false} minuteStep={15} placeholder="--:--" className="mycia-time-picker-input" onChange={(e) => onChangeTimeHandler("firstStart", e.format("HH:mm"))}/>
                                        <p className="mx-4 text-lg font-semibold">-</p>
                                        <TimePicker showSecond={false} minuteStep={15} placeholder="--:--" className="mycia-time-picker-input" onChange={(e) => onChangeTimeHandler("firstEnd", e.format("HH:mm"))}/>
                                    </div>

                                    {!continuedWork && 
                                        <div className="mt-4 flex items-center justify-center">
                                            <TimePicker showSecond={false} minuteStep={15} placeholder="--:--" className="mycia-time-picker-input" onChange={(e) => onChangeTimeHandler("secondStart", e.format("HH:mm"))}/>
                                            <p className="mx-4 text-lg font-semibold">-</p>
                                            <TimePicker showSecond={false} minuteStep={15} placeholder="--:--" className="mycia-time-picker-input" onChange={(e) => onChangeTimeHandler("secondEnd", e.format("HH:mm"))}/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }

                <div className="button-mycia-white mt-10 cursor-pointer select-none uppercase" onClick={onSubmitWeekDay}>
                    <p>AGGIUNGI</p>
                </div>

                <label className="mt-8 inline-block w-full text-center font-semibold">ORARI SALVATI</label>
                <div className="mt-6 flex flex-col gap-y-4">
                    {mapWeekDays(weekDayList).map((item, key) => (
                        <div className="flex justify-between text-lg font-light" key={key}>
                            <p>{item.day}</p>
                            <p>{item.time !== "" ? item.time : "Chiuso"}</p>
                        </div>
                    ))}
                </div>

                <button type="submit" className="button-mycia w-full mt-12">CREA PROFILO RISTORANTE</button>
            </fieldset>
        </form>
    );
}

export default TimeForm;
