import React from "react";

function Booking(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.72 63.95" onClick={onClick} className={className}>
            <defs>
                <linearGradient
                    id="A"
                    x1="3.576"
                    x2="60.716"
                    y1="42.362"
                    y2="42.362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#cf5448"></stop>
                    <stop offset="1" stopColor="#d16c4b"></stop>
                </linearGradient>
            </defs>
            <path d="M64.72 3.98v59.97H0V3.98h11.38V2.51C11.38 1.12 12.5 0 13.89 0h6.48c1.39 0 2.51 1.12 2.51 2.51v1.47h18.96V2.51c0-1.39 1.13-2.51 2.51-2.51h6.48c1.39 0 2.51 1.12 2.51 2.51v1.47h11.38zm-3.8 55.98V24.29H3.79v35.68h57.13zm0-39.66V7.96h-7.58v8.1h-11.5v-8.1H22.87v8.1h-11.5v-8.1H3.78v12.35h57.14zm-11.38-8.36V3.98h-3.91v7.96h3.91zm-30.46 0V3.98h-3.92v7.96h3.92z"></path>
            <path fill="url(#A)" d="M3.79 24.29h57.14v35.68H3.79z"></path>
            <path
                fill="#a83634"
                d="M60.93 7.97v12.34H3.79V7.97h7.58v8.1h11.5v-8.1h18.97v8.1h11.5v-8.1h7.58z"
            ></path>
        </svg>
    );
}

export default Booking;
