import React, { useState } from "react";
import Select from "react-select";
import { ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, Line } from "recharts";
import { myCIASelect } from "../../style";
import MyCIACheckBox from "../Input/MyCIACheckBox";
import { useTranslation } from "react-i18next";

function Insight() {
    const { t } = useTranslation();
    const [stampMenu, setStampMenu] = useState(false);
    const services = [
        { label: "Delivery", enabled: true },
        { label: "Takeaway", enabled: false },
        { label: "Al tavolo", enabled: false },
    ];

    let onClickStampMenuHanlder = () => setStampMenu(!stampMenu);

    const data = [];
    const rand = 300;
    for (let i = 0; i < 7; i++) {
        let d = {
            year: 2000 + i,
            value: Math.random() * (rand + 50) + 300,
        };

        data.push(d);
    }

    const options = [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
    ];

    return (
        <div className="RestaurantInsights p-mobile">
            <p className="text-lg font-semibold">Insights</p>

            <div className="button-mycia-white relative z-50 mt-2">
                <div className="flex cursor-pointer select-none items-center" onClick={onClickStampMenuHanlder}>
                    <p className="flex-1 font-semibold uppercase">SCEGLI UNO O PIÙ GIORNI</p>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                        className={`w-6 self-end transition-all duration-300 ${stampMenu ? `rotate-180` : `rotate-0`
                            }`}
                    >
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                    </svg>
                </div>

                <div
                    className={`shadow-mycia absolute left-0 mt-5 w-full rounded-lg py-3 transition-all duration-150 ${stampMenu ? `` : `hidden`
                        } bg-white`}
                >
                    {[1, 2, 3, 4, 5, 6, 7].map((item, key) => (
                        <MyCIACheckBox
                            id={`weekDay${key}`}
                            key={key}
                            className="z-50 mx-4 my-3 pr-8"
                            labelClassName="w-full"
                        >
                            <span className="w-full text-center font-semibold">Lunedi</span>
                        </MyCIACheckBox>
                    ))}
                </div>
            </div>

            <p className="mt-8 text-center font-semibold">ORDINI TOTALI</p>
            <ResponsiveContainer className="mt-4 aspect-video max-w-full md:aspect-video md:max-h-64">
                <LineChart data={data} margin={{ top: 5, left: -5, bottom: 5 }}>
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#f46139" dot={true} />
                    <XAxis dataKey="year" />
                    <YAxis />
                </LineChart>
            </ResponsiveContainer>

            <p className="mt-8 text-center font-semibold">VISUALIZZAZIONI MENÙ</p>
            <ResponsiveContainer className="mt-4 aspect-video max-w-full md:aspect-video md:max-h-64">
                <LineChart data={data} margin={{ top: 5, left: -5, bottom: 5 }}>
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#f46139" dot={false} />
                    <XAxis dataKey="year" />
                    <YAxis />
                </LineChart>
            </ResponsiveContainer>

            <p className="mt-8 text-center font-semibold">EURO CIRCUITATI</p>
            <ResponsiveContainer className="mt-4 aspect-video max-w-full md:aspect-video md:max-h-64">
                <LineChart data={data} margin={{ top: 5, left: -5, bottom: 5 }}>
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#f46139" dot={false} />
                    <XAxis dataKey="year" />
                    <YAxis />
                </LineChart>
            </ResponsiveContainer>

            <p className="mt-8 text-center font-semibold">PIATTI E BEVANDE PIÙ VENDUTI</p>
            <ResponsiveContainer className="mt-4 aspect-video max-w-full md:aspect-video md:max-h-64">
                <LineChart data={data} margin={{ top: 5, left: -5, bottom: 5 }}>
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#f46139" dot={false} />
                    <XAxis dataKey="year" />
                    <YAxis />
                </LineChart>
            </ResponsiveContainer>

            <div className="rounded-mobile mt-16 bg-[#FF805B] p-6">
                <div className="flex items-center justify-between">
                    <p className="text-xl font-semibold text-white">Statistiche</p>

                    <Select
                        styles={myCIASelect}
                        isClearable={false}
                        options={options}
                        placeholder="Nessuno"
                        noOptionsMessage={({ inputValue }) => (!inputValue ? "Nessuno" : "No results found")}
                    />
                </div>

                <div className="rounded-mobile mt-4 flex justify-between bg-white py-3 px-4">
                    <p className="font-light">Ordini</p>

                    <p className="font-semibold">0</p>
                </div>

                <div className="rounded-mobile mt-2.5 flex justify-between bg-white py-3 px-4">
                    <p className="font-light">Importo totale</p>

                    <p className="font-semibold">0</p>
                </div>

                <div className="rounded-mobile mt-2.5 flex justify-between bg-white py-3 px-4">
                    <p className="font-light">Importo medio per ordine</p>

                    <p className="font-semibold">0</p>
                </div>
            </div>

            <div className="rounded-mobile mt-10 bg-[#FF805B] p-6">
                <div className="flex items-center gap-x-4">
                    {/* TODO: ICON */}

                    <p className="text-xl font-semibold text-white">Servizi</p>
                </div>

                <div className="mt-4 flex flex-col gap-y-2.5">
                    {services.map((item, key) => (
                        <div
                            key={key}
                            className={`rounded-mobile flex justify-between bg-white py-3 px-4 ${item.enabled ? `font-semibold` : `font-light opacity-50`
                                } `}
                        >
                            <p>{item.label}</p>

                            <p>{item.enabled ? `Attivo` : `Disattivo`}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="rounded-mobile bg-mycia-whiteSmoke my-10 px-6 py-8">
                <div className="flex gap-x-4">
                    {/* TODO: ICON */}

                    <p className="font-semibold">
                        Contatti e-mail dei tuoi clienti: <span className="text-mycia-red">34</span>
                    </p>
                </div>

                <div className="button-mycia-white mt-4">
                    <p>COMUNICA CON I TUOI CLIENTI</p>
                </div>
            </div>
        </div>
    );
}

export default Insight;
