import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { editCategoryAddMore } from "../../../apis/modify-restaurant";
import Delete from "../../Icon/Delete";
import { useTranslation } from "react-i18next";

function ItemAddMore(props) {
    const { t } = useTranslation();
    const { restaurantId } = useParams();
    const { className, dishId, item, index, isDeletable, onDelete, onCreate } = props;

    const [addMore] = useState(item);
    const [addMoreName, setAddMoreName] = useState(item.nome_aggiunta);
    const [addMorePrice, setAddMorePrice] = useState(item.aggiunta_prezzo);

    const updateCategoryAddMore = async () => {
        if (!addMoreName && !addMorePrice) return;

        if (addMore.pva_id != null) {
            await editCategoryAddMore(restaurantId, dishId, addMore.pva_id, addMoreName, addMorePrice);
        } else {
            if (!addMoreName) {
                alert("Inserisci il nome della aggiunga prima del prezzo");
                setAddMorePrice("");
            } else {
                await onCreate(index, addMoreName);
            }
        }
    }

    return (
        <div className={`${className} flex w-full items-center gap-x-4`}>
            {
                isDeletable && <Delete onClick={() => onDelete(addMore.pva_id, index)} className="ml-3 h-3.5 w-3.5 cursor-pointer flex-shrink-0 fill-black" />
            }

            <input name="nome" type="text" value={addMoreName} onChange={({target}) => setAddMoreName(target.value)} onBlur={updateCategoryAddMore} placeholder="Aggiungi ingrediente" className="input-mycia flex-1 valid:bg-white" />

            <input name="aggiunta_prezzo" type="number" value={addMorePrice} onChange={({target}) => setAddMorePrice(target.value)} onBlur={updateCategoryAddMore} placeholder="€" className="input-mycia w-[10ch] flex-none valid:bg-white" />
        </div>
    );
}

export default ItemAddMore;
