import React, { useState } from 'react';
import Modal from 'react-modal';
import { addWine, getWineCategoriesInfo } from '../../../../apis/modify-restaurant';
import { modalStyle } from '../../../../style';
import Close from '../../../Icon/Close';
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

function WineAddModal(props) {
    const { t } = useTranslation();
    const { children, restaurantId, categoryName, onWineCreated } = props;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [name, setName] = useState();
    const [selectedCategoryId, setSelectedCategoryId] = useState(-1);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onCreateDish = async () => {
        if (selectedCategoryId == null) return;
        const payload = {
            name: name,
            category: categoryName,
            categoryId: selectedCategoryId
        };

        const res = await addWine(restaurantId, payload);
        if (res.outcome) {
            closeModal();
            onWineCreated();
        }
    }

    useEffect(() => {
        async function loadCategories() {
            const res = await getWineCategoriesInfo(restaurantId);
            setSelectedCategoryId(res.results?.find(item => item.text === categoryName)?.id ?? null)
        }

        if (modalIsOpen === true) {
            loadCategories();
        }
    }, [modalIsOpen, restaurantId, categoryName]);

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="Aggiungi bevande"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Aggiungi bevande</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <label htmlFor="name" className="mt-6 inline-block text-sm font-semibold uppercase">NOME BEVANDE</label>
                    <input id="name" type="text" className="input-mycia-secondary mt-2" placeholder="Nome Bevande" onChange={({target}) => setName(target.value)} />

                    <div className="button-mycia mt-8" onClick={onCreateDish}>CONFERMA</div>
                </Modal>
            )}
        </div>
    );
}

export default WineAddModal;