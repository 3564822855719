import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from "react-select";
import { changeCategoryOfDish, getMenuList } from '../../../../apis/modify-restaurant';
import { modalStyle, myCIASelect } from '../../../../style';
import Close from '../../../Icon/Close';
import { useTranslation } from "react-i18next";

function DishChangeCategoryModal(props) {
    const { t } = useTranslation();
    const { children, restaurantId, dishId, categoryId, onRefresh } = props;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({});
    const [selectedOption, setSelectedOption] = useState({});
    
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        onRefresh();
    }

    const mapMenuOptions = (data) => {
        return data.map(item => ({
            label: item.prt_nome,
            value: item.portata_id
        }))
    }

    const onClickConfirm = async () => {
        if (selectedOption) {
            const res = await changeCategoryOfDish(restaurantId, dishId, selectedOption.value);
            if (res.outcome) closeModal();
        }
    }

    useEffect(() => {
        async function loadData() {
            const res = await getMenuList(restaurantId);
            if (res.portate) {
                const option = res.portate.find(it => it.pia_id === categoryId);
                setSelectedOption(({
                    label: option.prt_nome,
                    value: option.portata_id
                }))
                setData(mapMenuOptions(res.portate));
            }
        };

        if(modalIsOpen)
            loadData();
    }, [restaurantId, dishId, categoryId, modalIsOpen]);

    const noOptionsMessage = ({ inputValue }) => (inputValue ? "Nessuno" : "No results found");

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="Example Modal"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Cambia categoria</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <Select
                        id="type_1"
                        className="mt-14 mb-20"
                        styles={myCIASelect}
                        isClearable={false}
                        isSearchable={false}
                        options={data}
                        placeholder="Nessuno"
                        noOptionsMessage={noOptionsMessage}
                        value={selectedOption}
                        onChange={(event) => setSelectedOption(event)}
                    />

                    <div className="button-mycia flex-1" onClick={onClickConfirm}>CONFERMA</div>
                </Modal>
            )}
        </div>
    );
}

export default DishChangeCategoryModal;
