const defaultSelect = {
    border: "0",
    outline: "none",
    boxShadow: "none",
    paddingTop: "0.16rem",
    paddingBottom: "0.16rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "0.5rem",
    fontWeight: 300,
    fontSize: "1.125rem",
    fontFamily: "Nunito, sans-serif",
    color: "rgb(0 0 0 / var(--tw-text-opacity))",
};

const myCIASelect = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: "#f2efef",
        ...defaultSelect,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "rgba(235,100,64,.7)" : "white",
        "&:hover": {
            color: "#000",
            backgroundColor: "#F46139",
        },
    }),
};

const myCIASelectWhiteControl = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: "#fff",
        ...defaultSelect,
    }),
};

const modalStyle = {
    content: {
        width: '80%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRadius: "10px",
        border: "0",
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        backgroundColor: "rgba(112, 112, 112, 0.35)"
    }
};


export {
    myCIASelect,
    myCIASelectWhiteControl,
    modalStyle,
}
