import React from 'react';

function MenuBodyFirst(props) {
    const { name, subItems, isDescriptionEnabled } = props;
    return (
        <div className="MenuBodyFirst">
            <p className="w-full mt-4 text-center text-2xl text-mycia-orangeRed font-firstStyle italic font-bold capitalize">{name}</p>
                                
            <div>
                {
                    subItems.map((it, index) => (
                        <div key={index} className="w-full text-center">
                            <p className="w-full mt-2 font-firstStyle italic text-lg">{it.name}</p>

                            {
                                isDescriptionEnabled && <p className="font-firstStyle font-medium text-sm uppercase">{it.description}</p>
                            }
                            <p className="text-xs font-secondStyle uppercase">{it.ingredients && it.ingredients.map(it => it.name).join(", ")}</p>
                            <p className="mt-2 font-firstStyle italic">{it.price} €</p>
                            
                            <div className="w-24 h-[1px] mt-4 mx-auto bg-mycia-dimGray"></div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default MenuBodyFirst;