import { createContext, useContext, useState } from "react";
import { PRINT_ENUM } from "./utils";

const PrintTypeContext = createContext();

function PrintTypeProvider(props) {
    const [printType, setPrintType] = useState(PRINT_ENUM.ORDER_AT_TABLE);

    const onChangePrintType = ({target}) => setPrintType(target.value);

    const value = {
        setPrintType,
        printType,
        onChangePrintType
    }

    return <PrintTypeContext.Provider value={value}>{props.children}</PrintTypeContext.Provider>;
}

function usePrintType() {
    const context = useContext(PrintTypeContext);

    if (!context) {
        throw new Error("usePrintType must be used within a PrintTypeProvider");
    }
    return context;
}

export {
    PrintTypeProvider,
    usePrintType,
}
