import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { checkDeliveryServices, setAllowSharing } from "../../../apis/restaurants";
import { usePrintType } from "../../../logic/PrintTypeContext";
import { buildContactlessMenuURL } from "../../../logic/utils";
import ItemCoupon from "./ItemCoupon";
import { useTranslation } from "react-i18next";

function MarketingTools() {
    const { t } = useTranslation();
    
    const { printType } = usePrintType();

    const { restaurantId, token } = useParams();

    const [menuLink, setMenuLink] = useState();
    const [allowSharingToggle, setAllowSharingToggle] = useState();

    const loadAllowSharingData = useCallback(async () => {
        const res = await checkDeliveryServices(restaurantId);
;        if (res.outcome) setAllowSharingToggle(res.sharable_menu_link);
    }, [restaurantId]);

    useEffect(() => {
        setMenuLink(buildContactlessMenuURL(printType, token));
        loadAllowSharingData();
    }, [loadAllowSharingData, printType, token]);

    const onToggleAllowSharing = async () => {
        const res = await setAllowSharing(restaurantId, allowSharingToggle);
        if (res.outcome) setAllowSharingToggle(prev => !prev);
    }

    return (
        <div className="MarketingTools flex flex-col gap-y-4">
            <div className="rounded-mobile border-2 border-mycia-dimGray">
                <p className="py-4 text-center font-light">Permetti la condivisione del menù dalla webapp</p>

                <hr className="border-t-2 border-mycia-dimGray" />

                <div className="p-4">
                    <div className="flex">
                        <div className="w-3/4">
                            <p className="font-semibold">Link condivisibile del tuo menù</p>
                            <p className="mt-2 font-light">Copia il link del tuo menù e condividilo per aumentare il numero di ordini ricevuti.</p>
                        </div>

                        <div className="relative flex w-1/4 items-start justify-end">
                            <label htmlFor="allow-sharing-toggle" className="relative inline-flex cursor-pointer items-center">
                                <input type="checkbox" checked={allowSharingToggle} onChange={onToggleAllowSharing} id="allow-sharing-toggle" className="peer sr-only" />
                                <div className="peer ml-4 h-5 w-9  rounded-full bg-gray-200 after:absolute after:top-0.5 after:right-[calc(1.3rem-3px)] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-mycia-orangeRed peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-mycia-titaniumGray"></div>
                            </label>
                        </div>
                    </div>

                    <input className="input-mycia-secondary mt-4 text-base" type="url" value={menuLink} onClick={({target}) => {target.select()}} readOnly={true}/>

                    <a href="https://ristoratori.mycia.it/net/profilo/step-14/docs/20201124_Tutorial%20condivisione%20men%C3%B9%20digitale.pdf" target="_blank" rel="noopener noreferrer">
                        <p className="mt-6 mb-2 text-center font-semibold underline">Come condividere il menù</p>
                    </a>
                </div>
            </div>

            <div className="rounded-mobile border-2 border-mycia-dimGray hidden">
                <p className="p-4 font-semibold">Codici sconto (coupon)</p>

                <hr className="border-t-2 border-mycia-dimGray" />

                <p className="p-4 font-light">I tuoi clienti, inserendo un codice da te definito, otterranno uno sconto sull'ordine che effettuano.</p>

                <div className="flex flex-col gap-y-4">
                    {[1, 2].map((item, key) => (
                        <ItemCoupon isExist={key % 2 === 0} />
                    ))}
                </div>

                <div className="button-mycia m-2 max-w-full">
                    <p>AGGIUNGI COUPON</p>
                </div>
            </div>
        </div>
    );
}

export default MarketingTools;
