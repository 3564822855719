import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { editCategoryAddIngredient, getIngredientsByName } from "../../../apis/modify-restaurant";
import { myCIASelect, myCIASelectWhiteControl } from "../../../style";
import Delete from "../../Icon/Delete";
import { useTranslation } from "react-i18next";

function ItemAddIngredient(props) {
    const { t } = useTranslation();
    const { restaurantId } = useParams();
    const {
        className,
        dishId,
        item,
        index,
        isDeletable = true,
        onDelete,
        onCreate
    } = props;
    
    const [addIngredient] = useState(item);
    const [addIngredientPrice, setAddIngredientPrice] = useState(item.aggiunta_prezzo);

    function mapSuggestedIngredients(data) {
        return data && data.map(it => ({
            label: it.text,
            value: it.id
        }));
    }

    const [suggestedIngredient, setSuggestedIngredient] = useState(item.ali_nome ? {
        label: item.ali_nome,
        value: item.aggiunta_alimento_id 
    }: null);

    const onChangeSuggestedIngredient = async ({target}) => {
        const id =  !target.name ? target.value : suggestedIngredient && suggestedIngredient.value;
        const price = target.name === "aggiunta_prezzo" ? target.value : addIngredientPrice || "";

        if (!id && !price) return;

        if (!addIngredient.pva_id) {
            if (id) {
                await onCreate(index, id);
            } else {
                alert("Inserisci il nome dell'aggiunta prima del prezzo");
                setAddIngredientPrice("");
            }
        } else {
            await editCategoryAddIngredient(restaurantId, dishId, addIngredient.pva_id, id, price);
        }
    }

    const promiseIngredientOptions = (inputValue) => {
        return new Promise(async (resolve) => {
            if (inputValue.length > 1) {
                const data = await getIngredientsByName(restaurantId, inputValue.trim());
                resolve(mapSuggestedIngredients(data.response));
            } else {
                resolve([]);
            }
        });
    }

    const onOptionChanged = async (e) => {
        setSuggestedIngredient(e);
        await onChangeSuggestedIngredient({target: e});
    }

    const noOptionsMessage = ({ inputValue }) => (inputValue.length > 1 ? "Nessun risultato trovato" : `Per favore inserisci ${2 - inputValue.length} o più caratteri`);

    return (
        <div className={`${className} flex w-full justify-between items-center gap-x-4`}>
            {
                isDeletable && 
                    <Delete onClick={() => onDelete(addIngredient.pva_id, index)} className="ml-3 h-3.5 w-3.5 cursor-pointer flex-shrink-0 fill-black" />
            }

            <AsyncSelect
                name="alimento_id"
                cacheOptions
                className="flex-1"
                styles={{
                    option: myCIASelect.option,
                    control: myCIASelectWhiteControl.control,
                }}
                isClearable={false}
                isSearchable={true}
                placeholder="Nessuno"
                value={suggestedIngredient}
                onChange={onOptionChanged}
                loadOptions={promiseIngredientOptions}
                noOptionsMessage={noOptionsMessage}
            />

            <input name="aggiunta_prezzo" type="number" placeholder="€" value={addIngredientPrice} onChange={({target}) => setAddIngredientPrice(target.value)} onBlur={onChangeSuggestedIngredient} className="input-mycia w-[10ch] flex-none valid:bg-white" />
        </div>
    );
}

export default ItemAddIngredient;
