import { LOCALSTORAGE_KEY, LOGS_DOMAIN, USER_AGENT, NODE_ENV, DEV_AUTHORIZATION, DEV_SECRET } from "./constants";

function doGet(url, config = {}) {
    config = {
        method: "GET",
        ...config,
        headers: {
            Accept: "application/json",
            ...config.headers,
        },
    };

    if (NODE_ENV === "development") {
        config["headers"]["Authorization"] = `Basic ${DEV_AUTHORIZATION}`;
    }

    return fetch(url, config).then(async (response) => {
        if (response.ok) {
            return await response.json();
        } else {
            var message = await response.text();
            return Promise.reject(new Error(message));
        }
    });
}

function doPost(url, data, config = {}) {
    var headers = {
        "Accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    };
    
    config = {
        method: "POST",
        ...config,
        headers: {
            ...headers,
            ...config.headers,
        },
        body: data,
    };

    if (NODE_ENV === "development") {
        headers["Authorization"] = `Basic ${DEV_AUTHORIZATION}`;
    }

    data.append("dev-key", DEV_SECRET); 
    data.append('agent', USER_AGENT);

    return fetch(url, config).then(async (response) => {
        if (response.ok) {
            return await response.json();
        } else {
            var message = await response.text();
            return Promise.reject(new Error(message));
        }
    });
}

function doAuthGet(url, config = {}) {
    config = {
        method: "GET",
        ...config,
        headers: {
            Accept: "application/json",
            ...config.headers,
        },
    };

    if (NODE_ENV === "development") {
        config["headers"]["Authorization"] = `Basic ${DEV_AUTHORIZATION}`;
    }

    return fetch(url, config).then(async (response) => {
        if (response.ok) {
            return await response.json();
        } else {
            var message = await response.text();
            return Promise.reject(new Error(message));
        }
    });
}

function doAuthPost(url, data, config = {}) {
    var headers = {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    };

    var token = window.localStorage.getItem(LOCALSTORAGE_KEY);
    if (NODE_ENV === "development") {
        headers["Authorization"] = `Basic ${DEV_AUTHORIZATION}`;
    } else if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    
    data.append('token', token);
    data.append("dev-key", DEV_SECRET); 
    data.append('agent', USER_AGENT);

    config = {
        method: "POST",
        ...config,
        headers: {
            ...headers,
            ...config.headers,
        },
        body: data,
    };

    return fetch(url, config).then(async (response) => {
        if (response.ok) {
            const res = await response.json();
            if (res && !res.outcome && res.logout) {
                window.localStorage.removeItem(LOCALSTORAGE_KEY);
                window.location.replace('/');
            }
            return res;
        } else {
            var message = await response.text();
            return Promise.reject(new Error(message));
        }
    });
}

async function doAuthPostLog(url, data, config = {}) {
    var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
    };

    var token = "hardcoded token";
    headers["Authorization"] = `Bearer ${token}`;

    var urlencoded = new URLSearchParams();
    urlencoded.append("data[type]", "weblog");
    urlencoded.append("data[attributes][action]", data.action);
    urlencoded.append("data[attributes][payload]", data.payload);
    urlencoded.append("data[attributes][user_id]", data.user_id);

    config = {
        method: "POST",
        ...config,
        headers: {
            ...headers,
            ...config.headers,
        },
        body: urlencoded,
    };

    if ("production" === process.env.NODE_ENV) {
        return fetch(`${LOGS_DOMAIN}${url}`, config)
            .then(async (response) => {
                if (response.ok) {
                    return await response.json();
                } else {
                    var message = await response.text();
                    return Promise.reject(new Error(message));
                }
            })
            .catch((e) => console.log(e));
    } else {
        const searchParams = new URLSearchParams(data);

        console.log({
            action: searchParams.get("action"),
            payload: JSON.parse(searchParams.getAll("payload")),
            user_id: searchParams.get("user_id"),
        });
    }
}

async function doFilePost(url, data) {
    var token = window.localStorage.getItem(LOCALSTORAGE_KEY);
    
    data.append("dev-key", DEV_SECRET); 
    data.append('agent', USER_AGENT);
    data.append('token', token);

    const config = {
        method: "POST",
        body: data,
    };

    return fetch(url, config).then(async (response) => {
        if (response.ok) {
            try{ 
                return await response.json();
            }
            catch(e) {
                return {}
            }
        } else {
            var message = await response.text();
            return Promise.reject(new Error(message));
        }
    });
}

export {
    doGet,
    doPost,
    doAuthGet,
    doAuthPost,
    doAuthPostLog,
    doFilePost,
}
