import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { deleteWinePhoto, deleteWinePhotoPath, getImageOfWine, updateWinePhoto, uploadWinePhoto } from '../../../../apis/modify-restaurant';
import { modalStyle } from '../../../../style';
import Close from '../../../Icon/Close';
import Delete from '../../../Icon/Delete';
import { useTranslation } from "react-i18next";

function WinePhotoModal(props) {
    const { t } = useTranslation();
    const { children, restaurantId, wineId } = props;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({});

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const uploadFile = async (event) => {
        if (event.target.files && event.target.files[0]) {
            let res = await uploadWinePhoto(event.target.files[0], restaurantId, wineId);
            if (res && res.filename) {
                res = await updateWinePhoto(restaurantId, wineId, res.filename);
                if (res.outcome) closeModal();
            }
        }
    }

    const deletePhoto = async () => {
        if (data.full_path) {
            const filename = data.full_path.substring(data.full_path.lastIndexOf('/') + 1)
            let res = await deleteWinePhoto(restaurantId, filename)
            if (res.outcome) {
                res = await deleteWinePhotoPath(restaurantId, wineId, filename);
                if (res.outcome) closeModal();
            }
        }
    }

    useEffect(() => {
        async function loadData() {
            const res = await getImageOfWine(restaurantId, wineId);
            setData(res);
        };

        if(modalIsOpen)
            loadData();
    }, [restaurantId, wineId, modalIsOpen]);

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="Example Modal"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Foto bevande</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <div className="flex justify-center items-center min-h-[10rem]">
                        {data.full_path && <img src={data.full_path} className="mx-auto max-h-[20rem]" alt="Wine" />}
                    </div>


                    <div className="flex gap-x-4 sm:justify-between">
                        <input id="file_upload" name="img" type="file" className="hidden" onChange={uploadFile} />
                        <label htmlFor="file_upload">
                            <div className="button-mycia flex-1">CAMBIA IMMAGINE</div>
                        </label>

                        <div className="button-mycia-white flex items-center shadow-none bg-mycia-whiteSmoke" onClick={deletePhoto}>
                            <Delete className="h-4 w-4 cursor-pointer fill-black" />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default WinePhotoModal;
