import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import CIAForm from "../components/Registration/CIAForm";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function RegistrationCIA(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onClickBackHandler = () => {
        navigate(-1);
    }

    return (
        <div className="RegistrationCIA">
            <Header isDividerExist={true} />

            <div className="px-mobile py-6 border-t border-mycia-dimGray text-mycia-dimDark">
                <div className="flex items-center" onClick={onClickBackHandler}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="icon-mycia">
                        <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
                    </svg>

                    <p className="text-lg font-light underline cursor-pointer">Dati gestore</p>
                </div>

                <CIAForm />
            </div>

            <Footer />
        </div>
    );
}

export default RegistrationCIA;
