import React from "react";
import MyCIAInput from "../Input/MyCIAInput";
import { useTranslation } from "react-i18next";

function CIAForm() {
    const { t } = useTranslation();
    
    return (
        <form method="POST">
            <fieldset className="mt-4">
                <label className="mt-6 inline-block text-sm font-semibold">TELEFONO</label>
                <input type="tel" className="input-mycia-secondary mt-2" required />

                <label className="mt-6 inline-block text-sm font-semibold">EMAIL</label>
                <input type="email" className="input-mycia-secondary mt-2" required />

                <label className="mt-6 inline-block text-sm font-semibold">CONFERMA EMAIL</label>
                <input type="email" className="input-mycia-secondary mt-2" required />

                <label className="mt-6 inline-block text-sm font-semibold">PASSWORD</label>
                <input type="password" className="input-mycia-secondary mt-2" required />

                <p className="mt-5 text-sm font-light text-mycia-red">La password deve avere almeno 8 caratteri, un numero e un simbolo.</p>

                <label className="mt-4 inline-block text-sm font-semibold">CONFERMA PASSWORD</label>
                <input type="password" className="input-mycia-secondary mt-2" required />

                <MyCIAInput
                    id="privacy-responsibility"
                    name="privacy"
                    type="checkbox"
                    className="mt-8"
                    text="Sì"
                    value={true}
                    labelClassName="p-4 w-full"
                    shadow
                    required
                >
                    <p className="text-lg font-light text-mycia-dimDark">
                        Dichiaro di aver preso visione e accetto le{" "}
                        <span className="underline">Responsabilità del ristoratore</span> dei dati inseriti
                    </p>
                </MyCIAInput>

                <MyCIAInput
                    id="privacy-leggi"
                    name="privacy"
                    type="checkbox"
                    text="No"
                    className="mt-10"
                    value={false}
                    labelClassName="p-4 w-full"
                    shadow
                    required
                >
                    <p className="text-lg font-light text-mycia-dimDark">
                        Ai sensi e per gli effetti di cui all'art. 1341 e 1342 c.c. previa attenta lettura di
                        ogni clausola e rilettura <span className="text-mycia-blue underline">leggi tutto</span>
                    </p>
                </MyCIAInput>

                <button type="submit" className="button-mycia w-full mt-16">CREA PROFILO</button>
            </fieldset>
        </form>
    );
}

export default CIAForm;
