import React from "react";

function Orders(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.96 47.07" className={className} onClick={onClick}>
            <defs>
                <linearGradient
                    id="A"
                    x1="64.682"
                    x2="7.342"
                    y1="27.354"
                    y2="27.354"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#cf5448"></stop>
                    <stop offset="1" stopColor="#d16c4b"></stop>
                </linearGradient>
            </defs>
            <path d="M4.6 42.25l.5-3.5a35.1 35.1 0 0126.3-27.97c.97-.22 1.24-.6 1.2-1.55-.09-1.16-.03-2.33.16-3.48A7.357 7.357 0 0141.55.18c3.3.74 5.67 3.63 5.75 7.02.04.79.04 1.57-.01 2.36-.07.73.2 1 .9 1.16 14.43 3.37 25.2 15.41 26.96 30.12l.18 1.3h2c1.36-.09 2.54.94 2.63 2.3s-.94 2.54-2.3 2.63h-.28c-.29.01-.58 0-.88 0H3.36c-.4 0-.82.01-1.22-.03A2.212 2.212 0 010 44.76v-.12c-.03-1.29.99-2.37 2.28-2.4.75-.02 1.51.01 2.31.01zm54.92-.15a2.02 2.02 0 00.06-.34 44.9 44.9 0 00-2.42-13.88 22.557 22.557 0 00-9.03-11.87 5.32 5.32 0 00-1.67-.69c-16.19-3.57-32.27 6.45-36.2 22.55-.32 1.37-.48 2.77-.72 4.23h49.98zM42.34 9.77a21.46 21.46 0 00-.03-3 2.29 2.29 0 00-2.5-1.84 2.36 2.36 0 00-2.3 2c-.09.94-.02 1.9-.02 2.84h4.84z"></path>
            <path
                fill="url(#A)"
                d="M64.71 42.23H7.44l.83-4.58A34.27 34.27 0 0138.8 12.61a36.6 36.6 0 0110.95.61c.67.14 1.32.4 1.91.75 4.83 2.97 8.48 7.51 10.35 12.85 1.75 4.82 2.69 9.9 2.78 15.03-.01.13-.04.25-.08.37zM31.44 20.45a2.265 2.265 0 00-1.35-3.96c-.34-.03-.69 0-1.02.11-.78.26-1.52.63-2.2 1.1a29.912 29.912 0 00-11.73 15.62c-.12.45-.11.93.04 1.38.36.99 1.32 1.62 2.37 1.57 1.15.01 2.16-.76 2.45-1.87 1.92-5.79 5.9-10.67 11.17-13.73a1.79 1.79 0 00.28-.2z"
            ></path>
            <path
                fill="#a83634"
                d="M44.11 12.45h-8.46l.03-4.96A4.128 4.128 0 0139.7 4a3.996 3.996 0 014.36 3.21c.13 1.74.15 3.49.05 5.24z"
            ></path>
            <path d="M30.12 15.9a2.53 2.53 0 012.19 2.82c-.07.59-.35 1.13-.79 1.54-.09.08-.18.15-.28.22a27.246 27.246 0 00-11.64 15.1 2.638 2.638 0 01-2.55 2.06 2.49 2.49 0 01-2.47-1.73 2.68 2.68 0 01-.04-1.51 32.798 32.798 0 0112.23-17.17c.7-.51 1.47-.92 2.29-1.2a2.61 2.61 0 011.07-.13z"></path>
            <path
                fill="#a83634"
                d="M59.19 42.23l12.11-.02a31.92 31.92 0 00-2.66-11.54 37.014 37.014 0 00-11-13.24 30.231 30.231 0 00-19.12-4.9c8.9.56 14.61 8.18 15.91 10.58a52.32 52.32 0 014.76 19.11z"
            ></path>
        </svg>
    );
}

export default Orders;
