import React from "react";

function Pagination(props) {
    const { fromPage, toPage, selectedPage, className, onPageChanged } = props;

    const paginationPages = [];

    const minPageSelected = selectedPage - 1 < fromPage;
    if (minPageSelected) {
        paginationPages.push(fromPage);
        paginationPages.push(fromPage + 1);
        paginationPages.push(fromPage + 2);
        paginationPages.push(null);
        paginationPages.push(toPage);
    } else {
        paginationPages.push(selectedPage - 1);
        paginationPages.push(selectedPage);

        const lastPageCondition = toPage - selectedPage;
        if (lastPageCondition === 2) {
            paginationPages.push(selectedPage + 1);
            paginationPages.push(selectedPage + 2);
        } else if (lastPageCondition === 1) {
            paginationPages.push(selectedPage + 1);
        } else if (lastPageCondition === 0) {
            paginationPages.unshift(selectedPage - 2);
        } else {
            paginationPages.push(selectedPage + 1);
            paginationPages.push(null);
            paginationPages.push(toPage);
        }

        if (selectedPage > toPage / 2) {
            paginationPages.unshift(null);
            paginationPages.unshift(fromPage);
        }
    }

    return (
        <div
            className={`Pagination flex cursor-pointer items-center justify-center gap-x-4 ${className} select-none`}
        >
            {paginationPages.map((item, key) => {
                return item != null ? (
                    <div
                        key={key}
                        className={`flex h-10 w-10 items-center justify-center ${item === selectedPage ? `bg-[#FF805B]` : `border border-[#2B2B2B] bg-white`
                            } rounded-mobile`}
                        onClick={(e) => onPageChanged(item)}
                    >
                        <p className="font-semibold">{item}</p>
                    </div>
                ) : (
                    <span key={key} className="mx-4 font-light">
                        ...
                    </span>
                );
            })}
        </div>
    );
}

export default Pagination;
