import { doAuthPost } from "./api-client";
import { API_DOMAIN } from "./constants";

async function getRestaurants() {
    const payload = new URLSearchParams({
        todo: 'elenco-ristoranti',
        script: 'scripts/step-1.php',
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getBusinessDetail() {
    const payload = new URLSearchParams({
        "todo": "load-ristoratore",
        "script": "scripts/step-1-a.php",
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateBusiness(business) {
    const payload = new URLSearchParams({
        "todo": "modifica-dati-ristoratore",
        "script": "scripts/step-1-a.php",
        "ris_id": "",
        "Piva": business.Piva,
        "Azienda": business.Azienda,
        "Indirizzo": business.Indirizzo,
        "Telefono": business.Telefono,
        "NomeRiferimento": business.NomeRiferimento,
        "Email": business.Email,
        "Password": business.Password,
        "ConfermaPassword": business.ConfermaPassword,
        "Trattamento": business.Trattamento,
        "Clausole": business.Clausole,
        "AccettazioneClausole": business.AccettazioneClausole,
        "vcl": business.vcl,
        "rcl": business.rcl
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

export {
    getRestaurants,
    getBusinessDetail,
    updateBusiness,
}