import React from "react";

function Address(props ) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.03 78.02" className={className} onClick={onClick}>
            <defs>
                <clipPath id="a">
                    <path fill="none" d="M.03 0h75v78.01h-75z"></path>
                </clipPath>
            </defs>
            <path
                d="M.03 39.12V19.26c-.37-4.08 2.63-7.69 6.71-8.06.49-.05.99-.04 1.48.01h22.34V3.38c0-1.86 1.51-3.37 3.37-3.37s3.37 1.51 3.37 3.37v7.83h18.15a8.82 8.82 0 016.94 2.9l9.5 9.05c4.2 4.04 4.12 8.1.03 12.04l-9.53 9.08a8.804 8.804 0 01-6.97 2.87l-18.12.03v24.07H48c1.87.06 3.34 1.62 3.28 3.49A3.376 3.376 0 0148 78.02H19.89a3.386 3.386 0 01-3.28-3.49 3.376 3.376 0 013.28-3.28h10.67V47.18H8.22c-4.07.44-7.74-2.5-8.18-6.57a7.32 7.32 0 01-.01-1.48m55 1.11c.96.11 1.91-.25 2.56-.97l9.25-8.7c.77-.59.91-1.69.32-2.46-.09-.11-.18-.21-.29-.3l-9.3-8.74c-.66-.66-1.58-.99-2.5-.9H9.03a1.76 1.76 0 00-1.9 1.61v18.56c-.07.97.66 1.82 1.63 1.89h46.27z"
                clipPath="url(#a)"
            ></path>
        </svg>
    );
}

export default Address;
