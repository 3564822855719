import React from "react";

function Person(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.8 55.14" className={className} onClick={onClick}>
            <path d="M0 49.8c0-8.35 10.34-18.78 25.9-18.78S51.8 41.45 51.8 49.8c0 3.63-2.55 5.33-7.73 5.33H7.76C2.55 55.13 0 53.43 0 49.8zm44.59-.32c.64 0 .88-.21.88-.7 0-4.22-7.09-12.1-19.57-12.1S6.33 44.56 6.33 48.78c0 .5.23.7.88.7h37.38zM12.95 13.77C12.92 6.18 18.78 0 25.93 0s12.95 6.12 12.95 13.74S33.11 27.8 25.93 27.8s-12.95-6.27-12.98-14.03zm20.07-.03c0-4.57-3.22-8.09-7.09-8.09s-7.15 3.57-7.12 8.12c0 4.75 3.25 8.38 7.12 8.38s7.09-3.69 7.09-8.41z"></path>
        </svg>
    );
}

export default Person;
