function required(description) {
    return function (value) {
        return !value || "" === value.trim() ? description : undefined;
    }
}

function match(re, description) {
    return function (value) {
        return !value.match(re) ? description : undefined;
    }
}

function equal(field, secondField, description) {
    return function (value) {
        return field !== secondField ? description : undefined;
    }
}

function list(...validators) {
    return function (value) {
        return validators.reduce(
            (result, validator) => result || validator(value),
            undefined
        );
    }
}

function validateMany(validators, fields) {
    return Object.entries(fields).reduce(
        (result, [name, value]) => ({
            ...result,
            [name]: validators[name] ? validators[name](value) : undefined,
        }), {});
}

function anyErrors(errors) {
    return Object.values(errors).some(error => undefined !== error);
}

export default {
    required,
    match,
    equal,
    list,
    validateMany,
    anyErrors,
}
