import React, { useState } from 'react';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import DetailForm from '../components/Restaurant/DetailForm/DetailForm';
import ProfileForm from '../components/Restaurant/ProfileForm/ProfileForm';
import TimeForm from '../components/Restaurant/TimeForm/TimeForm';
import { addRestaurant } from '../apis/restaurants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function CreateRestaurant(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [registrationPage, setRegistrationPage] = useState(0);
    const [restaurantData, setRestaurantData] = useState([]);

    const onNextPage = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        setRegistrationPage(registrationPage + 1);
    }

    const onFormCompleted = (data) => {
        return setRestaurantData(restaurantData => ({
            ...restaurantData,
            ...data
        }));
    };
    
    const onSubmitHandler = async (data) => {
        const payload = {
            ...data,
            ...restaurantData
        }

        if (true) {
            const response = await addRestaurant(payload);
            
            if (response && response.rac_token) {
                navigate(-1);
            } else {
                alert(response.message);
            }
        } else {
            alert("Accedi o registrati");
        }
    }

    const pageComponents = () => {
        switch (registrationPage) {
            case 0:
                return <ProfileForm onFormCompleted={onFormCompleted} onSubmitHandler={onNextPage} />;
            case 1:
                return <DetailForm onFormCompleted={onFormCompleted} onSubmitHandler={onNextPage} />;
            case 2:
                return <TimeForm onSubmitHandler={onSubmitHandler} />;
            default:
                return <DetailForm onFormCompleted={onFormCompleted} onSubmitHandler={onNextPage} />;
        }
    };    

    return (
        <div>
            <Header isDividerExist={true} />

            <div className="RegistrationRestaurant px-mobile pb-8">
                <div className="flex items-center my-6 cursor-pointer" onClick={() => navigate(-1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="icon-mycia">
                        <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
                    </svg>

                    <p className="text-lg font-light underline ml-2">I tuoi ristoranti</p>
                </div>

                {pageComponents()}
            </div>

            <Footer />
        </div>
    )
}

export default CreateRestaurant;
