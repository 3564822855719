import React from 'react';

function MenuBodySecond(props) {
    const { name, subItems, isDescriptionEnabled } = props;
    return (
        <div className="MenuBodySecond">
            <p className="w-full mt-4 text-3xl text-mycia-orangeRed font-secondStyle font-bold uppercase">{name}</p>
                                
            <div className="mt-8">
                {
                    subItems.map((it, index) => (
                        <div key={index} className="w-full">
                            <p className="w-full mt-2 font-secondStyle italic text-lg">{it.name}</p>

                            {
                                isDescriptionEnabled && <p className="font-firstStyle font-medium text-start text-sm uppercase">{it.description}</p>
                            }
                            <p className="text-xs font-secondStyle uppercase">{it.ingredients && it.ingredients.map(it => it.name).join(", ")}</p>
                            <p className="mt-2 text-end font-secondStyle">{it.price} €</p>
                            
                            <div className="w-full h-[3px] mt-4 mx-auto border border-dashed border-mycia-dimGray"></div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default MenuBodySecond;