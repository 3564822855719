import React from "react";

function Instruments(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.78 69.59" className={className} onClick={onClick}>
            <path d="M5.41 47.73c3.43-3.4 7.68-5.13 13.77-9.05L3.41 22.89c-4.51-4.54-4.54-9.35-.15-13.77l4.37-4.37C12.03.36 16.89.39 21.4 4.9l15.06 15.12c-1.05-5.42.44-11.13 4.51-15.21 5.6-5.62 14.53-6.42 21.47-1.85l-3.75 3.72-4.37 4.37c-.7.7-.67 1.52.09 2.29l3.05 3.08c.73.7 1.55.67 2.26-.03l4.39-4.34-.03-.03 3.75-3.72c4.54 6.97 3.75 15.91-1.88 21.5-4.98 4.98-12.39 6.09-18.69 3.46a108.28 108.28 0 00-3.22 3.28l11.72 11.69 2.52.38c1.17.21 2.29.91 2.99 1.96l6.24 9.35c.73 1.08.79 2.31-.03 3.13l-5.89 5.89c-.85.85-2.02.85-3.13.09l-9.38-6.27c-1.03-.7-1.79-1.79-1.96-3.02l-.38-2.49-10.96-10.96c-6.74 9.05-8.47 14.74-12.77 19.07-5.07 5.1-12.25 5.21-17.55-.06C.19 60 .3 52.79 5.4 47.72zm18.19-12.1c3.28-2.4 7.03-5.48 11.37-9.64L17.39 8.41c-1.99-1.96-3.96-1.99-5.89-.06l-4.63 4.66c-1.9 1.96-1.93 3.84.09 5.86L23.6 35.63zm16.55-7.73c-19.04 18.49-24.58 17.23-31 23.64-2.9 2.9-3.11 6.83.03 10.02 3.19 3.13 7.15 2.96 10.05.03 6.42-6.39 5.16-11.92 23.64-30.97-.5-.41-1-.85-1.44-1.32-.47-.44-.88-.91-1.29-1.41zM10.24 17.7c-.59-.59-.56-1.58.03-2.17.59-.56 1.58-.56 2.11 0l10.46 10.46c.59.62.59 1.55-.03 2.11-.59.59-1.55.62-2.14 0L10.24 17.7zm.53 38.99c0-1.85 1.52-3.37 3.37-3.37s3.34 1.52 3.34 3.37-1.49 3.34-3.34 3.34-3.37-1.49-3.37-3.34zm3.31-42.83c-.59-.59-.59-1.55.03-2.14.59-.56 1.49-.59 2.08 0l10.46 10.43c.59.59.59 1.55-.03 2.2-.56.53-1.58.59-2.17-.03L14.08 13.86zm45.41 49.63l2.52-2.52L56.77 54l-3.28-.53-13.01-12.98c-.47.56-.94 1.08-1.38 1.61l12.86 12.86.59 3.28 6.94 5.24zM50.38 8.85l3.66-3.63c-3.46-.38-6.94.73-9.43 3.22-4.66 4.63-4.45 12.33.47 17.26 4.92 4.89 12.63 5.13 17.26.5 2.52-2.52 3.6-6.01 3.22-9.49L61.9 20.4c-2.11 2.08-4.92 2.05-7.15-.18l-4.19-4.13c-2.29-2.26-2.34-5.07-.18-7.24z"></path>
        </svg>
    );
}

export default Instruments;
