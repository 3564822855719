import React from "react";
import { useTranslation } from "react-i18next";
import useIP from "../../logic/useIP";

function Footer() {
    const { t } = useTranslation();
    const { isStaff } = useIP();

    return (
        <footer className="Footer pt-10 pb-12 px-6 bg-mycia-whiteSmoke">
            <div className="w-40">
                <img src="/MyCIA-for-Business-logo-arancione.png" alt="logo" />
            </div>

            <div className="mt-10 font-light">
                <a href="mailto:info@cartaidentitalimentare.com">
                    <p>Contattaci</p>
                </a>

                <p className="mt-10 font-semibold">Utenti</p>
                <a href="https://www.cartaidentitalimentare.com/wp-content/uploads/2019/06/Profilo-Utente-guidato.pdf" target="_blank" rel="noopener noreferrer">
                    <p className="mt-2 cursor-pointer">Come funziona</p>
                </a>

                <p className="mt-10 font-semibold">Ristoratori</p>
                <a href="https://www.cartaidentitalimentare.com/wp-content/uploads/2019/06/Profilo-Ristoratore-guidato.pdf" target="_blank" rel="noopener noreferrer">
                    <p className="mt-2 cursor-pointer">Come funziona</p>
                </a>
                <a href="https://www.cartaidentitalimentare.com/wp-content/uploads/2019/06/FAQ-CIA-1.pdf" target="_blank" rel="noopener noreferrer">
                    <p className="mt-2 cursor-pointer">Come registrarsi</p>
                </a>

                <p className="mt-10 font-semibold">Privacy</p>

                {!isStaff && (
                    <a href="https://www.cartaidentitalimentare.com/termini-condizioni-utente/" target="_blank" rel="noopener noreferrer">
                        <p className="mt-2 cursor-pointer">Termini</p>
                    </a>
                )}

                {!isStaff && (
                    <a href="https://www.cartaidentitalimentare.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                        <p className="mt-2 cursor-pointer">Privacy Policy</p>
                    </a>
                )}

                <a href="https://www.cartaidentitalimentare.com/cookie-policy/" target="_blank" rel="noopener noreferrer">
                    <p className="mt-2 cursor-pointer">Cookie Policy</p>
                </a>
            </div>
        </footer>
    );
}

export default Footer;
