import React, { useState } from 'react';
import Modal from 'react-modal';
import { addDishToCategory } from '../../../../apis/modify-restaurant';
import { modalStyle } from '../../../../style';
import Close from '../../../Icon/Close';
import { useTranslation } from "react-i18next";

function DishAdd(props) {
    const { t } = useTranslation();
    const { children, restaurantId, categoryId, onDishCreated } = props;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [name, setName] = useState();

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onCreateDish = async () => {
        const res = await addDishToCategory(restaurantId, categoryId, name);
        if (res.outcome) {
            closeModal();
            onDishCreated();
        }
    }

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="Aggiungi piatto"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Aggiungi piatto</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <input type="text" className="input-mycia-secondary mt-6" placeholder="Nome Piatto" onChange={({target}) => setName(target.value)} />

                    <div className="button-mycia mt-8" onClick={onCreateDish}>CONFERMA</div>
                </Modal>
            )}
        </div>
    );
}

export default DishAdd;