import React from "react";

function QrCode(props) {
    const { className, onClick } = props; 

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.03 75.03" className={className} onClick={onClick}>
            <defs>
                <clipPath id="a">
                    <path fill="none" d="M.02.02h75v75h-75z"></path>
                </clipPath>
            </defs>
            <path
                d="M.02 21.53V11.46C.02 3.9 3.94.02 11.58.02h10.04c1.21-.15 2.31.71 2.46 1.91.02.17.02.34 0 .52.12 1.22-.76 2.31-1.98 2.43-.16.02-.32.02-.49 0h-9.95a6.09 6.09 0 00-6.79 5.3c-.06.52-.06 1.04 0 1.55v9.8c.14 1.22-.73 2.32-1.94 2.47-.16.02-.32.02-.49 0-1.22.12-2.31-.76-2.43-1.98-.02-.16-.02-.32 0-.49m.01 42V53.5a2.191 2.191 0 012.43-2.46c1.22-.12 2.31.76 2.43 1.98.02.16.02.32 0 .49v9.79a6.112 6.112 0 005.26 6.85c.5.07 1.01.07 1.52 0h9.96c1.34 0 2.43 1.09 2.43 2.43s-1.09 2.43-2.43 2.43H11.58C3.94 75.02.02 71.14.02 63.54m18.22-29.51V20.08c0-.97.78-1.76 1.75-1.76h13.97c.95 0 1.73.77 1.73 1.72v13.99c.03.92-.69 1.69-1.61 1.72H20.01c-.94.04-1.73-.69-1.76-1.63v-.09m0 20.93V41.01c0-.97.78-1.76 1.75-1.76h13.97c.95 0 1.73.77 1.73 1.72v13.99c.03.92-.69 1.69-1.61 1.73H20.02c-.94.04-1.73-.69-1.76-1.63v-.09m13.95-33.15h-10.5v10.5h10.5v-10.5zm0 20.92h-10.5v10.5h10.5v-10.5zM24.8 24.87h4.33v4.35H24.8v-4.35zm0 20.93h4.33v4.35H24.8V45.8zm14.34-11.76V20.09c0-.97.78-1.76 1.75-1.76h13.96c.94-.01 1.71.74 1.73 1.68v14.04c.05.91-.65 1.68-1.56 1.72H40.9a1.7 1.7 0 01-1.76-1.64v-.09m.51 5.68h4.39v4.39h-4.39v-4.39zm0 12.07h4.39v4.39h-4.39v-4.39zm13.49-29.98h-10.5v10.5h10.5v-10.5zm-7.45 3.06h4.39v4.35h-4.39v-4.35zm0 20.93h4.35v4.35h-4.35V45.8zm24.49-24.26v-9.8a6.134 6.134 0 00-5.32-6.85c-.5-.06-1-.06-1.5 0h-9.95a2.25 2.25 0 01-2.47-1.98c-.02-.15-.02-.3 0-.45-.12-1.22.76-2.31 1.98-2.43.16-.02.32-.02.49 0h10.07c7.64 0 11.56 3.92 11.56 11.44v10.07c0 1.34-1.09 2.43-2.43 2.43s-2.43-1.09-2.43-2.43M50.94 72.6c-.12-1.22.76-2.31 1.98-2.43.16-.02.32-.02.49 0h9.95c3.38.39 6.43-2.02 6.82-5.4.06-.48.05-.97 0-1.46v-9.8a2.191 2.191 0 012.43-2.46c1.23-.11 2.32.79 2.43 2.02.01.15.01.3 0 .45v10.03c0 7.56-3.92 11.48-11.56 11.48H53.41a2.25 2.25 0 01-2.47-1.98c-.02-.15-.02-.3 0-.45m.78-32.88h4.35v4.39h-4.35v-4.39zm0 12.07h4.35v4.39h-4.35v-4.39z"
                clipPath="url(#a)"
            ></path>
        </svg>
    );
}

export default QrCode;
