import React from "react";

function Pen(props) {
    const { className, onClick } = props;

    return (
        <svg id="Livello_2" className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.41 24">
            <g id="Livello_1-2">
                <path
                    id="Tracciato_399"
                    d="M0,22.95c0-.57,.46-1.04,1.03-1.05H22.36c.58,0,1.04,.48,1.04,1.05,0,.57-.47,1.04-1.04,1.05H1.03C.46,23.98,0,23.52,0,22.95Zm1.14-3.77l1.64-1.7c-.41-.47-.49-1.13-.21-1.68l1.56-3.66c.1-.24,.24-.45,.42-.63L15.33,.8c.82-.96,2.27-1.08,3.23-.26,.1,.08,.19,.18,.27,.28l2.46,2.46c.97,.82,1.09,2.27,.27,3.24-.08,.1-.17,.19-.27,.27l-10.77,10.71c-.17,.19-.38,.33-.62,.42l-3.67,1.54c-.55,.28-1.22,.19-1.68-.22l-.74,.58c-.2,.18-.46,.28-.73,.25H1.53c-.28,.05-.54-.14-.58-.42-.03-.18,.04-.37,.19-.48Zm6.98-2.77l-2.46-2.48-.93,2.15c-.09,.17-.06,.38,.09,.5l.65,.64c.12,.15,.33,.2,.5,.1l2.16-.91Zm1.6-1.05l7.96-7.91-3-3.02-7.97,7.92,3.01,3.01ZM15.99,3.11l3,3.01,.65-.65c.23-.17,.28-.5,.11-.73-.02-.03-.05-.06-.09-.09l-2.19-2.21c-.19-.22-.53-.25-.75-.06-.03,.02-.06,.05-.08,.08l-.65,.65Z"
                />
            </g>
        </svg>
    );
}

export default Pen;
