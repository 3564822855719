import React from "react";

function Delivery(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.95 61.91" className={className} onClick={onClick}>
            <defs>
                <linearGradient
                    id="A"
                    x1="6.094"
                    x2="58.334"
                    y1="30.372"
                    y2="30.372"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#cf5448"></stop>
                    <stop offset="1" stopColor="#d16c4b"></stop>
                </linearGradient>
            </defs>
            <path d="M89.92 37.74l.03 14.94c0 1.54-.42 1.96-1.99 1.96h-6.8l-.2.66c-1.15 4.66-5.87 7.51-10.53 6.36a8.7 8.7 0 01-6.36-6.38c-.13-.52-.33-.65-.82-.65H32.32c-.18 0-.36.02-.6.03a8.798 8.798 0 01-4.05 5.95 8.688 8.688 0 01-5.26 1.27c-3.81-.24-6.71-2.86-7.95-7.25H4.59c-1.35 0-1.82-.47-1.82-1.8V7.09c0-1.4.46-1.86 1.86-1.86h54.41c1.37 0 1.86.51 1.86 1.88v8.29l13.61-.03a2.73 2.73 0 012.64 1.45l12.23 19.12c.35.54.53 1.17.53 1.81zm-2.87 7.43v-6.89c0-1-.06-1.05-1.05-1.05H65.78c-1.87 0-1.92-.7-1.92-3.23v-9.72c0-2.37.07-3.03 1.83-3.04h10.8l-1.48-2.34a1.03 1.03 0 00-1.05-.58l-12.32.01c-.22 0-.45.03-.69.04v33.37c.77 0 1.5-.03 2.21.01.56.03.78-.15.93-.73 1.12-4.54 5.66-7.36 10.23-6.33 3.27.63 5.86 3.11 6.63 6.34.07.25.29.67.44.67 1.85.05 3.7.03 5.6.03v-2.89h-2.78a1.464 1.464 0 11-.01-2.93h2.81c0-.28.03-.52.03-.74zm-20.31-10.9h18.07l-.12-.3-6.08-9.43a1.15 1.15 0 00-.88-.41l-10.3-.02c-.23 0-.45.03-.69.05v10.1zM78.3 53.58a5.82 5.82 0 00-5.4-6.2 5.82 5.82 0 00-6.2 5.4 5.82 5.82 0 005.4 6.2 4.82 4.82 0 00.76 0 5.813 5.813 0 005.44-5.4zm-20.37-1.84v-2.9H34.79a1.465 1.465 0 010-2.93h23.13V13.92H10.04a1.451 1.451 0 010-2.9h47.87V8.17H5.71v37.78h5.68c.81 0 1.46.65 1.46 1.46a1.46 1.46 0 01-.43 1.04 1.42 1.42 0 01-1.03.43H5.71v2.87l7.92.01c.5.1.98-.23 1.07-.72 0-.04.01-.07.01-.11 1.23-4.63 5.98-7.38 10.61-6.15a8.69 8.69 0 016.15 6.15.92.92 0 00.99.84c.03 0 .07 0 .1-.01l24.49-.01h.87zm-34.84 7.24c3.21 0 5.81-2.59 5.82-5.8 0-3.21-2.59-5.81-5.8-5.82-3.21 0-5.81 2.59-5.82 5.8v.02c0 3.19 2.57 5.79 5.77 5.8h.03z"></path>
            <path
                fill="#a83634"
                d="M87.04 38.27v6.89c0 .23-.03.45-.04.74h-2.8c-.81 0-1.47.66-1.46 1.47a1.46 1.46 0 001.46 1.46h2.78v2.89c-1.89 0-3.74.01-5.6-.03-.16 0-.37-.42-.44-.67a8.467 8.467 0 00-6.63-6.34c-4.56-1.02-9.11 1.79-10.23 6.33-.15.58-.36.76-.93.73-.72-.04-1.44-.01-2.21-.01V18.36c.24-.01.47-.04.69-.04l12.32-.01c.44-.05.86.19 1.05.58.43.75.92 1.47 1.48 2.34h-10.8c-1.76 0-1.83.67-1.83 3.04v9.72c0 2.54.05 3.23 1.91 3.23h20.22c1 0 1.06.06 1.06 1.05z"
            ></path>
            <path
                fill="#ebebeb"
                d="M84.81 34.27H66.74V24.16l.69-.05 10.3.02c.34 0 .66.16.88.41l6.08 9.43c.05.1.09.2.12.3z"
            ></path>
            <path
                fill="#c5c5c5"
                d="M72.53 47.37c3.2.02 5.78 2.63 5.76 5.84a5.798 5.798 0 01-5.84 5.76 5.796 5.796 0 01-5.76-5.8c0-3.21 2.62-5.81 5.83-5.8zm2.07 5.85v-.03c0-1.15-.93-2.09-2.09-2.09s-2.09.93-2.09 2.09v.03c0 1.15.93 2.09 2.09 2.09s2.09-.93 2.09-2.09z"
            ></path>
            <path
                fill="#4d4d4d"
                d="M74.6 53.19v.03c0 1.15-.93 2.09-2.09 2.09s-2.09-.93-2.09-2.09v-.03c0-1.15.93-2.09 2.09-2.09s2.09.93 2.09 2.09z"
            ></path>
            <path
                fill="url(#A)"
                d="M57.93 48.84v2.9h-.88l-24.49.01c-.5.1-.98-.23-1.07-.73 0-.03-.01-.06-.01-.1-1.23-4.63-5.98-7.38-10.61-6.15a8.69 8.69 0 00-6.15 6.15.91.91 0 01-.98.84c-.04 0-.07 0-.11-.01l-7.92-.01v-2.87h5.69a1.46 1.46 0 001.46-1.46c0-.81-.65-1.46-1.46-1.46H5.7V8.18h52.2v2.85H10.04a1.451 1.451 0 000 2.9h47.88v31.99H34.78a1.465 1.465 0 000 2.93h23.15z"
            ></path>
            <path
                fill="#c5c5c5"
                d="M28.9 53.19c0 3.2-2.61 5.79-5.82 5.78s-5.79-2.61-5.78-5.82c0-3.19 2.6-5.77 5.79-5.78a5.78 5.78 0 015.81 5.75v.06zm-3.71 0a2.09 2.09 0 10-4.18 0c0 1.16.93 2.09 2.09 2.09 1.15 0 2.09-.94 2.09-2.09z"
            ></path>
            <path
                fill="#a83634"
                d="M7.1 0h17.44c.78 0 1.41.63 1.41 1.41 0 .78-.63 1.41-1.41 1.41H7.1c-.78 0-1.41-.63-1.42-1.41C5.69.63 6.32 0 7.1 0z"
            ></path>
            <rect
                width="4.19"
                height="4.18"
                x="21.01"
                y="51.09"
                fill="#4d4d4d"
                rx="2.09"
            ></rect>
            <path
                fill="#d03732"
                d="M2.79 1.41v.03c.08.77-.47 1.46-1.24 1.54A1.41 1.41 0 010 1.74v-.33C.02.63.65.01 1.43.02c.36 0 .7.15.96.4.26.26.41.62.41.99z"
            ></path>
        </svg>
    );
}

export default Delivery;
