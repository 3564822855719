import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteWine, setVisibilityWine } from '../../../apis/modify-restaurant';
import Delete from '../../Icon/Delete';
import Restore from '../../Icon/Restore';
import { useTranslation } from "react-i18next";

function ItemWineMenuArchieve(props) {
    const { t } = useTranslation();
    const { restaurantId } = useParams();
    const { wineId, wine, onDelete } = props;

    const onDeleteDish = async () => {
        const res = await deleteWine(restaurantId, wineId);
        if (res.outcome) onDelete(wineId);
    }

    const onRestoreDish = async () => {
        const res = await setVisibilityWine(restaurantId, wineId, 1);
        if (res.outcome) onDelete(wineId);
    }

    return (
        <div className="ItemDishMenuArchieve">
            <div className="flex items-center justify-between border-b border-mycia-dimGray px-mobile py-4">
                <div className="flex items-center">
                    <Delete className="w-4 h-4" onClick={onDeleteDish} />

                    <p className="ml-4 font-light">{wine.name}</p>
                </div>

                <div className="flex items-center cursor-pointer" onClick={onRestoreDish}>
                    <p className="font-semibold text-mycia-orangeRed underline">Ripristina</p>

                    <Restore className="ml-4 w-4 h-4 fill-mycia-orangeRed"/>
                </div>
            </div>
        </div>
    )
}

export default ItemWineMenuArchieve;
