import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkDeliveryServices, updateServices } from "../../../apis/restaurants";
import Orders from "../../Icon/Orders";
import Delivery from "../../Icon/Delivery";
import Takeaway from "../../Icon/Takeaway";
import Booking from "../../Icon/Booking";
import ItemLocal from "./ItemLocal";
import { useCallback } from "react";

const initialState = [
    {
        id: "table_service",
        state: false,
        label: "Ordini al tavolo",
        icon: <Orders className="w-8 h-8" />,
        customizePath: "customize/local",
        todo: "update-status-tables",
        visibilityField: "isOrderAndPayEnabled",
        visibility: false,
    },
    {
        id: "delivery",
        state: false,
        label: "Delivery",
        icon: <Delivery className="w-8 h-8" />,
        customizePath: "customize/delivery",
        todo: "update-status-delivery",
        visibilityField: "isOrderAndPayEnabled",
        visibility: false,
    },
    {
        id: "takeaway",
        state: false,
        label: "Takeaway",
        icon: <Takeaway className="w-6 h-6" />,
        customizePath: "customize/takeaway",
        todo: "update-status-takeaway",
        visibilityField: "isOrderAndPayEnabled",
        visibility: false,
    },
    {
        id: "booking",
        state: false,
        label: "Prenotazioni",
        icon: <Booking className="w-6 h-6" />,
        customizePath: "customize/booking",
        todo: "update-status-booking",
        visibilityField: "isBookingEnabled",
        visibility: false,
    },
];

function LocalService() {
    const navigate = useNavigate();
    const { restaurantId } = useParams();

    const [deliveryServices, setDeliveryServices] = useState(initialState);
    const loadRestaurant = useCallback(async () => {
        const res = await checkDeliveryServices(restaurantId);
        if (res.outcome) {
            const data = initialState.map((it) => ({
                ...it,
                state: res.response[it.id],
                visibility: res[it.visibilityField] === true,
            }));
            setDeliveryServices(data);
        }
    }, [restaurantId]);

    useEffect(() => {
        loadRestaurant();
    }, [loadRestaurant]);

    const onChange = async (todo, toggle) => {
        const res = await updateServices(restaurantId, todo, toggle);
        if (res.outcome) loadRestaurant();
        else alert("Devi completare la personalizzazione del serivizio prima di attivarlo");
    };

    const onClickCustomize = (path) => {
        if (path) navigate(path);
    };

    return (
        <div className="LocalService flex flex-col gap-y-4">
            {deliveryServices
                .filter((it) => it.visibility === true)
                .map((item, key) => (
                    <ItemLocal key={key} item={item} onClickCustomize={() => onClickCustomize(item.customizePath)} onChange={onChange} />
                ))}
        </div>
    );
}

export default LocalService;
