import React from "react";

function DropdownItem(props) {
    const { label, icon, onClick } = props;
    return (
        <div className="flex items-center" onClick={onClick}>
            {icon}

            <p className="ml-6 text-lg font-semibold normal-case underline">{label}</p>
        </div>
    );
}

export default DropdownItem;
