import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Insight from "../components/Restaurant/Insight";

function RestaurantInsights(props) {
    return (
        <div>
            <Header isDividerExist={true} isTopBarEnabled={true} />

            <Insight />

            <Footer />
        </div>
    );
}

export default RestaurantInsights;
