import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { createCategoryAddIngredient, createCategoryAddMore, deleteAddMore, editCategoryAdditions } from '../../../apis/modify-restaurant';
import Delete from '../../Icon/Delete';
import ItemAddIngredient from "./ItemAddIngredient";
import ItemAddMore from "./ItemAddMore";
import { useTranslation } from "react-i18next";

function ItemAddCategory(props) {
    const { t } = useTranslation();
    const { restaurantId } = useParams();
    const { dishId, category, onDeleteCategory } = props;

    const [categoryState, setCategoryState] = useState(category);
    const [catObligatorState, setCatObligatorState] = useState(!!category.scelte_obbligatorie);
    const [maxNumberState, setMaxNumberState] = useState(!!category.max_scelte_attive);

    const onBlurHandler = async ({target}) => await editCategoryAdditions(restaurantId, dishId, category.id, target.name, target.value);
    const onChangeHandler = ({ target }) => {
        setCategoryState(categoryState => ({
            ...categoryState,
            [target.name]: target.value
        }));
    }

    const onChangeHandlerObligator = async ({ target }) => {
        const res = await editCategoryAdditions(restaurantId, dishId, category.id, target.name, +!(catObligatorState));
        if (res) setCatObligatorState(!catObligatorState);
    }

    const onChangeHandlerMaxNumberActive = async ({ target }) => {
        const res = await editCategoryAdditions(restaurantId, dishId, category.id, target.name, +!(maxNumberState));
        if (res) setMaxNumberState(!maxNumberState);
    }

    const onDeleteAdd = async (id, key) => {
        let res = {};
        if (id) res = await deleteAddMore(restaurantId, id);
        if (!id || res.outcome) {
            const temp = categoryState.aggiunte;
            setCategoryState(categoryState => ({
                ...categoryState,
                "aggiunte": temp.filter((it, index) => index !== key)
            }));
        }
    }

    const onAddIngredientOptional = () => {
        const temp = categoryState.aggiunte;
        temp.push({
            "idcat": categoryState.id,
            "pva_id": null,
            "ali_nome": null,
            "nome_aggiunta": null,
            "aggiunta_alimento_id": null,
            "aggiunta_prezzo": "null",
            "aggiunta_type": 1,
            "active": 1,
            "pos_id": null
        });

        setCategoryState(categoryState => ({
            ...categoryState,
            "aggiunte": temp
        }));
    }

    const onAddMore = () => {
        const temp = categoryState.aggiunte || [];
        temp.push({
            "idcat": categoryState.id,
            "pva_id": null,
            "ali_nome": null,
            "nome_aggiunta": null,
            "aggiunta_alimento_id": null,
            "aggiunta_prezzo": "null",
            "aggiunta_type": 2,
            "active": 1,
            "pos_id": null
        });

        setCategoryState(categoryState => ({
            ...categoryState,
            "aggiunte": temp
        }));
    }

    const onCreateAddMore = async (key, name) => {
        const tempVariations = categoryState.aggiunte;

        const res = await createCategoryAddIngredient(restaurantId, categoryState.pia_id, categoryState.id, name);

        tempVariations[key].nome_aggiunta = name;
        tempVariations[key].pva_id = res.pva_id;
        
        if (res.outcome) {
            setCategoryState(categoryState => ({
                ...categoryState,
                "aggiunta": tempVariations
            }));
        }
    }

    const onCreateIngredientOptional = async (key, id) => {
        const tempVariations = categoryState.aggiunte;

        const res = await createCategoryAddMore(restaurantId, categoryState.pia_id, categoryState.id, id);

        tempVariations[key].aggiunta_alimento_id = id;
        tempVariations[key].pva_id = res.pva_id;

        if (res.outcome) {
            setCategoryState(categoryState => ({
                ...categoryState,
                "aggiunta": tempVariations
            }));
        }
    }

    return (
        <div className="bg-mycia-whiteSmoke py-10 px-mobile">
            <div className={`flex w-full items-center gap-x-4`}>
                <Delete onClick={() => onDeleteCategory(categoryState.id)} className="ml-3 h-3.5 w-3.5 cursor-pointer fill-black" />

                <input type="text" name="nome" value={categoryState.nome} onChange={onChangeHandler} onBlur={onBlurHandler} placeholder="Aggiungi ingrediente" className="input-mycia flex-1 valid:bg-white" />
            </div>

            <div className="mt-2 py-3">
                <label htmlFor={`toggle-oblig-${categoryState.id}`} className="w-full relative inline-flex justify-between cursor-pointer items-center">
                    <span className="font-semibold">Categoria obbligatoria per l'ordine</span>
                    <input
                        id={`toggle-oblig-${categoryState.id}`}
                        name="scelte_obbligatorie"
                        type="checkbox"
                        className="peer sr-only"
                        checked={catObligatorState}
                        onChange={onChangeHandlerObligator} />
                    <div className="peer toggle-mycia"></div>
                </label>
            </div>

            <div className="mt-2 py-3">
                <label htmlFor={`toggle-max-${categoryState.id}`} className="w-full relative inline-flex justify-between cursor-pointer items-center">
                    <span className="font-semibold flex-">Numero di scelte massime</span>
                    
                    <div className="flex items-center">
                        <input name="max_scelte" type="number" onChange={onChangeHandler} value={categoryState.max_scelte} onBlur={onBlurHandler} placeholder="Aggiungi ingrediente" className="input-mycia w-[6ch] mx-0.5 valid:bg-white" />
                        <input
                            id={`toggle-max-${categoryState.id}`}
                            name="max_scelte_attive"
                            type="checkbox"
                            className="peer sr-only"
                            checked={maxNumberState}
                            onChange={onChangeHandlerMaxNumberActive} />
                        <div className="peer toggle-mycia"></div>
                    </div>
                </label>
            </div>

            <div className="flex cursor-pointer items-center justify-center py-4" onClick={onAddIngredientOptional}>
                <p className="text-center font-semibold text-mycia-red underline">
                    AGGIUNGI INGREDIENTE OPZIONALE
                </p>

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="icon-mycia ml-2 fill-mycia-red"
                >
                    <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
                </svg>
            </div>

            <div className="mt-2 flex cursor-pointer items-center justify-center py-4" onClick={onAddMore}>
                <p className="font-semibold text-mycia-red underline">AGGIUNGI ALTRO</p>

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="icon-mycia ml-4 fill-mycia-red"
                >
                    <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
                </svg>
            </div>

            <div className="mt-4 flex flex-col gap-y-5">
                {
                    categoryState.aggiunte && categoryState.aggiunte.map((item, key) => {
                        if (item.aggiunta_type === 1) {
                            return <ItemAddIngredient key={key} dishId={categoryState.pia_id} item={item} index={key} isDeletable={true} onDelete={onDeleteAdd} onCreate={onCreateIngredientOptional} />
                        } else {
                            return <ItemAddMore key={key} dishId={categoryState.pia_id} item={item} index={key} isDeletable={true} onDelete={onDeleteAdd} onCreate={onCreateAddMore} />
                        }
                    })
                }
            </div>
        </div>
    )
}

export default ItemAddCategory;
