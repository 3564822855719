import React from "react";

function Archieve(props) {
    const { className, onClick } = props; 

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.3 63.74" className={className} onClick={onClick}>
            <path d="M4.96 52.58V20.66C1.83 19.58 0 16.75 0 12.94V8.19C0 3.24 3.17 0 7.99 0h53.36c4.96 0 7.96 3.24 7.96 8.19v4.75c0 3.81-1.79 6.64-4.92 7.72v31.92c0 7.21-4.04 11.16-11.22 11.16H16.18c-7.21 0-11.22-3.94-11.22-11.16zm55.17-38.46c1.42 0 2.09-.71 2.09-2.12V9.13c0-1.42-.67-2.12-2.09-2.12H9.2c-1.42 0-2.09.71-2.09 2.12V12c0 1.42.67 2.12 2.09 2.12h50.93zm-7.62 42.1c2.66 0 4.15-1.35 4.15-4.11V21.13H12.64v30.98c0 2.76 1.45 4.11 4.15 4.11h35.73zM21 31.96v-1.08c0-1.82 1.25-3.07 3.03-3.07h21.3c1.75 0 3.03 1.25 3.03 3.07v1.08c0 1.79-1.28 3.07-3.03 3.07h-21.3c-1.75 0-3.03-1.28-3.03-3.07z"></path>
        </svg>
    );
}

export default Archieve;
