import { API_BASE_DOMAIN } from "../apis/constants";

const TYPE_ENUM = {
    NONE: 0,
    DISH: 1,
    WINE: 2
}

const PRINT_ENUM = {
    ORDER_AT_TABLE: "r",
    DELIVERY: "d"
}

function mapArrayToString(data) {
    let out = ""; 
    if (data) out = data.map(item => Object.values(item).join("")).join(", ");
    return out;
}

function mapNumberStr(num) {
    if (num && !isNaN(num)) {
        return num.toString();
    } else {
        return "0.0";
    }
}

function mapPrice(priceId) {
    let priceStr = "";

    switch (priceId) {
        case 1:
            priceStr = "€ - Economico";
            break;
        case 2:
            priceStr = "€€ - Medio";
            break;
        case 3:
            priceStr = "€€€ - Medio - Alto";
            break;
        case 4:
            priceStr = "€€€€ - Raffinato";
            break;
        default:
            break;
    }

    return priceStr;
}

function mapDishes(data) {
    return data.map(item => (
        {
            id: item.pia_id,
            name: item.pia_nome,
            type: item.piatto_per,
            price: Number(item.pia_prezzo).toFixed(2),
            visibility: item.pia_visible,
            order: item.pia_weight
        }
    ))
}

function mapDishCategories(catData) {
    return catData && catData.map(item => (
        {
            id: item.id,
            name: item.nome,
            type: TYPE_ENUM.DISH, // Dish
            items: mapDishes(item.piatti),
            itemsCount: item.all_piatti,
            order: item.weight
        }
    ))
}

function mapWines(data) {
    return data.map(item => (
        {
            id: item.rvi_id,
            name: item.rvi_nome,
            type: item.bevande_per,
            price: Number(item.rvi_prezzo).toFixed(2),
            visibility: item.rvi_visible,
            order: item.rvi_weight
        }
    ))
}

function mapWineCategories(catData) {
    return catData && catData.map(item => (
        {
            name: item.nome,
            type: TYPE_ENUM.WINE,
            items: mapWines(item.vini),
        }
    ))
}

function mapWeekDays(days) {
    const listOfDays = [
        {
            name: "MON",
            day: "Lunedì",
            time: ""
        },
        {
            name: "TUE",
            day: "Martedì",
            time: ""
        },
        {
            name: "WED",
            day: "Mercoledi",
            time: ""
        },
        {
            name: "THU",
            day: "Giovedì",
            time: ""
        },
        {
            name: "FRI",
            day: "Venerdì",
            time: ""
        },
        {
            name: "SAT",
            day: "Sabato",
            time: ""
        },
        {
            name: "SUN",
            day: "Domenica",
            time: ""
        }
    ];

    if (days && days.length !== 0) {
        let hours = [], dayStrTemp = days[0].day_of_week;

        // Last iteration
        days.push({
            day_of_week: "",
            open: "",
            close: ""
        })

        // Iterate a list
        days.forEach(item => {
            if (dayStrTemp !== item.day_of_week) {
                if (hours.length !== 0) {
                    listOfDays[listOfDays.findIndex(it => it.name === dayStrTemp)].time = hours.join(" | ") 
                    hours = []
                } else {
                    listOfDays[listOfDays.findIndex(it => it.name === dayStrTemp)].time = ""; 
                }

                // Keep temp day
                dayStrTemp = item.day_of_week;
            }
            if (item.open !== "" || item.close !== "") {
                hours.push(`${item.open}-${item.close}`);
            }
        });
    }

    return listOfDays;
}

function sortNumeric(a, b) {
    if(a === Infinity) 
        return 1; 
    else if(isNaN(a)) 
        return -1;
    else 
        return a - b;
}

function buildPublicImageURL(name) {
    return `${API_BASE_DOMAIN}/images/logos/${name}`
}

function buildPublicAssetsUrl(name) {
    return `${API_BASE_DOMAIN}/assets/foto/${name}`
}

function mapStrToNumberPrice(price) {
    const number = parseFloat(price);
    return (!isNaN(number) && number.toFixed(2)) || 0;
}

function buildContactlessMenuURL(type, token) {
    return `https://mycontactlessmenu.mycia.it/${type}/q/${token}`
}

export {
    TYPE_ENUM,
    PRINT_ENUM,
    mapArrayToString,
    mapNumberStr,
    mapPrice,
    mapDishes,
    mapDishCategories,
    mapWines,
    mapWineCategories,
    mapWeekDays,
    sortNumeric,
    buildPublicImageURL,
    buildPublicAssetsUrl,
    mapStrToNumberPrice,
    buildContactlessMenuURL,
}
