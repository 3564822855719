import { Route, Routes } from "react-router-dom";
import Businesses from "../pages/Businesses";
import Homepage from "../pages/Homepage";
import RestaurantModify from "../pages/RestaurantModify";
import MenuServices from "../pages/MenuServices";
import AccountModify from "../pages/AccountModify";
import RestaurantMenuModify from "../pages/RestaurantMenuModify";
import RegistrationAccount from "../pages/RegistrationAccount";
import RegistrationCIA from "../pages/RegistrationCIA";
import RestaurantDetail from "../pages/RestaurantDetail";
import RestaurantInsights from "../pages/RestaurantInsights";
import RestaurantReviews from "../pages/RestaurantReviews";
import CreateRestaurant from "../pages/CreateRestaurant";
import RecoverPassword from "../pages/RecoverPassword";
import RestaurantTastingMenu from "../pages/RestaurantTastingMenu";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import CustomizeLocalOrder from "../pages/CustomizeLocalOrder";
import CustomizeDelivery from "../pages/CustomizeDelivery";
import CustomizeTakeAway from "../pages/CustomizeTakeAway";
import CustomizeBooking from "../pages/CustomizeBooking";
import DishMenuPrint from "../pages/DishMenuPrint";
import DrinkMenuPrint from "../pages/DrinkMenuPrint";

function AppRoutes() {
    return (
        <Routes>
            {/** Public Routes */}
            <Route path="/" element={<PublicRoutes />}>
                <Route path="/" element={<Homepage />} />
                <Route path="/forgot-password" element={<RecoverPassword />} />
                <Route path="/registration/account" element={<RegistrationAccount />} />
                <Route path="/registration/cia" element={<RegistrationCIA />} />
            </Route>

            {/** Protected Routes */}
            <Route path="/" element={<ProtectedRoutes />}>
                <Route path="/businesses" element={<Businesses />} />
                <Route path="/account/modify" element={<AccountModify />} />
                <Route path="/restaurant/:restaurantId/services/:token" element={<MenuServices />} />
                <Route path="/restaurant/:restaurantId/services/:token/customize/local" element={<CustomizeLocalOrder />} />
                <Route path="/restaurant/:restaurantId/services/:token/customize/delivery" element={<CustomizeDelivery />} />
                <Route path="/restaurant/:restaurantId/services/:token/customize/takeaway" element={<CustomizeTakeAway />} />
                <Route path="/restaurant/:restaurantId/services/:token/customize/booking" element={<CustomizeBooking />} />
                <Route path="/restaurant/:restaurantId/menu" element={<RestaurantMenuModify />} />
                <Route path="/restaurant/:restaurantId/menu/tasting" element={<RestaurantTastingMenu />} />
                <Route path="/restaurant/:restaurantId/menu/:token/dish" element={<DishMenuPrint />} />
                <Route path="/restaurant/:restaurantId/menu/:token/drink" element={<DrinkMenuPrint />} />
                <Route path="/restaurant/:restaurantId/detail" element={<RestaurantDetail />} />
                <Route path="/restaurant/:restaurantId/modify" element={<RestaurantModify />} />
                <Route path="/restaurant/insights" element={<RestaurantInsights />} />
                <Route path="/restaurant/reviews" element={<RestaurantReviews />} />
                <Route path="/restaurant/create" element={<CreateRestaurant />} />
            </Route>
        </Routes>
    );
}

export default AppRoutes;
