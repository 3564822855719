import { doAuthPost, doFilePost } from "./api-client";
import { API_BASE_DOMAIN, API_DOMAIN } from "./constants";

async function getWineCategories(resId, isArchieve = 0, wineId = "") {
    const payload = new URLSearchParams({
        todo: 'elenco-vini',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        archivio: isArchieve,
        id_vino: wineId,
    }); 

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getPackageStatus(resId) {
    const payload = new URLSearchParams({
        todo: 'check-pacchetto-attivo',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getLanguagesList(resId) {
    const payload = new URLSearchParams({
        todo: 'elenco-lingue',
        script: 'scripts/step-5-a-traduzioni.php',
        ris_id: resId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getTranslationsList(resId, dishId, dishCategoryId = "", lang) {
    const payload = new URLSearchParams({
        todo: "get-traduzione-nome-piatto",
        script: "scripts/step-5-a-traduzioni.php",
        ris_id: resId,
        piatto_id: dishId,
        portata_id: dishCategoryId,
        lang: lang,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateNameTranslation(resId, dishId, dishCategoryId = "", name, lang) {
    const payload = new URLSearchParams({
        todo: "change-traduzione-nome-piatto",
        script: "scripts/step-5-a-traduzioni.php",
        ris_id: resId,
        piatto_id: dishId,
        portata_id: dishCategoryId,
        autotraduzione_lemma: name,
        lang: lang,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getMenuList(resId, isArchieve = "") {
    const payload = new URLSearchParams({
        todo: 'portate',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        archivio: isArchieve,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getDishCategories(resId, isArchieve = 0) {
    const payload = new URLSearchParams({
        todo: 'get-select-dishes',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        archivio: isArchieve,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getDishesByMenuId(resId, menuId, isArchieve = 0) {
    const payload = new URLSearchParams({
        todo: 'elenco-piatti',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        portata_id: menuId,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        archivio: isArchieve,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateCostOfCover(resId, coverCost) {
    const payload = new URLSearchParams({
        todo: 'edit-coperto',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        coperto: coverCost,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateMenuDescription(resId, description) {
    const payload = new URLSearchParams({
        todo: 'edit-descrizione-menu',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        descrizione_menu: description,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateNameDish(restaurantId, dishId, dishName, staff = "") {
    const payload = new URLSearchParams({
        todo: 'edit-nome-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: restaurantId,
        piatto_id: dishId,
        piatto_nome: dishName,
        staff: staff
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updatePriceDish(restaurantId, dishId, dishPrice, staff = "") {
    const payload = new URLSearchParams({
        todo: 'edit-prezzo-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: restaurantId,
        piatto_id: dishId,
        piatto_prezzo: dishPrice,
        staff: staff
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateDescriptionDish(restaurantId, dishId, description) {
    const payload = new URLSearchParams({
        todo: 'edit-descrizione-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: restaurantId,
        piatto_id: dishId,
        descrizione: description
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteIngredient(restaurantId, dishId, ingredientId) {
    const payload = new URLSearchParams({
        todo: 'delete-ingrediente',
        script: 'scripts/step-5-a.php',
        ris_id: restaurantId,
        piatto_id: dishId,
        ingrediente_id: ingredientId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function addDishCategory(resId, categoryName) {
    const payload = new URLSearchParams({
        todo: 'add-portata',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        portata_nome: categoryName,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateOrderCategory(resId, categoryId, weight, dir = null) {
    const payload = new URLSearchParams({
        todo: 'swap-course',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        portata_id: categoryId,
        weight: weight,
        dir: dir, // up && down
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));    
}

async function addDishToCategory(resId, categoryId, dishName) {
    const payload = new URLSearchParams({
        todo: 'add-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        portata_id: categoryId,
        piatto_nome: dishName,
        staff: "",
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateDishMealsOfDay(resId, dishId, mealsOfDay = 1) {
    const payload = new URLSearchParams({
        todo: 'toggle-piatto-per',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        piatto_per: mealsOfDay,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateOrderDish(resId, categoryId, dishId, startWeight, endWeight, isArchieve = null) {
    const payload = new URLSearchParams({
        todo: 'reorder-dish-range',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        portata_id: categoryId,
        startWeight: startWeight,
        endWeight: endWeight,
        pia_id: dishId,
        archivio: isArchieve,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));    
}

async function getIngredientsOfComposed(resId, dishId, composedId) {
    const payload = new URLSearchParams({
        todo: 'ingredienti-composto',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        composto_id: composedId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));   
}

async function getIngredientsByName(resId, name, semplici = null) {
    const payload = new URLSearchParams({
        script: 'alimenti/alimenti.php',
        ris_id: resId,
        search: name,
        page: 1,
    });

    if (semplici) payload.append("semplici", semplici)

    return (await doAuthPost(`${API_DOMAIN}`, payload));   
}

async function updateIngredientToDish(resId, dishId, ingredients) {
    const payload = new URLSearchParams({
        todo: 'edit-ingredienti-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
    });

    ingredients.forEach(element => {
        payload.append("ingredienti[]", element);
    });

   return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateIngredientToComposed(resId, dishId, composedId, ingredients) {
    const payload = new URLSearchParams({
        todo: 'composto-personalizzato-update',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        composto_id: composedId
    });

    ingredients.forEach(element => {
        payload.append("composto_ingredienti[]", element);
    });

   return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateDishCharacteristic(resId, dishId, type, toggle) {
    const payload = new URLSearchParams({
        todo: 'toggle-caratteristica-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        caratteristica: type, // Crudista / Biologico / Senza Glutine
        toggle: toggle ? 1 : 0
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateDishContains(resId, dishId, type, toggle) {
    const payload = new URLSearchParams({
        todo: `toggle-${type}`,
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        [type]: toggle ? 1 : 0, // Congelati / Solfiti
        toggle: toggle ? 1 : 0
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateDishFrozen(resId, dishId, type) {
    const payload = new URLSearchParams({
        todo: 'toggle-congelati',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        congelati: type, // Crudista / Biologico / Senza Glutine
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

// Abbina un vino/bevanda al piatto
async function getDrinkByName(resId, name, page = 1) {
    const payload = new URLSearchParams({
        todo: 'get-bevande-consigliate',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        search: name,
        page: page,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setConsignedDrink(resId, dishId, drinkId = null) {
    const payload = new URLSearchParams({
        todo: 'set-bevanda-consigliata',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pia_id: dishId,
        rvi_id: drinkId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateContaminatingIngredient(resId, dishId, contaminants = []) {
    const payload = new URLSearchParams({
        todo: 'edit-contaminanti-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
    });

    contaminants.forEach(element => {
        payload.append("contaminanti[]", element)
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getLanguageDescription(resId, dishId, lang = "it") {
    const payload = new URLSearchParams({
        todo: 'get-traduzione-descrizione-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pia_id: dishId,
        lang: lang,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function modifyLanguageDescription(resId, dishId, lang = "it", description = "") {
    const payload = new URLSearchParams({
        todo: 'modifica-traduzione-descrizione',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pde_id: dishId, // TODO: What is this? 
        description: description,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function addLanguageDescription(resId, dishId, lang = "it", description = "") {
    const payload = new URLSearchParams({
        todo: 'aggiungi-traduzione-descrizione',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pia_id: dishId, 
        lang: lang,
        description: description,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function addVariation(resId, dishId, variationName) {
    const payload = new URLSearchParams({
        todo: 'add-variazione',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        pva_id: "",
        variazione_nome: variationName,
        variazione_prezzo: "",
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function modifyVariation(resId, dishId, categoryId, variationName, variationPrice) {
    const payload = new URLSearchParams({
        todo: 'edit-variazione',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        pva_id: categoryId,
        variazione_nome: variationName,
        variazione_prezzo: variationPrice,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteVariation(resId, variationId) {
    const payload = new URLSearchParams({
        todo: 'elimina-variazione',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pva_id: variationId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function toggleVariation(resId, categoryId, isActive = 1) {
    const payload = new URLSearchParams({
        todo: 'toggle-pva',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pva_id: categoryId,
        toggle: isActive,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function toggleConsignment(resId, dishId, isActive = 1) {
    const payload = new URLSearchParams({
        todo: 'update-special',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pia_id: dishId,
        action: (isActive ? 'update' : 'remove' ), 
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteDishCategory(resId, dishId, categoryId) {
    const payload = new URLSearchParams({
        todo: 'delete-categoria-aggiunte',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pia_id: dishId,
        cva_id: categoryId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function addCategoryAdditions(resId, dishId, name = "") {
    const payload = new URLSearchParams({
        todo: 'add-categoria-aggiunte',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pia_id: dishId,
        nome: name,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteCategoryAdditions(resId, dishId, cvaId) {
    const payload = new URLSearchParams({
        todo: 'delete-categoria-aggiunte',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pia_id: dishId,
        cva_id: cvaId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function editCategoryAdditions(resId, dishId, cvaId, attributeName, attributeValue) {
    const payload = new URLSearchParams({
        todo: 'edit-categoria-aggiunte',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pia_id: dishId,
        cva_id: cvaId,
        attr: attributeName,
        value: attributeValue
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function editCategoryAddMore(resId, dishId, pvaId, name, price) {
    const payload = new URLSearchParams({
        todo: 'edit-aggiunta-testo',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        pva_id: pvaId,
        nome: name,
        aggiunta_prezzo: price
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function createCategoryAddMore(resId, dishId, categoryId, ingredientId) {
    const payload = new URLSearchParams({
        todo: 'add-aggiunta',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        cva_id: categoryId,
        alimento_id: ingredientId,
        aggiunta_prezzo: "",
        pva_id: ""
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

// Input
async function createCategoryAddIngredient(resId, dishId, categoryId, name) {
    const payload = new URLSearchParams({
        todo: 'add-aggiunta-testo',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        cva_id: categoryId,
        nome: name
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function editCategoryAddIngredient(resId, dishId, categoryId, ingredientId, price) {
    const payload = new URLSearchParams({
        todo: 'edit-aggiunta',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        pva_id: categoryId,
        alimento_id: ingredientId,
        aggiunta_prezzo: price
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteAddMore(resId, pvaId) {
    const payload = new URLSearchParams({
        todo: 'elimina-aggiunta-testo',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pva_id: pvaId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function changeCategoryOfDish(resId, dishId, newCategoryId) {
    const payload = new URLSearchParams({
        todo: 'cambia-portata',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        portata_new: newCategoryId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function renameCategoryOfDish(resId, categoryId, oldCategoryName, newCategoryName) {
    const payload = new URLSearchParams({
        todo: 'edit-nome-portata',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        portata_id: categoryId,
        portata_nome: newCategoryName,
        nome_old: oldCategoryName
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteCategory(resId, categoryId) {
    const payload = new URLSearchParams({
        todo: 'force-delete-portata',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        prt_id: categoryId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteDish(resId, dishId) {
    const payload = new URLSearchParams({
        todo: 'delete-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getImageOfDish(resId, dishId) {
    const payload = new URLSearchParams({
        todo: 'get-img-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getImageOfWine(resId, wineId) {
    const payload = new URLSearchParams({
        todo: 'get-img-vino',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        vino_id: wineId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

// TODO: FILE UPDATE, DOMAIN
async function uploadDishFile(resId, dishId, file) {
    const payload = new URLSearchParams({
        file: file,
        ris_id: resId,
        pia_id: dishId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

// TODO: FILE UPDATE, DOMAIN
async function deleteDishFile(resId, name) {
    const payload = new URLSearchParams({
        ris_id: resId,
        name: name,
        op: `delete`,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getDishYoutubeLink(resId, dishId) {
    const payload = new URLSearchParams({
        todo: 'get-dish-youtube-link',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        id_piatto: dishId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getWineYoutubeLink(resId, wineId) {
    const payload = new URLSearchParams({
        todo: 'get-drink-youtube-link',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        id_vino: wineId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setDishYoutubeLink(resId, dishId, vidUrl) {
    const payload = new URLSearchParams({
        todo: 'set-dish-youtube-link',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        id_piatto: dishId,
        link: vidUrl,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setWineYoutubeLink(resId, wineId, vidUrl) {
    const payload = new URLSearchParams({
        todo: 'set-drink-youtube-link',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        id_vino: wineId,
        link: vidUrl,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getGramsOfIngredients(resId, dishId) {
    const payload = new URLSearchParams({
        todo: 'get-grammatura-ingredienti',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateGramsOfIngredients(resId, dishId, grams) {
    const payload = new URLSearchParams({
        todo: 'edit-grammatura-ingredienti',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        grammatura_ingredienti: grams, // array of object
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateDrinkOfDay(resId, categoryName, occasion = 0) {
    const payload = new URLSearchParams({
        todo: 'update_bevanda_per_categoria',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        category_name: categoryName,
        occasion: occasion,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getRestaurantInfo(resId) {
    const payload = new URLSearchParams({
        todo: 'restaurant-info',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function renameCategoryOfWine(resId, oldCategoryName, newCategoryName) {
    const payload = new URLSearchParams({
        todo: 'rename-drink-category',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        category_name: newCategoryName,
        old_name: oldCategoryName
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getWineById(resId, wineId) {
    const payload = new URLSearchParams({
        todo: 'get-single-drink',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        rvi_id: wineId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function getWineCategoriesInfo(resId) {
    const payload = new URLSearchParams({
        todo: "category-list",
        script: "scripts/step-5-a.php",
        ris_id: resId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload))
}

async function addWineCategory(resId, name) {
        const payload = new URLSearchParams({
        todo: 'add-drink-category',
        script: 'scripts/step-5-a.php',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        ris_id: resId,
        category_name: name
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function addWine(resId, wine) {
    const payload = new URLSearchParams({
        todo: 'add-vino',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        categoria: wine.category || "",
        id_categoria_rvc: wine.categoryId || "",
        nome_categoria_rvc: wine.category || "",
        cantina: wine.cellar || "",
        nome: wine.name || "",
        anno: wine.anno || "",
        prezzo: wine.price || 0,
        prezzo_calice: wine.gobletPrice || "",
        solfiti: wine.sulfite || "", // 0,1,2
        descrizione: wine.description || "",
        supply: wine.supply || "",
        id_vino: '',
        served_at: wine.served_at || 2,
        staff: '',
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function editWine(resId, wineId, wine) {
    const payload = new URLSearchParams({
        todo: 'edit-vino',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        categoria: wine.category || "",
        id_categoria_rvc: wine.categoryId || "",
        nome_categoria_rvc: wine.category || "",
        cantina: wine.cellar,
        nome: wine.name,
        anno: wine.releaseYear,
        prezzo: wine.price,
        prezzo_calice: wine.pricePerGlass,
        solfiti: wine.solfiti, // 0,1,2
        descrizione: wine.description,
        supply: wine.stock,
        id_vino: wineId,
        served_at: wine.served_at,
        staff: "",
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteWine(resId, wineId) {
    const payload = new URLSearchParams({
        todo: 'elimina-vini',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        id_vino: wineId,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateOrderDrink(resId, wineId, isArchieve, categoryName, startWeight, endWeight) {
    const payload = new URLSearchParams({
        todo: 'reorder-drinks-range',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        drink_id: wineId,
        category_name: categoryName,
        startWeight: startWeight,
        endWeight: endWeight,
        archivio: isArchieve
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateOrderDrinkCategory(resId, categoryName, dir, weight) {
    const payload = new URLSearchParams({
        todo: 'swap-drink-category',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        category_name: categoryName,
        dir: dir,
        weight: weight,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setStatusOfTranslationOfDrink(resId, category, status = 0) {
    const payload = new URLSearchParams({
        todo: 'set-drink-translation-status',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        category: category,
        status: status,
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateDishPhoto(resId, dishId, filename) {
    const payload = new URLSearchParams({
        todo: 'edit-path-image',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pia_id: dishId,
        filename: filename,
        path: `${API_BASE_DOMAIN}/assets/piatti/`
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function updateWinePhoto(resId, wineId, filename) {
    const payload = new URLSearchParams({
        todo: 'edit-path-image-vino',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        rvi_id: wineId,
        filename: filename,
        path: `${API_BASE_DOMAIN}/assets/vini/`
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function archiveDish(resId, dishCategoryId) {
    const payload = new URLSearchParams({
        todo: 'nr_piatti_portata',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        portata_id: dishCategoryId
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setVisibilityDish(resId, dishId, toggle = 0) {
    const payload = new URLSearchParams({
        todo: 'toggle-visibile-piatto',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        piatto_id: dishId,
        visibile: toggle
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function setVisibilityWine(resId, wineId, toggle = 0) {
    const payload = new URLSearchParams({
        todo: 'toggle-vino-visibile',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        rvi_id: wineId,
        visibile: toggle
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteDishPhotoPath(resId, dishId) {
    const payload = new URLSearchParams({
        todo: 'elimina-path-immagine',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        pia_id: dishId,
        dataType: "json"
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function deleteWinePhotoPath(resId, wineId) {
    const payload = new URLSearchParams({
        todo: 'elimina-path-immagine-vino',
        script: 'scripts/step-5-a.php',
        ris_id: resId,
        rvi_id: wineId,
        dataType: "json"
    });

    return (await doAuthPost(`${API_DOMAIN}`, payload));
}

async function uploadDishPhoto(file, resId, dishId) {
    const payload = new FormData();
    payload.append("myfile", file);
    payload.append("ris_id", resId);
    payload.append("pia_id", dishId);

    return (await doFilePost(`${API_BASE_DOMAIN}/net/profilo/step-5-a/scripts/include/upload_file.php`, payload));
}

async function deleteDishPhoto(resId, name) {
    const payload = new FormData();
    payload.append("op", "delete");
    payload.append("ris_id", resId);
    payload.append("name", name);

    return (await doFilePost(`${API_BASE_DOMAIN}/net/profilo/step-5-a/scripts/include/delete_file.php`, payload));
}

async function uploadWinePhoto(file, resId, wineId) {
    const payload = new FormData();
    payload.append("myfilevino", file);
    payload.append("ris_id", resId);
    payload.append("rvi_id", wineId);

    return (await doFilePost(`${API_BASE_DOMAIN}/net/profilo/step-5-a/scripts/include/upload_file_vino.php`, payload));
}

async function deleteWinePhoto(resId, name) {
    const payload = new FormData();
    payload.append("op", "delete");
    payload.append("ris_id", resId);
    payload.append("name", name);

    return (await doFilePost(`${API_BASE_DOMAIN}/net/profilo/step-5-a/scripts/include/delete_file.php`, payload));
}

export {
    getWineCategories,
    getPackageStatus,
    getLanguagesList,
    getTranslationsList,
    updateNameTranslation,
    getMenuList,
    getDishCategories,
    getDishesByMenuId,
    updateCostOfCover,
    updateMenuDescription,
    updateNameDish,
    updatePriceDish,
    updateDescriptionDish,
    deleteIngredient,
    addDishCategory,
    updateOrderCategory,
    addDishToCategory,
    updateDishMealsOfDay,
    updateOrderDish,
    getIngredientsOfComposed,
    getIngredientsByName,
    updateIngredientToDish,
    updateIngredientToComposed,
    updateDishCharacteristic,
    updateDishContains,
    updateDishFrozen,
    getDrinkByName,
    setConsignedDrink,
    updateContaminatingIngredient,
    getLanguageDescription,
    modifyLanguageDescription,
    addLanguageDescription,
    addVariation,
    modifyVariation,
    deleteVariation,
    toggleVariation,
    toggleConsignment,
    deleteDishCategory,
    addCategoryAdditions,
    deleteCategoryAdditions,
    editCategoryAdditions,
    editCategoryAddMore,
    createCategoryAddMore,
    createCategoryAddIngredient,
    editCategoryAddIngredient,
    deleteAddMore,
    changeCategoryOfDish,
    renameCategoryOfDish,
    deleteCategory,
    deleteDish,
    getImageOfDish,
    getImageOfWine,
    uploadDishFile,
    deleteDishFile,
    getDishYoutubeLink,
    getWineYoutubeLink,
    setDishYoutubeLink,
    setWineYoutubeLink,
    getGramsOfIngredients,
    updateGramsOfIngredients,
    updateDrinkOfDay,
    getRestaurantInfo,
    renameCategoryOfWine,
    getWineById,
    getWineCategoriesInfo,
    addWineCategory,
    addWine,
    editWine,
    deleteWine,
    updateOrderDrink,
    updateOrderDrinkCategory,
    setStatusOfTranslationOfDrink,
    updateDishPhoto,
    updateWinePhoto,
    archiveDish,
    setVisibilityDish,
    setVisibilityWine,
    deleteDishPhotoPath,
    deleteWinePhotoPath,
    uploadDishPhoto,
    deleteDishPhoto,
    uploadWinePhoto,
    deleteWinePhoto,
}