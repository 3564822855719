import React, { useEffect, useReducer, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ItemDishCategoryModify from "../components/Restaurant/Menu/ItemDishCategoryModify";
import ItemWineCategoryModify from "../components/Restaurant/Menu/ItemWineCategoryModify";
import { useNavigate, useParams } from "react-router-dom";
import { getMenuList, getWineCategories, updateCostOfCover, updateMenuDescription } from "../apis/modify-restaurant";
import DishAddCategory from "../components/Restaurant/Menu/Modal/DishAddCategory";
import { useCallback } from "react";
import Archieve from "../components/Icon/Archieve";
import { useTranslation } from "react-i18next";
import WineAddCategoryModal from "../components/Restaurant/Menu/Modal/WineAddCategoryModal";

function RestaurantMenuModify(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { restaurantId } = useParams()

    const [menuPrice, setMenuPrice] = useState("");
    const [menuDescription, setMenuDescription] = useState("");
    const [isArchieve, setIsArchieve] = useState(false);
    const [dishCategories, setDishCategories] = useState([]);
    const [wineCategories, setWineCategories] = useState([]);
    const [isDishMovable, setIsDishMovable] = useState(false);
    const [isWineMovable, setIsWineMovable] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const loadWineCategories = useCallback(async (isArchieve = 0) => {
        const wineCategoriesData = await getWineCategories(restaurantId, +isArchieve);
        setWineCategories(wineCategoriesData.elenco);
        forceUpdate();
    }, [restaurantId]);

    const loadMenuList = useCallback(async (isArchieve = 0) => {
        const menuData = await getMenuList(restaurantId, +isArchieve);
        setMenuPrice(menuData.menu.mnu_prezzo_coperto);
        setMenuDescription(menuData.menu.mnu_descrizione);

        let tempDishCats = menuData.portate;
        if (isArchieve) tempDishCats = tempDishCats.filter(it => it.nr_piatti_archiviati !== 0)
        setDishCategories(tempDishCats);
    }, [restaurantId]);

    useEffect(() => {
        loadMenuList();
        loadWineCategories();
    }, [restaurantId, loadWineCategories, loadMenuList])

    const changeHandlerDescription = ({ target }) => setMenuDescription(target.value);
    const blurHandlerDescription = async ({ target }) => await updateMenuDescription(restaurantId, target.value);

    const changeHandlerPrice = ({ target }) => setMenuPrice(target.value);
    const blurHandlerPrice = async ({ target }) => await updateCostOfCover(restaurantId, target.value);

    const onClickArchieve = () => {
        setIsArchieve(prev => !prev);
        setWineCategories([]);
        setDishCategories([]);
        loadMenuList(!isArchieve);
        loadWineCategories(!isArchieve);
    }

    const onClickBackHandler = () => {
        navigate(-1);
    }

    const onClickTastingMenu = () => {
        navigate("./tasting");
    }

    return (
        <div className="RestaurantMenuModify">
            <Header isDividerExist={true} isTopBarEnabled={true} />

            <div className="pb-8">
                <a href="https://ristoratori-staging.mycia.it/net/profilo/step-5-a/docs/MyCIA_Registrazione_Ristoratore.pdf" target="_blank" rel="noopener noreferrer">
                    <div className="flex items-center justify-center gap-x-4 bg-mycia-blue py-2">
                        <svg id="Livello_2" className="h-5 w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g id="Livello_1-2">
                                <g>
                                    <path d="M11.71,15.32s-.02,0-.03,0c-.72-.02-1.32,.56-1.34,1.28h0s0,.01,0,.02c.03,.72,.65,1.28,1.37,1.25,.06,0,.11,0,.17,0,.7-.05,1.24-.66,1.19-1.36-.05-.7-.66-1.24-1.36-1.19Z" />
                                    <path d="M12.1,6.05c-1.98,0-3.34,.9-3.7,1.99-.07,.19-.11,.4-.12,.6,0,.02,0,.05,0,.07,.03,.5,.47,.88,.97,.85,.68,0,.84-.35,1.2-.74,.31-.57,.9-.92,1.54-.92,.05,0,.1-.01,.14-.01,.74-.02,1.36,.57,1.38,1.31,0,.71-.49,1.09-1.47,1.76-.84,.43-1.38,1.28-1.43,2.22v.13h0c0,.67,.41,1,1.13,1,.53,.06,1.01-.32,1.08-.85,0-.05,0-.09,.01-.14,.04-.59,.44-.99,1.2-1.49,1.15-.74,1.88-1.42,1.88-2.75,0-1.93-1.74-3.04-3.8-3.04Z" />
                                    <path d="M11.98,0C5.38,.04,.04,5.39,0,12c.04,6.61,5.39,11.96,11.99,12,6.61-.04,11.96-5.39,12.01-12C23.95,5.38,18.6,.04,11.98,0Zm.02,21.6c-5.29,0-9.58-4.3-9.59-9.59,0-5.3,4.29-9.6,9.59-9.6,5.3,0,9.6,4.29,9.6,9.59v.03c0,5.3-4.31,9.59-9.6,9.58Z" />
                                </g>
                            </g>
                        </svg>

                        <p className="text-lg font-semibold uppercase text-white">Tutorial</p>
                    </div>
                </a>

                <div className="my-4 flex cursor-pointer items-center px-mobile" onClick={onClickBackHandler}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="h-5 w-5">
                        <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                    </svg>

                    <p className="ml-2 text-lg font-light underline">Scheda ristorante</p>
                </div>

                <hr className="border-t border-mycia-dimGray" />

                <div className="py-4 px-mobile">
                    <div className="flex items-center">
                        <p className="text-lg font-semibold">Costo del coperto</p>

                        <input
                            name="coperto"
                            type="number"
                            step={0.1}
                            placeholder="€"
                            value={menuPrice}
                            onChange={changeHandlerPrice}
                            onBlur={blurHandlerPrice}
                            className="input-mycia-secondary ml-4 max-w-[11ch]"
                        />
                    </div>

                    <p className="mt-8 text-lg font-semibold">Ci sono dettagli particolari del tuo menu?</p>
                    <input
                        type="text"
                        placeholder="Inserisci una descrizione del tuo locale"
                        value={menuDescription}
                        onChange={changeHandlerDescription}
                        onBlur={blurHandlerDescription}
                        className="input-mycia-secondary mt-2"
                    />

                    <p className="mt-4 text-lg font-semibold">Offri un menù degustazione?</p>
                    <div className="button-mycia-white mt-4" onClick={onClickTastingMenu}>
                        <p>CREALO ORA</p>
                    </div>
                </div>

                <hr className="mt-4 border-t border-mycia-dimGray" />

                <div className="button-mycia-white flex justify-center items-center mx-6 my-4" onClick={onClickArchieve}>
                    {!isArchieve && <Archieve className="w-5 h-5 mr-2"/>}
                    <p>{!isArchieve ? 'ARCHIVIO' : 'MENÙ'}</p>
                </div>

                {!isArchieve &&
                    <DishAddCategory restaurantId={restaurantId} onRefresh={loadMenuList}>
                        <div className="button-mycia mx-6 my-6 bg-mycia-greenPhilippine shadow-mycia-greenPhilippine">
                            <p>AGGIUNGI CATEGORIA</p>
                        </div>
                    </DishAddCategory>
                }

                <div className="flex justify-between items-center mx-6 my-6">
                    {dishCategories && dishCategories.length !== 0 && 
                        <p className={`cursor-pointer text-lg font-semibold ${!isDishMovable ? 'text-mycia-orangeRed' : 'text-mycia-silver'} underline`} onClick={() => setIsDishMovable(true)}>
                            Riordina portate
                        </p>
                    }

                    {
                        isDishMovable && <p className="cursor-pointer text-lg font-semibold text-mycia-orangeRed underline" onClick={() => setIsDishMovable(false)}>Fine</p>
                    }
                </div>

                {dishCategories && dishCategories.map((item, key, arr) => (
                    <ItemDishCategoryModify key={item.portata_id} id={item.portata_id} name={item.prt_nome} weight={item.prt_weight} isStart={key === 0} isEnd={key === arr.length - 1} onRefresh={loadMenuList} isArchieve={isArchieve} isDishMovable={isDishMovable} />
                ))}

                {!isArchieve &&
                    <WineAddCategoryModal restaurantId={restaurantId} onRefresh={loadWineCategories}>
                        <div className="button-mycia mx-6 my-6 bg-mycia-greenCyan shadow-mycia-greenCyan">
                            <p>AGGIUNGI BEVANDA</p>
                        </div>
                    </WineAddCategoryModal>
                }

                 <div className="flex justify-between items-center mx-6 my-6">
                    {wineCategories && wineCategories.length !== 0 && 
                        <p className={`cursor-pointer text-lg font-semibold ${!isWineMovable ? 'text-mycia-orangeRed' : 'text-mycia-silver'} underline`} onClick={() => setIsWineMovable(true)}>
                            Riordina categoria bevande
                        </p>
                    }

                    {
                        isWineMovable && <p className="cursor-pointer text-lg font-semibold text-mycia-orangeRed underline" onClick={() => setIsWineMovable(false)}>Fine</p>
                    }
                </div>

                {wineCategories && wineCategories.map((item, key, arr) => {
                    if (Array.isArray(item.vini) && !item.vini.length && isArchieve) return <></>
                    const isStart = arr?.[key-1]?.campari === 1 || arr?.[key-1]?.pago === 1 || arr?.[key-1]?.sanbitter === 1 || key === 0;
                    return <ItemWineCategoryModify key={key} name={item.nome} wines={item.vini} isCampari={item.campari} isPago={item.pago} isSanbitter={item.sanbitter} weight={item.weight} isStart={isStart} isEnd={key === arr.length - 1} isArchieve={isArchieve} onRefresh={loadWineCategories} isWineMovable={isWineMovable}/>
                })}
            </div>

            <Footer />
        </div>
    );
}

export default RestaurantMenuModify;
