import React from 'react';

function TastingMenuThird(props) {
    const { name, plates, price } = props;
    return (
        <div className="TastingMenuThird">
            <p className="text-mycia-red capitalize">{name}</p>
            
            {
                plates && plates.map((item, index) => (
                    <div key={index} className="mt-2">
                        <p className="text-xl italic capitalize">{item.pia_nome}</p>
                        <p className="text-xs uppercase">{item.ingredients && item.ingredients.map(it => it.name).join(", ")}</p>
                    </div>
                ))
            }

            <p className="mt-2 text-lg text-end capitalize">{price}€</p>
        </div>
    )
}

export default TastingMenuThird;