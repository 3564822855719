import React from "react";
import { useTranslation } from "react-i18next";

function ItemReview(props) {
    const { t } = useTranslation();
    const { title, description, date, rating } = props;

    return (
        <div className="ItemReview">
            <div className="flex">
                <div className="w-full rounded-mobile bg-mycia-whiteSmoke p-3">
                    <div className="flex items-center justify-between">
                        <p className="font-light">{title}</p>

                        <p className="text-xs">{date}</p>
                    </div>

                    <div className="mt-4 flex items-center gap-x-1">
                        {Array(10)
                            .fill(0)
                            .map((item, key) => (
                                <span
                                    key={key}
                                    className={`h-3.5 w-3.5 rounded-full border border-mycia-red 
                                    ${!isNaN(rating) && rating > key ? `bg-mycia-red` : `bg-transparent`}`}
                                ></span>
                            ))}
                        <p className="ml-4 text-xs">{rating}/10</p>
                    </div>

                    <p className="mt-6 mb-2 font-light">
                        <span className="font-semibold">
                            {title} <br />{" "}
                        </span>
                        {description}
                    </p>

                    <div className="my-2 rounded-mobile bg-[#e6e0e0] p-3">
                        <p className="font-light">
                            <span className="font-semibold">
                                La tua risposta: <br />{" "}
                            </span>
                            Grazie!
                        </p>
                    </div>
                </div>

                <div className="flex flex-col justify-center space-y-8 px-4">
                    {/* TODO: CHANGE ICON */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        viewBox="0 0 1000 1000"
                        className="h-6 w-6 cursor-pointer"
                    >
                        <path d="M866.5 823.5H493.3l-239 159.8L214 824.9h-81.9C65 824.9 10 748.4 10 681.2V138.9C10 71.8 65 16.7 132.2 16.7h735.7c67.1 0 122.2 55 122.2 122.2v562.5c-1.4 67.1-56.5 122.1-123.6 122.1zm79.2-684.6c0-44.3-36.2-79.2-80.5-79.2H130.8c-44.3 0-80.5 36.2-80.5 79.2v541c0 44.3 36.2 100.7 80.5 100.7l116.8 1.3 34.9 127.5 196-128.9 388-1.3c44.3 0 80.5-36.2 80.5-79.2V138.9h-1.3zm-233.6 345c-34.9 0-64.4-28.2-64.4-64.4 0-34.9 28.2-64.4 64.4-64.4 34.9 0 64.4 28.2 64.4 64.4-1.3 36.2-29.5 64.4-64.4 64.4zm0-84.6c-12.1 0-21.5 9.4-21.5 21.5s9.4 21.5 21.5 21.5 21.5-9.4 21.5-21.5-10.8-21.5-21.5-21.5zm-213.4 84.6c-34.9 0-64.4-28.2-64.4-64.4 0-34.9 28.2-64.4 64.4-64.4 34.9 0 64.4 28.2 64.4 64.4s-29.5 64.4-64.4 64.4zm0-84.6c-12.1 0-21.5 9.4-21.5 21.5s9.4 21.5 21.5 21.5 21.5-9.4 21.5-21.5-9.5-21.5-21.5-21.5zm-213.5 84.6c-34.9 0-64.4-28.2-64.4-64.4 0-34.9 28.2-64.4 64.4-64.4 34.9 0 64.4 28.2 64.4 64.4s-28.1 64.4-64.4 64.4zm0-84.6c-12.1 0-21.5 9.4-21.5 21.5s9.4 21.5 21.5 21.5 21.5-9.4 21.5-21.5-9.4-21.5-21.5-21.5z"></path>
                    </svg>

                    {/* TODO: CHANGE ICON */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        viewBox="0 0 1000 1000"
                        className="h-6 w-6 cursor-pointer"
                    >
                        <path d="M866.5 823.5H493.3l-239 159.8L214 824.9h-81.9C65 824.9 10 748.4 10 681.2V138.9C10 71.8 65 16.7 132.2 16.7h735.7c67.1 0 122.2 55 122.2 122.2v562.5c-1.4 67.1-56.5 122.1-123.6 122.1zm79.2-684.6c0-44.3-36.2-79.2-80.5-79.2H130.8c-44.3 0-80.5 36.2-80.5 79.2v541c0 44.3 36.2 100.7 80.5 100.7l116.8 1.3 34.9 127.5 196-128.9 388-1.3c44.3 0 80.5-36.2 80.5-79.2V138.9h-1.3zm-233.6 345c-34.9 0-64.4-28.2-64.4-64.4 0-34.9 28.2-64.4 64.4-64.4 34.9 0 64.4 28.2 64.4 64.4-1.3 36.2-29.5 64.4-64.4 64.4zm0-84.6c-12.1 0-21.5 9.4-21.5 21.5s9.4 21.5 21.5 21.5 21.5-9.4 21.5-21.5-10.8-21.5-21.5-21.5zm-213.4 84.6c-34.9 0-64.4-28.2-64.4-64.4 0-34.9 28.2-64.4 64.4-64.4 34.9 0 64.4 28.2 64.4 64.4s-29.5 64.4-64.4 64.4zm0-84.6c-12.1 0-21.5 9.4-21.5 21.5s9.4 21.5 21.5 21.5 21.5-9.4 21.5-21.5-9.5-21.5-21.5-21.5zm-213.5 84.6c-34.9 0-64.4-28.2-64.4-64.4 0-34.9 28.2-64.4 64.4-64.4 34.9 0 64.4 28.2 64.4 64.4s-28.1 64.4-64.4 64.4zm0-84.6c-12.1 0-21.5 9.4-21.5 21.5s9.4 21.5 21.5 21.5 21.5-9.4 21.5-21.5-9.4-21.5-21.5-21.5z"></path>
                    </svg>
                </div>
            </div>

            <p className="mt-4 mr-10 text-sm">
                Il commento è attualmente nascosto. Un nostro operatore valuterà la tua segnalazione e deciderà se
                eliminarlo definitivamente oppure renderlo nuovamente visibile. Grazie.
            </p>
        </div>
    );
}

export default ItemReview;
