const NODE_ENV = process.env.NODE_ENV;
const DEV_AUTHORIZATION = process.env.REACT_APP_DEV_AUTHORIZATION;
const DEV_SECRET = process.env.REACT_APP_DEV_SECRET;

const LOCALSTORAGE_KEY = process.env.REACT_APP_LOCALSTORAGE_KEY;

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_PASSWORD = process.env.REACT_APP_CLIENT_PASSWORD;

const API_DOMAIN = process.env.REACT_APP_DOMAIN_API;
const API_BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN_API;
const API_BASE_DOMAIN_V2 = process.env.REACT_APP_BASE_DOMAIN_API_V2;
const API_BASE_DOMAIN_V3 = process.env.REACT_APP_BASE_DOMAIN_API_V3;
const STORAGE_DOMAIN = process.env.REACT_APP_STORAGE_DOMAIN;
const API_DOMAIN_MYCIA = process.env.REACT_APP_DOMAIN_API_MYCIA;
const LOGIN_DOMAIN = process.env.REACT_APP_DOMAIN_LOGIN;
const LOGS_DOMAIN = process.env.REACT_APP_DOMAIN_LOGS;

const USER_AGENT = "mycia restaurants mobile application";

const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;

export {
    NODE_ENV,
    DEV_AUTHORIZATION,
    DEV_SECRET,
    LOCALSTORAGE_KEY,
    CLIENT_ID,
    CLIENT_PASSWORD,
    API_DOMAIN,
    API_BASE_DOMAIN_V2,
    API_BASE_DOMAIN_V3,
    STORAGE_DOMAIN,
    API_BASE_DOMAIN,
    API_DOMAIN_MYCIA,
    LOGIN_DOMAIN,
    LOGS_DOMAIN,
    USER_AGENT,
    GOOGLE_MAPS_API_KEY,
}
