import React, { useState, useEffect } from "react";
import Select from "react-select";
import { myCIASelect } from "../../../style";
import MyCIAInput from "../../Input/MyCIAInput";
import CreateRestaurantPages from "../CreateRestaurantPages";
import forms from "../../../logic/forms";
import { getCoursesAndTypesList } from "../../../apis/restaurants"
import { useTranslation } from "react-i18next";

function ProfileForm(props) {
    const { t } = useTranslation();
    const { onFormCompleted, onSubmitHandler } = props;
    
    const [profileFormData, setProfileFormData] = useState({
        "NomeRistorante": "",
        "Indirizzo": "",
        "IndirizzoDefault": "",
        "LinkSito": "",
        "Email": "",
        "Telefono": "",
        "TipoRistorante[1]": "",
        "LineeCucina[1]": "",
        "FasciaPrezzo": 1,
        "Descrizione": ""
    });
    const [coursesList, setCoursesList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [errors, setErrors] = useState({});
    
    // TODO: DEPENDS ON BACKEND
    const validators = {
        "NomeRistorante": forms.list(forms.required("Il nome è richiesto")),
        "Indirizzo": forms.list(forms.required("L'indirizzo è obbligatorio")),
        "Email": forms.list(forms.required("L'e-mail è richiesta")),
        "Telefono": forms.list(forms.required("Il telefono è richiesto")),
        "TipoRistorante": forms.list(forms.required("Tipo è richiesto")),
        "LineeCucina": forms.list(forms.required("La linea è richiesta")),
        "FasciaPrezzo": forms.list(),
        "Descrizione": forms.list(),
    };
    const prices = [
        {
            label: "€ - Economico",
            price: "(10-20€)"
        },
        {
            label: "€€- Prezzi moderati",
            price: "(20-35€)"
        },
        {
            label: "€€€ - Alto",
            price: "(35-50€)"
        },
        {
            label: "€€€€ - Raffinato",
            price: "(50+€)"
        }
    ];

    useEffect(() => {
        async function loadCoursesAndTypes() {
            const response = await getCoursesAndTypesList();
            const courses = response.linee.map((item) => ({
                value: item.id,
                label: item.text
            }));
            const types = response.tipi.map((item) => ({
                value: item.id,
                label: item.text
            }));

            setTypesList(types);
            setCoursesList(courses);
        }

        loadCoursesAndTypes();
    }, []);

     const renderModifyError = fieldName => {
        if (undefined !== errors[fieldName]) {
            return (
                <p className="mt-2 text-sm text-mycia-red">{errors[fieldName]}</p>
            );
        }
    };

    const onBlurHandler = ({ target }) => {
        const result = forms.validateMany(validators, { [target.name]: target.value });
        setErrors({ ...errors, ...result });
    };

    const onChangeHandler = ({ target }) => {
        return setProfileFormData(profileFormData => ({
            ...profileFormData,
            [target.name]: target.value,
        }));
    };

    const arrayFormDataHandle = (name) => {
        let isDataExist = false;
        for (let index = 1; index < 4; index++) {
            const element = profileFormData[`${name}[${index}]`];
            if (element && element.length !== 0) isDataExist = true;
        }

        if (!isDataExist) {
            const result = forms.validateMany(validators, { [name]: "" });
            return result;
        }
        return {};
    }

    const onSubmit = async (evt) => {
        evt.preventDefault();
        
        const errorTypesRestaurant = arrayFormDataHandle("TipoRistorante");
        const errorCoursesRestaurant = arrayFormDataHandle("LineeCucina");

        let errors = {
            ...forms.validateMany(validators, profileFormData),
            ...errorTypesRestaurant,
            ...errorCoursesRestaurant
        } 
        
        if (forms.anyErrors(errors)) {
            setErrors(errors);
        } else {
            onFormCompleted(profileFormData);
            onSubmitHandler();
        }
    };

    const mapReactSelect = (name, value) => {
        return {
            target: {
                name: name,
                value: value
            }
        }
    };

    const noOptionsMessage = ({ inputValue }) => (inputValue ? "Nessuno" : "No results found");
    
    const findOptionFromList = (list, value) => list.find(item => item.value == value);
    
    return (
        <form method="POST" action="" onSubmit={onSubmit} >
            <fieldset>
                <legend className="font-light mt-4 inline-block">
                    <span className="font-semibold">Creazione profilo ristorante</span> <br /> <br />
                    Compila i campi sottostanti con i dati del tuo ristorante per crearne il profilo su MyCIA. Ricordati di tornare su questa pagina dopo la sua creazione per aggiungere i servizi disponibili nel tuo locale.
                </legend>

                <CreateRestaurantPages selectedPage={0} className="mt-10" />

                <label htmlFor="name" className="mt-8 inline-block text-sm">NOME RISTORANTE</label>
                <input id="name" name="NomeRistorante" className="input-mycia-secondary" type="text" placeholder="-" onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("NomeRistorante")}
                
                <label className="mt-8 inline-block text-sm">FASCIA DI PREZZO</label>
                <div>
                    {prices.map((item, key) => (
                        <MyCIAInput
                            id={`price-${key + 1}`}
                            name="FasciaPrezzo"
                            type="radio"
                            value={key + 1}
                            key={key}
                            className="mt-1"
                            labelClassName="p-1"
                            isLabelMaxWidth={true}
                            onChange={onChangeHandler}
                            onBlur={onBlurHandler}
                            checked={key + 1 == profileFormData.FasciaPrezzo}
                        >
                            <div className="flex justify-between text-lg font-light text-black ">
                                <p>{item.label}</p>
                                <p>{item.price}</p>
                            </div>
                        </MyCIAInput>
                    ))}
                </div>

                <label className="mt-4 inline-block text-sm">TIPO DI RISTORANTE</label>
                <Select
                    id="type_1"
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={typesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(typesList, profileFormData["TipoRistorante[1]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("TipoRistorante[1]", event.value))}
                />
                <Select
                    id="type_2"
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={typesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(typesList, profileFormData["TipoRistorante[2]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("TipoRistorante[2]", event.value))}
                />
                <Select
                    id="type_2"
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={typesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(typesList, profileFormData["TipoRistorante[3]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("TipoRistorante[3]", event.value))}
                />
                {renderModifyError("TipoRistorante")}

                <label className="mt-4 inline-block text-sm">LINEA DI CUCINA</label>
                <Select
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={coursesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(coursesList, profileFormData["LineeCucina[1]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("LineeCucina[1]", event.value))}
                />
                <Select
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={coursesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(coursesList, profileFormData["LineeCucina[2]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("LineeCucina[2]", event.value))}
                />
                <Select
                    className="mt-2"
                    styles={myCIASelect}
                    isClearable={false}
                    options={coursesList}
                    placeholder="Nessuno"
                    noOptionsMessage={noOptionsMessage}
                    value={findOptionFromList(coursesList, profileFormData["LineeCucina[3]"])}
                    onChange={(event) => onChangeHandler(mapReactSelect("LineeCucina[3]", event.value))}
                />
                {renderModifyError("LineeCucina")}

                <label htmlFor="address" className="mt-4 inline-block text-sm">INDIRIZZO</label>
                <input id="address" name="Indirizzo" type="text" className="input-mycia-secondary" placeholder="-" onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("Indirizzo")}

                <label htmlFor="telephone" className="mt-4 inline-block text-sm">TELEFONO</label>
                <input id="telephone" name="Telefono" type="tel" className="input-mycia-secondary" placeholder="-" onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("Telefono")}

                <label htmlFor="email" className="mt-4 inline-block text-sm">EMAIL</label>
                <input id="email" name="Email" type="email" className="input-mycia-secondary" placeholder="-" onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("Email")}

                <label htmlFor="website" className="mt-4 inline-block text-sm">SITO WEB (OPZIONALE)</label>
                <input id="website" name="LinkSito" type="text" className="input-mycia-secondary" placeholder="-" onChange={onChangeHandler} />

            </fieldset>
            
            <button className="button-mycia w-full mt-12">CONTINUA</button>
        </form>
    );
}

export default ProfileForm;
