import React, { useRef, useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { usePrintType } from "../../../logic/PrintTypeContext";
import { buildContactlessMenuURL, PRINT_ENUM } from "../../../logic/utils";
import MyCIAInput from "../../Input/MyCIAInput";
import { useTranslation } from "react-i18next";

function QrService(props) {
    const { t } = useTranslation();
    const { token } = useParams();    
    const { printType, onChangePrintType } = usePrintType();

    const printRef = useRef();
    const [selectedQrTheme, setSelectedQrTheme] = useState(1);
    const [menuUrl, setMenuUrl] = useState();

    const getBackgroundImage = () => {
        switch (selectedQrTheme) {
            case 1:
                return "bg-[url('https://ristoratori.mycia.it/assets/qr/qr-background-1-600.jpg')]"
            case 2:
                return "bg-[url('https://ristoratori.mycia.it/assets/qr/qr-background-2-600.jpg')]"
            case 3:
                return "bg-[url('https://ristoratori.mycia.it/assets/qr/qr-background-3-600.jpg')]"
            case 4:
                return "bg-[url('https://ristoratori.mycia.it/assets/qr/qr-background-4-600.jpg')]"
            default:
                break;
        }
    }

    useEffect(() => {
        setMenuUrl(buildContactlessMenuURL(printType, token));
    }, [printType, token])

    return (
        <div className="QrService rounded-mobile border-2 border-mycia-dimGray p-4">
            <p className="text-center">Scegli un template grafico per il tuo menù tra i 4 disponibili</p>

            <div className="mt-4 flex flex-row justify-center gap-x-4">
                {[0, 1, 2, 3].map((item, key) => (
                    <div
                        className={`flex items-center justify-center rounded-full ${key + 1 === selectedQrTheme ? `bg-mycia-orangeLight` : `bg-mycia-dimGray`} h-9 w-9 cursor-pointer`}
                        onClick={() => setSelectedQrTheme(key + 1)}
                    >
                        <p className={`text-lg font-semibold ${key + 1 === selectedQrTheme ? `text-black` : `text-white`}`}>
                            {key + 1}
                        </p>
                    </div>
                ))}
            </div>

            <img className="mx-auto h-auto w-3/4" src="" alt="" />

            <p className="mt-4 cursor-pointer select-none text-center text-sm font-semibold uppercase underline hidden">
                VISUALIZZA ANTEPRIMA
            </p>

            <div ref={printRef} className={`relative h-[300px] mx-auto mt-10 drop-shadow-xl bg-contain ${getBackgroundImage()} bg-no-repeat bg-center`}>
                {menuUrl &&
                    <QRCode
                        size={220}
                        style={{ position: "absolute", left: "calc(50% - 35px)", top: "calc(50% - 83px)", height: "70px", maxWidth: "70px" }}
                        value={menuUrl}
                        viewBox={`0 0 220 220`}
                    />
                }
            </div>

            <p className="mt-12 font-semibold uppercase">PER CHE TIPO DI ORDINE DEVI STAMPARE IL TUO MENÙ CON QR CODE?</p>
            <p className="mt-4">Attenzione: ad ogni servizio è associato un codice QR diverso.</p>

            <MyCIAInput id="order_at_table" name="printType" className="mt-4" type="radio" value={PRINT_ENUM.ORDER_AT_TABLE} checked={printType === PRINT_ENUM.ORDER_AT_TABLE} onChange={onChangePrintType}>
                <p className="ml-2 text-sm font-semibold text-mycia-dimDark">ORDINI AL TAVOLO</p>
            </MyCIAInput>

            <MyCIAInput id="delivery" name="printType" className="mt-4" type="radio" value={PRINT_ENUM.DELIVERY} checked={printType === PRINT_ENUM.DELIVERY} onChange={onChangePrintType}>
                <p className="ml-2 text-sm font-semibold text-mycia-dimDark">ORDINI DELIVERY / TAKE AWAY</p>
            </MyCIAInput>

            <ReactToPrint
                trigger={() =>
                    <div className="button-mycia mt-8">
                        <p>Stampa</p>
                    </div>
                }
                content={() => printRef.current}
                pageStyle="@page { size: 3.2in 4.8in }"
            />

            <p className="mt-12">
                <span className="text-lg font-semibold uppercase">VUOI IMPAGINARE IN MODO DIVERSO IL TUO MENÙ DIGITALE?</span>
            </p>

            <p className="mt-4">
                Premi con il tasto destro sull'anteprima del menù e seleziona “Salva con nome”: in questo
                modo potrai salvarti il solo QR Code sul tuo computer e stamparlo sul supporto che preferisci.
            </p>
        </div>
    );
}

export default QrService;
