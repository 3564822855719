import React, { useState, useEffect } from "react";
import { getBusinessDetail, updateBusiness } from "../../apis/businesses";
import forms from "../../logic/forms";
import MyCIAInput from "../Input/MyCIAInput";
import { useAuth } from "../../logic/AuthContext";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function EditForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [restaurantData, setRestaurantData] = useState({
        "Piva": "",
        "Azienda": "",
        "Indirizzo": "",
        "Telefono": "",
        "NomeRiferimento": "",
        "Email": "",
        "Password": "",
        "ConfermaPassword": "",
        "Trattamento": 0,
        "Clausole": 0,
        "AccettazioneClausole": "",
        "vcl": "",
        "rcl": ""
    });
    const [errors, setErrors] = useState({});

    // TODO: DEPENDS ON BACKEND
    const validators = {
        "Piva": forms.list(forms.required()),
        "Azienda": forms.list(forms.required()),
        "Indirizzo": forms.list(forms.required()),
        "Telefono": forms.list(),
        "NomeRiferimento": forms.list(),
        "Email": forms.list(forms.required()),
        "Password": forms.list(forms.required()),
        "ConfermaPassword": forms.list(forms.equal(restaurantData.Password, restaurantData.ConfermaPassword, "Le password non corrispondono")),
        "Trattamento": forms.list(forms.required()),
        "Clausole": forms.list(forms.required()),
        "AccettazioneClausole": forms.list(),
        "vcl": forms.list(),
        "rcl": forms.list(),
        "ris_id": forms.list()
    };

    useEffect(() => {
        async function loadBusinessDetail() {
            const businessDetailResponse = (await getBusinessDetail());
            const businessDetail = businessDetailResponse.ristoratore;
            
            setRestaurantData({
                "Piva": businessDetail.san_piva,
                "Azienda": businessDetail.san_ragione_sociale,
                "Indirizzo": businessDetail.san_indirizzo,
                "Telefono": businessDetail.san_telefono,
                "NomeRiferimento": businessDetail.san_nome,
                "Email": businessDetail.san_email,
                "Password": "",
                "ConfermaPassword": "",
                "Trattamento": 0,
                "Clausole": 0,
                "AccettazioneClausole": "",
                "vcl": "",
                "rcl": ""
            });
        }

        loadBusinessDetail();
    }, []);

    const renderModifyError = fieldName => {
        if (undefined !== errors[fieldName]) {
            return (
                <p className="mt-2 text-sm text-mycia-red">{errors[fieldName]}</p>
            );
        }
    };

    const onBlurHandler = ({ target }) => {
        const result = forms.validateMany(validators, { [target.name]: target.value });
        setErrors({ ...errors, ...result });
    };

    const onChangeHandler = ({ target }) => {
        return setRestaurantData(restaurantData => ({
            ...restaurantData,
            [target.name]: target.value,
        }));
    };

    const onSubmitHandler = async evt => {
        evt.preventDefault();

        if (true) {
            let errors = forms.validateMany(validators, restaurantData);

            if (forms.anyErrors(errors)) {
                setErrors(errors);
            } else {
                let response = updateBusiness(restaurantData);

                if (response.rac_token) {
                    navigate(-1);
                }
            }
        } else {
            alert("Accedi o registrati");
        }
    };

    return (
        <form method="POST" action="" onSubmit={onSubmitHandler}>
            <fieldset className="mt-4">
                <label htmlFor="piva" className="mt-4 inline-block text-sm font-semibold">P.IVA</label>
                <input type="number" name="Piva" id="piva" className="input-mycia-secondary mt-2" value={restaurantData.Piva} onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("Piva")}

                <label htmlFor="company" className="mt-6 inline-block text-sm font-semibold">RAGIONE SOCIALE</label>
                <input type="text" name="Azienda" id="company" className="input-mycia-secondary mt-2" value={restaurantData.Azienda} onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("Azienda")}

                <label htmlFor="address" className="mt-6 inline-block text-sm font-semibold">INDIRIZZO</label>
                <input type="text" name="Indirizzo" id="address" className="input-mycia-secondary mt-2" value={restaurantData.Indirizzo} onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("Indirizzo")}

                <label htmlFor="telephone" className="mt-6 inline-block text-sm font-semibold">TELEFONO</label>
                <input type="tel" name="Telefono" id="telephone" className="input-mycia-secondary mt-2" value={restaurantData.Telefono} onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("Telefono")}

                <label htmlFor="name_reference" className="mt-6 inline-block text-sm font-semibold">NOME DI RIFERIMENTO</label>
                <input type="text" name="NomeRiferimento" id="name_reference" className="input-mycia-secondary mt-2" value={restaurantData.NomeRiferimento} onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("NomeRiferimento")}

                <label htmlFor="email" className="mt-6 inline-block text-sm font-semibold">EMAIL</label>
                <input type="email" name="Email" id="email" className="input-mycia-secondary mt-2" value={restaurantData.Email} onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("Email")}

                <label htmlFor="password" className="mt-6 inline-block text-sm font-semibold">PASSWORD</label>
                <input type="password" name="Password" id="password" className="input-mycia-secondary mt-2" value={restaurantData.Password} onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("Password")}

                <p className="mt-6 text-sm font-light text-mycia-red">La password deve avere almeno 8 caratteri, un numero e un simbolo.</p>

                <label htmlFor="confirm_password" className="mt-6 inline-block text-sm font-semibold">CONFERMA PASSWORD</label>
                <input type="password" name="ConfermaPassword" id="confirm_password" className="input-mycia-secondary mt-2" value={restaurantData.ConfermaPassword} onBlur={onBlurHandler} onChange={onChangeHandler} />
                {renderModifyError("ConfermaPassword")}

                <MyCIAInput
                    id="privacy-responsibility"
                    name="Trattamento"
                    type="checkbox"
                    className="mt-8"
                    text="Sì"
                    value={restaurantData.Trattamento}
                    labelClassName="p-4 w-full"
                    onBlur={onBlurHandler}
                    onChange={onChangeHandler} 
                    shadow
                >
                    <p className="text-lg font-light text-mycia-dimDark">
                        Dichiaro di aver preso visione e accetto le 
                        <span className="underline">Responsabilità del ristoratore</span> dei dati inseriti.
                    </p>
                </MyCIAInput>
                {renderModifyError("Trattamento")}

                <MyCIAInput
                    id="privacy-leggi"
                    name="Clausole"
                    type="checkbox"
                    text="No"
                    className="mt-10"
                    value={restaurantData.Clausole}
                    labelClassName="p-4 w-full"
                    onBlur={onBlurHandler}
                    onChange={onChangeHandler} 
                    shadow
                >
                    <p className="text-lg font-light text-mycia-dimDark">
                        Ai sensi e per gli effetti di cui all'art. 1341 e 1342 c.c. previa attenta lettura di
                        ogni clausola e rilettura <span className="text-mycia-blue underline">leggi tutto</span>.
                    </p>
                </MyCIAInput>
                {renderModifyError("Clausole")}
            </fieldset>

            <button type="submit" className="button-mycia w-full mt-16">CONFERMA MODIFICHE</button>
        </form>
    );
}

export default EditForm;
