import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ItemBusiness(props) {
    const { t } = useTranslation();
    const { restaurantId, name, photo } = props;

    return (
        <div className="ItemBusiness">
            <Link to={`/restaurant/${restaurantId}/detail`}>
                <img
                    src={photo}
                    className="mx-auto mt-10 h-auto max-h-[280px] w-3/5 rounded-lg sm:w-2/5 md:w-2/5 lg:w-1/3 xl:w-1/5 cursor-pointer"
                    alt="business"
                />
            </Link>

            <div className="mx-auto mt-6">
                {/* TODO: ICON */}

                <p className="text-center text-xl font-semibold">{name}</p>
            </div>

            <Link to={`/restaurant/${restaurantId}/detail`}>
                <div className="button-mycia mt-8" >VAI AL PROFILO DEL LOCALE</div>
            </Link>
        </div>
    );
}

export default ItemBusiness;
