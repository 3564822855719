import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createTastingMenu, getTastingMenu } from '../../apis/tasting';
import ItemMenu from './ItemMenu';
import { useTranslation } from "react-i18next";

const initialState = {
    name: "",
    description: "",
    price: "0.00"
}

function TastingMenu(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { restaurantId } = useParams();

    const [tastingMenuData, setTastingMenuData] = useState([]);
    const [menuData, setMenuData] = useState(initialState);

    const onClickBackHandler = () => {
        navigate(-1);
    }

    const loadMenu = useCallback(async () => {
        const res = await getTastingMenu(restaurantId);
        setTastingMenuData(res.menus);
    }, [restaurantId]);

    useEffect(() => {
        loadMenu();
    }, [loadMenu, restaurantId]);

    const onChangeHandler = ({target}) => {
        setMenuData(menuData => ({
            ...menuData,
            [target.name]: [target.value]
        }));
    }

    const onSubmitCreateMenu = async () => {
        const res = await createTastingMenu(restaurantId, menuData);
        if (res.outcome) {
            await loadMenu();
            setMenuData(initialState);
        }
    }

    return (
        <div className="TastingMenu">
            <div className="px-mobile pt-mobile">
                <div className="flex items-center justify-between">
                    <div className="flex cursor-pointer items-center" onClick={onClickBackHandler}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="h-4 w-4">
                            <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                        </svg>

                        <p className="ml-2 text-lg font-light underline">Indietro</p>
                    </div>

                    <p className="font-semibold text-lg underline text-mycia-orangeRed cursor-pointer" onClick={onClickBackHandler}>Vai al menù completo</p>
                </div>

                <p className="mt-6 text-xl font-semibold">Crea un Menù Degustazione</p>

                <p className="mt-6 text-sm font-semibold uppercase">NOME MENÙ</p>
                <input type="text" name="name" value={menuData.name} onChange={onChangeHandler} placeholder="-" className="input-mycia-secondary mt-2" />

                <div className="flex items-center justify-between mt-4">
                    <p className="text-lg font-semibold">Costo del menù completo</p>

                    <input type="number" name="price" value={menuData.price} onChange={onChangeHandler} placeholder="-" className="input-mycia-secondary w-[10ch] mt-2" />
                </div>

                <p className="mt-4 inline-block font-semibold uppercase text-sm">DESCRIZIONE</p>
                <textarea
                    id="description"
                    name="description"
                    placeholder="Descrizione del menù"
                    className="input-mycia-secondary resize-none mt-2"
                    rows="10"
                    value={menuData.description}
                    onChange={onChangeHandler}
                /> 

                <div className="button-mycia my-6 bg-mycia-greenPhilippine shadow-mycia-greenPhilippine" onClick={onSubmitCreateMenu}>
                    <p>CREA MENÙ</p>
                </div>
            </div>

            <div className="mb-mobile">
                {
                    tastingMenuData.map((item, key) => (
                        <ItemMenu className={`${key === 0 ? 'border-t-2' : ''} border-b-2 border-mycia-dimGray`} key={item.mnd_id} menu={item} restaurantId={restaurantId} refreshMenu={loadMenu} />
                    ))
                }
            </div>
        </div>
    )
}

export default TastingMenu;
