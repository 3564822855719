import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { getGramsOfIngredients, updateGramsOfIngredients } from '../../../../apis/modify-restaurant';
import { modalStyle } from '../../../../style';
import Close from '../../../Icon/Close';
import { useTranslation } from "react-i18next";

function DishNutritionalModal(props) {
    const { t } = useTranslation();
    const { children, restaurantId, dishId } = props;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [data, setData] = useState([]);
    
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const updateModify = async () => {
        const payload = new URLSearchParams();
        data.forEach(element => {
            payload.append(element.alimento_id, element.plc_grammi)
        });
        await updateGramsOfIngredients(restaurantId, dishId, payload);
    }

    const onChangeHandler = ({ target }) => {
        const temp = data;
        temp[target.name].plc_grammi = target.value;
        return setData(temp);
    }

    useEffect(() => {
        async function loadData() {
            const res = await getGramsOfIngredients(restaurantId, dishId);
            setData(res);
        };

        if(modalIsOpen) loadData();
    }, [restaurantId, dishId, modalIsOpen]);

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="Valori nutrizionali Modal"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Valori nutrizionali</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <div className="flex flex-col gap-y-4 mt-6">
                        {
                            data.map((item, key) => (
                                <div className="flex justify-between items-center" key={key}>
                                    <p className="font-semibold">{item.ali_nome}</p>

                                    <input id={key} name={key} type="text" className="input-mycia w-[10ch]" defaultValue={item.plc_grammi} onChange={onChangeHandler} />
                                </div>
                            ))
                        }
                    </div>

                    <div className="button-mycia mt-8" onClick={updateModify}>SALVA MODIFICHE</div>
                </Modal>
            )}
        </div>
    );
}

export default DishNutritionalModal;
