import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Home from "../Icon/Home";
import Instruments from "../Icon/Instruments";
// import Options from "../Icon/Options";
import Personalize from "../Icon/Personalize";
import Print from "../Icon/Print";
// import Product from "../Icon/Product";
// import Waiter from "../Icon/Waiter";
// import AddOn from "../Icon/AddOn";
// import AddOnService from "./Pages/AddOnService";
// import EcommerceService from "./Pages/EcommerceService";
import LocalService from "./Pages/LocalService";
import MarketingTools from "./Pages/MarketingTools";
// import PaymentService from "./Pages/PaymentService";
import PersonalizeMenuService from "./Pages/PersonalizeMenuService";
import QrService from "./Pages/QrService";
// import Payment from "../Icon/Payment";
import { PrintTypeProvider } from "../../logic/PrintTypeContext";
// import Ecommerce from "../Icon/Ecommerce";

const options = [
    { label: "Servizi per il locale", icon: <Home className="h-6 w-6" />, page: <LocalService /> },
    // { label: "Servizi per l'e-commerce", icon: <Ecommerce className="h-6 w-6" />, page: <EcommerceService /> },
    { label: "Stampa menù QR", icon: <Print className="h-6 w-6" />, page: <QrService /> },
    { label: "Strumenti marketing", icon: <Instruments className="h-6 w-6" />, page: <MarketingTools /> },
    // { label: "Prodotti aggiuntivi", icon: <Product className="h-6 w-6" />, page: <div></div> },
    { label: "Personalizzazione menù digitale", icon: <Personalize className="h-6 w-6" />, page: <PersonalizeMenuService /> },
    // { label: "Cameriere Digitale", icon: <Waiter className="h-6 w-6" /> },
    // { label: "Add-on", icon: <AddOn className="h-6 w-6" />, page: <AddOnService /> },
    // { label: "Metodi di pagamento", icon: <Payment className="h-6 w-6" />, page: <PaymentService /> },
];

function Services() {
    const navigate = useNavigate();

    const [isOptionEnabled, setIsOptionEnabled] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const onClickBackHandler = () => {
        navigate(-1);
    };

    return (
        <div className="MenuSevices px-6">
            <div className="my-6 flex cursor-pointer items-center" onClick={onClickBackHandler}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="h-5 w-5">
                    <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                </svg>

                <p className="ml-2 text-lg font-light underline">Scheda ristorante</p>
            </div>

            <p className="text-xl font-semibold text-black">Servizi menù digitale</p>

            <div className="button-mycia-white relative z-[9999] mt-6 normal-case" onClick={() => setIsOptionEnabled(!isOptionEnabled)}>
                <div className="flex cursor-pointer select-none items-center justify-between">
                    <div className="flex items-center">
                        {selectedOption && selectedOption.icon}

                        <p className="ml-4 font-semibold">{selectedOption ? selectedOption.label : `OPZIONI`}</p>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className={`h-6 w-6 transition-all duration-300 ${isOptionEnabled ? `rotate-180` : `rotate-0`}`}>
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                    </svg>
                </div>
                <div className={`absolute left-0 mt-6 max-h-0 w-full overflow-hidden rounded-lg py-2 shadow-mycia transition-all duration-300 ${isOptionEnabled ? `max-h-96` : ``} bg-white select-none`}>
                    {options.map((item, key) => (
                        <div
                            key={key}
                            className="flex cursor-pointer items-center px-4 py-2.5 hover:bg-mycia-whiteSmoke"
                            onClick={() => {
                                setSelectedOption(options[key]);
                                setIsOptionEnabled(false);
                            }}
                        >
                            {item.icon}

                            <p className="ml-6 text-xl font-semibold text-left">{item.label}</p>
                        </div>
                    ))}
                </div>
            </div>

            <PrintTypeProvider>
                <div className="my-8">{selectedOption && selectedOption.page}</div>
            </PrintTypeProvider>
        </div>
    );
}

export default Services;
