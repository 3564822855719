import React from "react";

function EyeIcon(props) {
    const { className, onClick } = props;
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick} viewBox="0 0 80.89 51.15">
            <path d="M0 25.58C0 19.54 16.46 0 40.43 0s40.46 19.54 40.46 25.58-16.11 25.58-40.46 25.58S0 31.55 0 25.58zm72.8 0c0-3.4-14.21-19.01-32.37-19.01S8.09 22.18 8.09 25.58c0 4.01 14.15 19.01 32.34 19.01s32.37-15 32.37-19.01zm-47.58 0c-.03-8.5 6.77-15.18 15.23-15.18s15.21 6.71 15.21 15.18-6.77 15.18-15.21 15.18-15.21-6.91-15.23-15.18zm20.01 0c0-2.64-2.14-4.78-4.8-4.78s-4.78 2.14-4.78 4.78 2.14 4.75 4.78 4.75 4.8-2.17 4.8-4.75z"></path>
        </svg>
    );
}

export default EyeIcon;
