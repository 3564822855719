import React from "react";

function Analytics(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.13 55.82" className={className} onClick={onClick}>
            <path d="M89.13 0L62.24 9.33l7.74 6.7-20.47 23.66L33.8 23.3c-1.23-1.28-2.91-2.02-4.74-1.96-1.78.04-3.47.82-4.61 2.11L1.04 49.12a4 4 0 00.26 5.65c.77.7 1.73 1.04 2.69 1.04 1.09 0 2.17-.44 2.96-1.3l22.27-24.42 15.74 16.42c1.24 1.29 2.99 2 4.77 1.96 1.79-.05 3.49-.85 4.66-2.2l21.63-25.01 7.74 6.7L89.13 0z"></path>
        </svg>
    );
}

export default Analytics;
