import { createContext, useContext, useState } from "react";
import { login as clientLogin } from "../apis/users";

const AuthContext = createContext();

function AuthProvider(props) {
    const [user, setUser] = useState({});

    async function login(email, password) {
        const res = await clientLogin(email, password);
        if (res.outcome) {
            setUser(user);
        }
        return res.outcome;
    }

    const value = {
        user,
        setUser,
        login,
    }

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
}

export {
    AuthProvider,
    useAuth,
}
