import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../Icon/Delete";

function ItemCoupon(props) {
    const { t } = useTranslation();
    const { isExist } = props;

    return (
        <div className="ItemCoupon">
            <div className="m-2 rounded-mobile border-2 border-mycia-dimGray p-4">
                <div className="flex items-center justify-between">
                    <p>Che percentuale di sconto vuoi offrire?</p>

                    <input type="number" className="input-mycia-secondary float-right w-[10ch] text-sm" step="0.5" placeholder="%" disabled={isExist} />
                </div>

                <input type="text" className="input-mycia-secondary mt-4 text-base" placeholder="Scrivi il codice sconto" disabled={isExist} />
                <input type="text" className="input-mycia-secondary mt-4 text-base" placeholder="Descrizione coupon" disabled={isExist} />
            </div>

            {isExist && (
                <div className="m-2 flex w-fit cursor-pointer items-center gap-x-2 rounded-lg border-2 border-mycia-orangeRed py-1 px-2">
                    <Delete className="w-3 h-3" />

                    <p className="text-sm font-semibold uppercase">ELIMINA COUPON</p>
                </div>
            )}
        </div>
    );
}

export default ItemCoupon;
