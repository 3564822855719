import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { LOCALSTORAGE_KEY } from "../apis/constants";

const useAuth = () => {
    const user = window.localStorage.getItem(LOCALSTORAGE_KEY)
    return !!user
}

const ProtectedRoutes = (props) => {
    const auth = useAuth()

    return auth ? <Outlet /> : <Navigate to="/" />
}


export default ProtectedRoutes;