import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import EditForm from "../components/Restaurant/EditForm/EditForm";
import { useTranslation } from "react-i18next";

function RestaurantModify(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onClickBackHandler = () => {
        navigate(-1);
    }

    return (
        <div className="RestaurantModify">
            <Header isDividerExist={true} />

            <div className="px-6 py-6">
                <div className="mb-6 flex cursor-pointer items-center" onClick={onClickBackHandler}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="h-4 w-4">
                        <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                    </svg>

                    <p className="text-lg font-light underline">Scheda ristorante</p>
                </div>

                <EditForm />
            </div>

            <Footer />
        </div>
    );
}

export default RestaurantModify;
