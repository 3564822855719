import React from 'react';
import {Navigate, Outlet} from 'react-router-dom'
import { LOCALSTORAGE_KEY } from '../apis/constants';

const useAuth = () => {
    const user = window.localStorage.getItem(LOCALSTORAGE_KEY)
    return !!user;
}

const PublicRoutes = (props) => {
    const auth = useAuth()

    return auth ? <Navigate to="/businesses" /> : <Outlet />
}

export default PublicRoutes;