import React, { useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async';
import { getIngredientsByName, getIngredientsOfComposed, updateIngredientToComposed } from '../../../../apis/modify-restaurant';
import { modalStyle, myCIASelect } from '../../../../style';
import Close from '../../../Icon/Close';
import ItemIngredient from '../ItemIngredient';
import { useTranslation } from "react-i18next";

function DishIngredientElemsModal(props) {
    const { t } = useTranslation();
    const { children, restaurantId, dishId, composedId } = props;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [suggestedIngredient, setSuggestedIngredient] = useState();
    const [ingredients, setIngredients] = useState([]);
    const [originalIngredients, setOriginalIngredients] = useState([]);
    
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function mapSuggestedIngredients(data) {
        return data && data.map(it => ({
            label: it.text,
            value: it.id
        }));
    }

    useEffect(() => {
        async function loadData() {
            const res = await getIngredientsOfComposed(restaurantId, dishId, composedId);
            setIngredients(mapSuggestedIngredients(res.ingredienti_personalizzati));
            setOriginalIngredients(res.ingredienti_originali);
        };

        if(modalIsOpen) loadData();
    }, [restaurantId, dishId, composedId, modalIsOpen]);

    const onDelete = async (key) => {
        const temp = ingredients.filter((it, index) => index !== key);
        setIngredients(temp);
    }

    const onChangeSuggestedIngredient = async (target) => {
        setIngredients(data => ([
            ...data,
            {...target}
        ]));
        setSuggestedIngredient();
    }

    const promiseIngredientOptions = (inputValue) => {
        return new Promise(async (resolve) => {
            if (inputValue.length > 1) {
                const data = await getIngredientsByName(restaurantId, inputValue.trim(), 1);
                resolve(mapSuggestedIngredients(data.response));
            } else {
                resolve([]);
            }
        });
    }

    const onChangeIngredients = async () => {
        const payload = ingredients.map(it => it.value);
        const res = await updateIngredientToComposed(restaurantId, dishId, composedId, payload);
        if (res.outcome) {
            closeModal();
        }
    }

    const noOptionsMessage = ({ inputValue }) => (inputValue.length > 1 ? "Nessun risultato trovato" : `Per favore inserisci ${2 - inputValue.length} o più caratteri`);

    return (
        <div>
            <div onClick={openModal}>
                {children}
            </div>

            {modalIsOpen && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={modalStyle}
                    contentLabel="Example Modal"
                >
                    <div className="flex items-center justify-between">
                        <p className="text-lg font-semibold">Ingredienti alimento composto</p>

                        <Close className="cursor-pointer w-4 h-4 mr-2" onClick={closeModal} />
                    </div>

                    <p className="mt-4 font-light text-lg"><span className="font-semibold">Ingredienti originali: </span>{originalIngredients.map(item => item.text.trim()).join(", ")}</p>

                    <AsyncSelect
                        name="alimento_id"
                        cacheOptions
                        className="flex-1 mt-4"
                        styles={myCIASelect}
                        isClearable={false}
                        isSearchable={true}
                        placeholder="Scrivi un ingrediente"
                        value={suggestedIngredient}
                        onChange={onChangeSuggestedIngredient}
                        loadOptions={promiseIngredientOptions}
                        noOptionsMessage={noOptionsMessage}
                    />

                    <div className="mt-6 flex flex-wrap gap-x-4 gap-y-6">
                        {ingredients.map((item, key) => (
                            <ItemIngredient key={key} dishId={dishId} id={item.value} name={item.label} onDelete={() => onDelete(key)} />
                        ))}
                    </div>

                    <div className="button-mycia flex-1 mt-6" onClick={onChangeIngredients}>CONFERMA</div>
                </Modal>
            )}
        </div>
    );
}

export default DishIngredientElemsModal;
