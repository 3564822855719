import React from "react";

function Settings(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.51 64.45" className={className} onClick={onClick}>
            <path d="M24.05 60.38l-1.14-4.89-.62-.23-4.28 2.64c-2.17 1.35-4.78 1-6.56-.85l-4.13-4.1c-1.85-1.88-2.17-4.42-.82-6.56l2.64-4.25-.21-.59-4.89-1.17C1.61 39.79 0 37.74 0 35.17v-5.74c0-2.55 1.58-4.6 4.04-5.21l4.83-1.2.26-.62-2.67-4.25c-1.35-2.11-1.03-4.66.82-6.56l4.13-4.13c1.79-1.82 4.37-2.17 6.56-.82l4.25 2.61.67-.26 1.14-4.89c.59-2.46 2.64-4.07 5.21-4.07h5.95c2.61 0 4.66 1.61 5.24 4.07l1.11 4.89.67.26 4.28-2.61c2.17-1.35 4.75-1 6.56.82l4.1 4.13c1.85 1.9 2.2 4.45.82 6.56l-2.64 4.25.23.62 4.83 1.2c2.46.59 4.07 2.67 4.07 5.21v5.74c0 2.58-1.61 4.6-4.07 5.21l-4.86 1.17-.23.59 2.67 4.25c1.35 2.14 1.03 4.69-.82 6.56l-4.13 4.1c-1.82 1.85-4.39 2.2-6.56.85l-4.28-2.64-.64.23-1.11 4.89c-.59 2.46-2.64 4.07-5.24 4.07h-5.95c-2.58 0-4.63-1.61-5.21-4.07zm10.22-1.93c.5 0 .7-.21.79-.64l1.73-7.15c2.05-.47 3.75-1.2 5.36-2.23l6.27 3.84c.35.23.7.21 1.05-.12l2.78-2.81c.35-.32.35-.67.12-1.03l-3.87-6.27c.94-1.52 1.73-3.4 2.14-5.3l7.21-1.7c.44-.09.64-.32.64-.79v-3.93c0-.53-.21-.73-.64-.82l-7.18-1.73c-.44-1.96-1.29-3.87-2.11-5.33l3.84-6.24c.23-.38.23-.73-.09-1.05l-2.81-2.78c-.38-.32-.67-.35-1.08-.12l-6.24 3.81c-1.58-.94-3.4-1.76-5.39-2.2l-1.73-7.21c-.09-.44-.29-.64-.79-.64h-4.04c-.5 0-.73.21-.79.64l-1.73 7.15c-1.96.5-4.04 1.35-5.42 2.26l-6.24-3.84c-.41-.23-.73-.21-1.08.15l-2.81 2.75c-.35.32-.32.67-.09 1.08l3.87 6.24c-.85 1.46-1.7 3.37-2.14 5.36l-7.15 1.7c-.47.09-.67.29-.67.82v3.93c0 .47.23.7.67.79l7.18 1.7c.41 1.9 1.23 3.75 2.14 5.3l-3.87 6.27c-.23.38-.23.73.09 1.05L15 52.17c.35.32.7.35 1.05.12l6.27-3.87c1.58 1.03 3.46 1.79 5.39 2.23l1.73 7.15c.06.44.29.64.79.64h4.04zM21.15 32.23c0-6.06 5.01-11.02 11.1-11.02s11.04 4.95 11.04 11.02-4.98 11.1-11.04 11.1-11.1-5.01-11.1-11.1zm16.64 0c0-3.02-2.52-5.57-5.54-5.57s-5.6 2.52-5.6 5.57 2.52 5.62 5.6 5.62 5.54-2.55 5.54-5.62z"></path>
        </svg>
    );
}

export default Settings;
