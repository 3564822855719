import React from "react";

function Home(props) {
    const { className, onClick } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.98 63.63" className={className} onClick={onClick}>
            <path d="M1.35 27.39L31.88 1.78c2.84-2.37 6.47-2.37 9.29 0l13.39 11.25v-5.1c0-1.32.91-2.2 2.23-2.2h4.75c1.32 0 2.17.88 2.17 2.2V20.7l7.94 6.68c.88.73 1.35 1.67 1.35 2.78 0 1.93-1.46 3.16-3.43 3.16-1 0-1.93-.5-2.75-1.17l-3.11-2.61v26.81c0 4.54-2.75 7.27-7.44 7.27H16.58c-4.69 0-7.44-2.72-7.44-7.27V29.67l-2.96 2.49c-.82.67-1.76 1.17-2.72 1.17-2.14 0-3.46-1.44-3.46-3.22 0-.97.47-1.96 1.35-2.72zm43.54 29.53h9.35c1.79 0 2.78-1 2.78-2.81V23.9L37.97 7.93c-.94-.79-1.99-.79-2.9 0L15.82 24.05v30.06c0 1.82 1 2.81 2.78 2.81h9.49V38.05c0-1.38.91-2.26 2.31-2.26h12.22c1.38 0 2.26.88 2.26 2.26v18.87z"></path>
        </svg>
    );
}

export default Home;
